import produce from "immer";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface Recent {
  title: string;
  path: string;
  date: Date;
  screenshot?: string;
}

export type Favorite = Recent;

interface RecentsState {
  recents: Recent[];
  addRecent: (recent: Recent) => void;
  favorites: { [key: string]: Favorite };
  addFavorite: (recent: Favorite) => void;
  removeFavorite: (path: string) => void;
}

const RECENT_LIMIT = 200;

export const useRecents = create<RecentsState>()(
  persist(
    (set, get) => ({
      recents: [],
      addRecent: (recent) => {
        let recents = get().recents;
        const screenshot = recents.find((item) => item.path === recent.path)
          ?.screenshot;
        recents = recents.filter((item) => item.path !== recent.path);
        if (screenshot && !recent.screenshot) {
          recent.screenshot = screenshot;
        }
        recents.push(recent);
        if (recents.length > RECENT_LIMIT) {
          recents.shift();
        }

        set({ recents });
      },
      favorites: {},
      addFavorite: (favorite) => {
        set(
          produce<RecentsState>((state) => {
            state.favorites[favorite.path] = favorite;
            return state;
          })
        );
      },
      removeFavorite: (path) => {
        set(
          produce<RecentsState>((state) => {
            delete state.favorites[path];
            return state;
          })
        );
      },
    }),
    {
      name: "promaton-viewer-recents",
      version: 2,
    }
  )
);
