import axios from "axios";

export * as acd from "./generated/api/acd";
export * as alignment from "./generated/api/alignment";
export * as cbct from "./generated/api/cbct";
export * as feedback from "./generated/api/feedback";
export * as oss from "./generated/api/oss";
export * as tsc from "./generated/api/tsc";
export * as sipp from "./generated/api/sipp";
export * as user from "./generated/api/users";
export * from "./generated/model";

if (typeof window !== "undefined") {
  axios.defaults.baseURL =
    location.host.includes("promaton.com") ||
    location.host.includes("localhost")
      ? "https://api.edge.promaton.com/"
      : "https://api-v2-staging.dev.promaton.ai/";
}
