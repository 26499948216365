import { acd, AcdTask } from "@promaton/api-client";
import { applyOSSConvention } from "@promaton/file-processing/src/conventionInternalOSS";
import { applyUniversalConvention } from "@promaton/file-processing/src/conventionUniversal";
import {
  CameraUtils,
  FileType,
  getNameFromID,
  useObjects,
  ViewerObject,
  ViewerObjectMap,
} from "@promaton/scan-viewer";

import { getAiAssistantHeaders } from "../../hooks/useAiAssistantHeaders";
import { useAiAssistantState } from "../../stores/useAiAssistantState";
import { parseAiTaskMetadata } from "./aiTaskMetadata";

export const loadAcdTaskResults = async (
  task: AcdTask,
  load = true,
  displayResultProgress = true
) => {
  if (!task.result) return;
  const metadata = parseAiTaskMetadata(task.meta_data);
  const inputFile = metadata.inputName ?? metadata.name;
  const input = useObjects.getState().objects[inputFile];
  const crowns = Object.keys(task.result.crown);
  const options = getAiAssistantHeaders();

  let resultLoadingProgress = 0.0001;
  displayResultProgress &&
    useAiAssistantState.setState({ resultLoadingProgress });

  const crownStls = await Promise.all(
    crowns.map((crown) =>
      acd
        .getAcdCrownStructure(task.id, crown, options)
        .then(
          (t) =>
            [
              `crown.${crown}`,
              {
                url: URL.createObjectURL(t.data),
                objectType: FileType.STL,
                clipToPlanes: true,
              },
            ] as [string, ViewerObject]
        )
        .finally(() => {
          resultLoadingProgress += 1 / crowns.length;
          displayResultProgress &&
            useAiAssistantState.setState({ resultLoadingProgress });
        })
    )
  );

  const stls = [...crownStls];

  const objects = Object.fromEntries(stls) as ViewerObjectMap;
  if (input) {
    // Preserve scene if original object is found
    Object.entries(useObjects.getState().objects).forEach(([id, value]) => {
      if (
        !objects[id] &&
        value &&
        !crowns.find((crown) => getNameFromID(id).includes(crown))
      ) {
        objects[id] = { ...value };
      }
    });
  }

  applyUniversalConvention(objects);
  await applyOSSConvention(objects);
  if (load) {
    useObjects.getState().setObjects(objects, false);
    setTimeout(() => {
      CameraUtils.recenterAllViews();
    }, 200);
  }

  return objects;
};
