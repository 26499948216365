import {
  AcdTask,
  AlignmentTask,
  CbctTask,
  OssTask,
  SippTask,
  TscTask,
} from "@promaton/api-client";
import { QueryClient } from "@tanstack/react-query";
import produce from "immer";
import ga4 from "react-ga4";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export const ApiEndpoint = {
  PROD: "https://api.promaton.com/",
  EDGE: "https://api.edge.promaton.com/",
  STAGING: "https://api-v2-staging.dev.promaton.ai/",
} as const;

export type ApiEndpoint = (typeof ApiEndpoint)[keyof typeof ApiEndpoint];

export const AiTaskType = {
  OPTICAL: "OPTICAL",
  CBCT: "CBCT",
  ALIGNMENT: "ALIGNMENT",
  ACD: "ACD",
  TSC: "TSC",
  SIPP: "SIPP",
  CBCT_FILLED_JAW: "CBCT_FILLED_JAW",
} as const;

export type AiTaskType = (typeof AiTaskType)[keyof typeof AiTaskType] | null;

export const AI_TASK_RETENTION_TIME_MINUTES = 24 * 60 * 30;

type AiTaskReference = {
  id: string;
  type: AiTaskType;
};

export type AiTaskData<
  T = OssTask | CbctTask | AcdTask | AlignmentTask | TscTask | SippTask,
> = T & {
  type: Exclude<AiTaskType, null>;
};

export type TasksCompletionCallback = {
  tasks: string[];
  onTasksCompleted: () => void;
};

interface AiAssistantState {
  dialogOpen: boolean;
  setDialogOpen: (to: boolean) => void;

  resultLoadingProgress: number;

  apiEndpoint: ApiEndpoint;
  setApiEndpoint: (to: ApiEndpoint) => void;

  apiToken: string;
  setApiToken: (to: string) => void;

  activeTasks: AiTaskReference[];
  addTask: (task: AiTaskReference) => void;
  removeTask: (id: string) => void;

  completionCallbacks: TasksCompletionCallback[];
  setCompletionCallbacks: (
    callbacks: TasksCompletionCallback[],
    append?: boolean
  ) => void;

  activePage: AiTaskType;
  setActivePage: (page: AiTaskType) => void;

  acceptedTerms: boolean;
  setAcceptedTerms: (to: boolean) => void;

  latestTaskLoaded: AiTaskData | null;
  setLatestTaskLoaded: (to: AiTaskData | null) => void;
}

export const aiQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60,
    },
  },
});

export const useAiAssistantState = create<AiAssistantState>(
  // @ts-ignore
  persist(
    (set) => ({
      dialogOpen: false,
      setDialogOpen: (dialogOpen) => {
        set({ dialogOpen });
        ga4.event({
          category: "AI",
          action: "AIdialogOpened",
        });
      },
      acceptedTerms: false,
      setAcceptedTerms: (acceptedTerms) => {
        set({ acceptedTerms });
        ga4.event({
          category: "AI",
          action: "AItermsAccepted",
        });
      },
      apiEndpoint:
        location.host.includes("promaton.com") ||
        location.host.includes("localhost")
          ? ApiEndpoint.EDGE
          : ApiEndpoint.STAGING,

      setApiEndpoint: (apiEndpoint) => {
        set({ apiEndpoint });
      },

      apiToken: "",
      setApiToken: (apiToken) => {
        set({ apiToken });
        apiToken &&
          ga4.event({
            category: "AI",
            action: "APITokenSet",
          });
      },
      activeTasks: [],
      addTask: (activeTask) => {
        set(
          produce<AiAssistantState>((draft) => {
            draft.activeTasks.push(activeTask);
          })
        );
        activeTask &&
          ga4.event({
            category: "TaskStarted",
            action: "APITokenSet",
          });
      },
      removeTask: (id) => {
        set(
          produce<AiAssistantState>((draft) => {
            const index = draft.activeTasks.findIndex((t) => t.id === id);
            if (index !== -1) {
              draft.activeTasks.splice(index, 1);
            }
          })
        );
      },

      completionCallbacks: [],
      setCompletionCallbacks: (callbacks, append = false) => {
        set(
          produce<AiAssistantState>((draft) => {
            if (append) {
              draft.completionCallbacks.push(...callbacks);
            } else {
              draft.completionCallbacks = callbacks;
            }
          })
        );
      },

      activePage: null,
      setActivePage: (activePage) => {
        set({ activePage });
        ga4.event({
          category: "AI",
          action: `AIView${activePage}Page`,
        });
      },
      resultLoadingProgress: 0,

      latestTaskLoaded: null,
      setLatestTaskLoaded: (latestTaskLoaded) => {
        set({ latestTaskLoaded });
      },
    }),
    {
      name: "promaton-ai-assistant",
      version: 1,
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) =>
            ["apiToken", "acceptedTerms", "apiEndpoint"].includes(key)
          )
        ),
    }
  )
);
