import { memo, useEffect } from "react";
import { useTimeoutFn } from "react-use";

import { trpc } from "../hooks/trpc";
import { useAppState } from "../stores/useAppState";

export const OnlineChecker = memo(() => {
  const setOnline = useAppState((s) => s.setOnline);
  const isOnline = useAppState((s) => s.isOnline);
  const ping = trpc.user.ping.useQuery(undefined, {
    retry: false,
    staleTime: 1000 * 30,
    refetchInterval: isOnline ? 1000 * 60 : 5000,
  });

  const [_, cancel] = useTimeoutFn(() => {
    setOnline(false);
  }, 5000);

  useEffect(() => {
    if (ping.isLoading) return;
    cancel();
    setOnline(ping.isSuccess || !ping.error);
  }, [ping.data, ping.error]);

  return null;
});
