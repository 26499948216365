import {
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  ListItemText,
  Stack,
} from "@mui/material";
import { acd, alignment, cbct, oss, sipp, tsc } from "@promaton/api-client";
import { TaskState } from "@promaton/api-client";
import { createNotification } from "@promaton/frontend-common";
import {
  BoundingBox,
  Image,
  Implant,
  Link,
  Tooth,
  Trash,
} from "@promaton/icons";
import { getNameFromID } from "@promaton/scan-viewer";
import { FC, useCallback, useMemo, useState } from "react";
import { useLocation } from "wouter";

import { parseAiTaskMetadata } from "../helpers/ai/aiTaskMetadata";
import { loadAiTaskResults } from "../helpers/ai/loadAiTaskResults";
import { useAiAssistantHeaders } from "../hooks/useAiAssistantHeaders";
import {
  aiQueryClient,
  AiTaskData,
  AiTaskType,
  useAiAssistantState,
} from "../stores/useAiAssistantState";

const TaskName: { [key in keyof typeof AiTaskType]: string } = {
  [AiTaskType.CBCT]: "CBCT",
  [AiTaskType.ACD]: "ACD",
  [AiTaskType.OPTICAL]: "OSS",
  [AiTaskType.ALIGNMENT]: "Alignment",
  [AiTaskType.TSC]: "TSC",
  [AiTaskType.SIPP]: "Implant",
  [AiTaskType.CBCT_FILLED_JAW]: "Jaw-Hole Filling",
} as const;

export const AiAssistantTask: FC<{
  task: AiTaskData;
  active?: boolean;
}> = ({ task, active }) => {
  const setDialogOpen = useAiAssistantState((s) => s.setDialogOpen);
  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);
  const [_, setLocation] = useLocation();
  const [loading, setLoading] = useState(false);

  const headers = useAiAssistantHeaders();
  const loadStructures = useCallback(
    async (task: AiTaskData) => {
      if (!task.result) return;
      setLoading(true);
      closeDialog();
      await loadAiTaskResults(task);
      setLoading(false);
      setLocation("/");
    },
    [headers, closeDialog]
  );

  const deleteOssTask = oss.useDeleteOss({
    ...headers,
    mutation: {
      onSuccess: () => {
        aiQueryClient.invalidateQueries({
          queryKey: oss.getGetOssTasksQueryKey(undefined),
        });
      },
    },
  });
  const deleteCbctTask = cbct.useDeleteCbctTask({
    ...headers,
    mutation: {
      onSuccess: () => {
        aiQueryClient.invalidateQueries({
          queryKey: cbct.getGetCbctTasksQueryKey(undefined),
        });
      },
    },
  });
  const deleteAcdTask = acd.useDeleteAcd({
    ...headers,
    mutation: {
      onSuccess: () => {
        aiQueryClient.invalidateQueries({
          queryKey: acd.getGetAcdTasksQueryKey(undefined),
        });
      },
    },
  });
  const deleteAlignmentTask = alignment.useDeleteAlignment({
    ...headers,
    mutation: {
      onSuccess: () => {
        aiQueryClient.invalidateQueries({
          queryKey: alignment.getGetAlignmentTasksQueryKey(undefined),
        });
      },
    },
  });
  const deleteTscTask = tsc.useDeleteTsc({
    ...headers,
    mutation: {
      onSuccess: () => {
        aiQueryClient.invalidateQueries({
          queryKey: tsc.getGetTscTasksQueryKey(undefined),
        });
      },
    },
  });
  const deleteSippTask = sipp.useDeleteSipp({
    ...headers,
    mutation: {
      onSuccess: () => {
        aiQueryClient.invalidateQueries({
          queryKey: sipp.getGetSippTasksQueryKey(undefined),
        });
      },
    },
  });
  const deleteJawFillingTask = cbct.useDeleteCbctTask({
    ...headers,
    mutation: {
      onSuccess: () => {
        aiQueryClient.invalidateQueries({
          queryKey: cbct.getGetCbctFilledJawTasksQueryKey(undefined),
        });
      },
    },
  });

  const metadata = useMemo(() => {
    return parseAiTaskMetadata(task.meta_data);
  }, [task.meta_data]);

  return (
    <Stack flexDirection={"row"} alignItems="center" gap={2}>
      <Avatar
        sx={{
          backgroundColor: (t) => t.palette.background.paper,
          border: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        {task.type === AiTaskType.CBCT ? (
          <Image color="success" />
        ) : task.type === AiTaskType.ALIGNMENT ? (
          <Link color="secondary" />
        ) : task.type === AiTaskType.SIPP ? (
          <Implant color="success" />
        ) : task.type === AiTaskType.ACD ? (
          <Tooth color="warning" />
        ) : task.type === AiTaskType.CBCT_FILLED_JAW ? (
          <Tooth color="action" />
        ) : (
          <BoundingBox color="primary" />
        )}
      </Avatar>
      <ListItemText
        title={metadata.name}
        primary={`${TaskName[task.type]} ${getNameFromID(metadata.name)}`}
        secondary={`Status: ${task.error?.message || task.state} · ${new Date(
          task.uploaded || ""
        ).toLocaleString("en-US", {
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}`}
        secondaryTypographyProps={task.error ? { color: "error" } : undefined}
        sx={{ flex: 1 }}
      />
      {task.state === TaskState.completed && (
        <Button
          disabled={loading}
          onClick={() => loadStructures(task)}
          variant="contained"
        >
          Load
        </Button>
      )}
      {!active &&
        (task.state === TaskState.pre_processing ||
          task.state === TaskState.processing) && (
          <CircularProgress size={28} />
        )}

      {!active && (
        <IconButton
          onClick={async () => {
            if (task.type === AiTaskType.CBCT) {
              await deleteCbctTask.mutateAsync({ taskId: task.id });
            } else if (task.type === AiTaskType.ACD) {
              await deleteAcdTask.mutateAsync({ taskId: task.id });
            } else if (task.type === AiTaskType.ALIGNMENT) {
              await deleteAlignmentTask.mutateAsync({ taskId: task.id });
            } else if (task.type === AiTaskType.TSC) {
              await deleteTscTask.mutateAsync({ taskId: task.id });
            } else if (task.type === AiTaskType.SIPP) {
              await deleteSippTask.mutateAsync({ taskId: task.id });
            } else if (task.type === AiTaskType.CBCT_FILLED_JAW) {
              await deleteJawFillingTask.mutateAsync({ taskId: task.id });
            } else {
              await deleteOssTask.mutateAsync({ taskId: task.id });
            }

            const aiState = useAiAssistantState.getState();

            if (aiState.activeTasks.find((t) => t.id === task.id)) {
              setTimeout(() => {
                aiState.removeTask(task.id);
              }, 500);
            }

            const newCompletionCallbacks = aiState.completionCallbacks.filter(
              (c) => {
                if (c.tasks.includes(task.id)) {
                  createNotification({
                    color: "warning",
                    text: "Pending task was cancelled as it depenended on this task.",
                  });
                  return false;
                }
                return true;
              }
            );

            if (
              newCompletionCallbacks.length !==
              aiState.completionCallbacks.length
            ) {
              aiState.setCompletionCallbacks(newCompletionCallbacks);
            }
          }}
        >
          <Trash />
        </IconButton>
      )}
    </Stack>
  );
};
