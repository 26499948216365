import {
  FileType,
  inferTypeFromUrl,
  ViewerObjectMap,
} from "@promaton/scan-viewer";

import { BaseColors, getToothColor } from "./baseColors";
import { trimFilePathDepth } from "./util";

const CbctToothRegex = /(tooth)?.?([1-8]{2})(.\d)?[^/]{0,4}$/i;
const CbctJawRegex = /(upper|lower)_jaw$/i;
const FilledRegex = /filled/i;

/** Somewhat hacky way of detecting we are looking at an CBCT output. */
export const checkIsCBCT = (objects: ViewerObjectMap) => {
  const entries = Object.entries(objects);

  const hasCBCTResults = !!entries.find(
    ([key]) => key.match(CbctToothRegex) || key.match(CbctJawRegex)
  );
  if (!hasCBCTResults) return false;

  return true;
};

export const applyCBCTConvention = (objects: ViewerObjectMap) => {
  const hasNonStandardTeeth = Object.keys(objects).find((i) =>
    trimFilePathDepth(i).match(/(fused|aligned).*[0-9]{2}/i)
  );

  const hasFilledJaws = Object.keys(objects).find((i) =>
    FilledRegex.exec(trimFilePathDepth(i))
  );

  const containedTeeth = Object.keys(objects)
    .filter((i) => objects[i]?.objectType !== FileType.PSG)
    .map(
      (i) =>
        !i.match(/(bbox|detection|annotation)/i) &&
        !i.match(/(segmentation)[^/]*$/i) &&
        i.match(CbctToothRegex)?.[2]
    )
    .filter((i) => !!i) as string[];
  const hasDuplicateTeeth =
    containedTeeth.length !== new Set(containedTeeth).size;

  const prefix = (group: string | undefined, name: string) => {
    if (hasDuplicateTeeth && group?.includes(name)) return group;
    return hasDuplicateTeeth && group ? `${group} ${name}` : name;
  };

  Object.entries(objects).map(([_id, viewerObject]) => {
    const fileType =
      viewerObject.objectType ?? inferTypeFromUrl(viewerObject.url);

    const id = trimFilePathDepth(_id);
    if (id.match(CbctJawRegex) || id.match(/jaw_(upper|lower)$/i)) {
      viewerObject.opacity = 0.5;
      viewerObject.group = prefix(viewerObject.group, "Jaws");

      if (hasFilledJaws && !FilledRegex.exec(id)) {
        viewerObject.hidden = true;
      }
    } else if (id.match(/(result|nerve)[^/]*$/i)) {
      viewerObject.color = BaseColors.orange;
      viewerObject.group = prefix(viewerObject.group, "Nerves");
    } else if (id.match(/keypoint/i)) {
      viewerObject.isMetadata = true;
    } else if (id.match(/detection/i)) {
      viewerObject.hidden = true;
    } else if (
      id.match(/result_cns$/i) ||
      (id.match(/evaluation/i) && viewerObject.objectType === FileType.JSON)
    ) {
      viewerObject.hidden = true;
      viewerObject.isMetadata = true;
    } else if (
      id.match(CbctToothRegex) &&
      !id.match(/(bbox|detection|crown|implant|abutment)/i) &&
      fileType !== FileType.DICOM
    ) {
      if (!hasNonStandardTeeth && !hasDuplicateTeeth) {
        viewerObject.group = "Teeth";
      }

      const offset = hasNonStandardTeeth
        ? (viewerObject.group
            ?.split("")
            .reduce((a, b) => a + b.charCodeAt(0), 0) || 0) * 3
        : 0;

      viewerObject.color = getToothColor(
        BaseColors.blue,
        parseInt(id.match(/[0-9]{2}$/)?.[0] ?? "0"),
        offset
      );
    }
  });
};
