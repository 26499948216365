import { z } from "zod";

import { AiTaskType } from "../../stores/useAiAssistantState";

const aiTaskMetadataSchema = z
  .object({
    name: z.string(),
    inputName: z.string().optional(),
    related: z
      .array(
        z.object({
          id: z.string(),
          type: z.nativeEnum(AiTaskType),
        })
      )
      .optional(),
  })
  .passthrough();

export type AiTaskMetadata = z.infer<typeof aiTaskMetadataSchema>;

export const parseAiTaskMetadata = (metadata?: string): AiTaskMetadata => {
  try {
    const parsed = aiTaskMetadataSchema.parse(JSON.parse(metadata ?? "{}"));
    return parsed;
  } catch (error) {
    return {
      name: metadata ?? "Unknown",
    };
  }
};

export const createAiTaskMetadataPayload = (data: AiTaskMetadata) =>
  JSON.stringify(data);
