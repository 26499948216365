import {
  Button,
  IconButton,
  Paper,
  Popper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { CaretLeft } from "@promaton/icons";
import { useObjects } from "@promaton/scan-viewer";
import { FC, memo, useEffect, useState } from "react";

import { useIsMobile } from "../hooks/useIsMobile";
import { useWaitForElement } from "../hooks/useWaitForElement";
import { useAiAssistantState } from "../stores/useAiAssistantState";
import { useAppState } from "../stores/useAppState";
import { OnboardingStep, useOnboarding } from "../stores/useOnboarding";

export const Onboarding: FC<{}> = memo(() => {
  const mobile = useIsMobile();
  const [active, setActive] = useState(false);
  const hasObjects = useObjects((s) => Object.keys(s.objects).length > 0);
  const onboardingCompleted = useAppState((s) => s.onboardingComplete);

  const stepIndex = useOnboarding((s) => s.activeStep);
  const stepTotal = useOnboarding((s) => s.steps.length);
  const activeStep = useOnboarding(
    (s) => s.steps[s.activeStep] as OnboardingStep | undefined
  );
  const aiAssistantOpen = useAiAssistantState((s) => s.dialogOpen);

  useEffect(() => {
    activeStep?.init?.();
  }, [activeStep]);

  useEffect(() => {
    const _active =
      !mobile && hasObjects && !onboardingCompleted && !aiAssistantOpen;
    setActive(_active);
  }, [hasObjects, onboardingCompleted, aiAssistantOpen]);

  useEffect(() => {
    active && useOnboarding.setState({ activeStep: 0 });
  }, [active]);

  if (!active || !activeStep) return null;
  return (
    <StepCard step={activeStep} stepIndex={stepIndex} stepTotal={stepTotal} />
  );
});

const StepCard: FC<{
  step: OnboardingStep;
  stepIndex: number;
  stepTotal: number;
}> = ({ step, stepIndex, stepTotal }) => {
  const incrementStep = useOnboarding((s) => s.incrementActiveStep);
  const element = useWaitForElement(step.selector, () => incrementStep());
  const setOnboardingCompleted = useAppState((s) => s.setOnboardingComplete);

  return (
    <>
      <Popper
        open
        anchorEl={element}
        placement={step.position || "bottom"}
        sx={{ transition: "all 0.25s ease", zIndex: (t) => t.zIndex.fab }}
      >
        <Arrow sx={{ margin: 1 }} />
      </Popper>
      <Popper
        open
        anchorEl={element}
        placement={step.position || "bottom"}
        sx={{ transition: "all 0.25s ease", zIndex: (t) => t.zIndex.fab }}
      >
        <Paper
          sx={{
            margin: 1,
            overflow: "visible",
            position: "relative",
            padding: 2,
            width: 300,
            background: (t) => t.palette.primary.main,
            color: (t) => t.palette.primary.contrastText,
          }}
        >
          <Typography variant="body1" fontWeight={"medium"}>
            {step.title}
          </Typography>
          <Typography variant="body2">{step.description}</Typography>
          <Stack
            sx={{
              marginTop: 2,
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 1,
            }}
          >
            {stepIndex >= 1 ? (
              <>
                <IconButton
                  size="small"
                  color="inherit"
                  sx={{ opacity: 0.7 }}
                  onClick={() => {
                    incrementStep(-1);
                  }}
                >
                  <CaretLeft />
                </IconButton>
                <span style={{ flex: 1 }} />
              </>
            ) : null}
            {stepIndex < stepTotal - 1 && (
              <Button
                color="inherit"
                variant={"text"}
                sx={{ opacity: 0.7 }}
                onClick={() => {
                  setOnboardingCompleted(true);
                }}
              >
                Skip
              </Button>
            )}
            <Button
              color="inherit"
              variant={"outlined"}
              onClick={() => {
                if (stepIndex >= stepTotal - 1) {
                  setOnboardingCompleted(true);
                } else {
                  incrementStep();
                }
              }}
            >
              {stepIndex === stepTotal - 1 ? "Complete" : "Next"}
            </Button>
          </Stack>
        </Paper>
      </Popper>
    </>
  );
};

const Arrow = styled("div")`
  ${({ theme }) => {
    return `
      background: ${theme.palette.primary.main};
      width: 10px;
      height: 10px;
      transform: scale(1.5) rotate(45deg);
      z-index: -1;
    `;
  }}
`;
