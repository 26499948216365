import { IconButton } from "@mui/material";
import { FavoriteOff, FavoriteOn } from "@promaton/icons";
import { FC, memo } from "react";
import ga4 from "react-ga4";

import { useRecents } from "../stores/useRecents";

export const FavoriteToggle: FC<{ path: string; title: string }> = memo(
  ({ path, title }) => {
    const active = useRecents((s) => !!s.favorites[path]);
    const addFavorite = useRecents((s) => s.addFavorite);
    const removeFavorite = useRecents((s) => s.removeFavorite);

    return (
      <IconButton
        className={active ? "" : "hide"}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          active &&
            ga4.event({
              category: "browsing",
              action: "add favorite",
            });

          active
            ? removeFavorite(path)
            : addFavorite({ path, title, date: new Date() });
        }}
      >
        {active ? (
          <FavoriteOn color="warning" />
        ) : (
          <FavoriteOff sx={{ opacity: 1 }} />
        )}
      </IconButton>
    );
  }
);
