import { Button, ButtonProps } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { FC, memo } from "react";

import { trpc } from "../hooks/trpc";
import { useAppState } from "../stores/useAppState";

export const LoginButton: FC<{ label?: string } & ButtonProps> = memo(
  ({ label = "Login", ...props }) => {
    const createToken = trpc.user.login.useMutation();
    const setSession = useAppState((s) => s.setSession);
    const isOnline = useAppState((s) => s.isOnline);
    const utils = trpc.useContext();
    const login = useGoogleLogin({
      flow: "auth-code",
      hosted_domain: "promaton.com",
      onSuccess: async (response) => {
        const data = await createToken.mutateAsync(response.code);

        setSession({
          name: data.name,
          picture: data.picture,
          email: data.email,
        });
        utils.user.session.setData(undefined, () => data);
      },
    });

    return (
      <Button
        onClick={() => login()}
        variant="outlined"
        disabled={createToken.isLoading || !isOnline}
        {...props}
        sx={{ textTransform: "none", fontWeight: "bold", ...props.sx }}
      >
        {label}
      </Button>
    );
  }
);
