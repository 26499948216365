import { Button, CardActions, Stack, Typography } from "@mui/material";
import { Copy, ObjectCube } from "@promaton/icons";
import { memo } from "react";
import { Helmet } from "react-helmet";
import { useCopyToClipboard } from "react-use";
import { VirtuosoGrid } from "react-virtuoso";
import { useLocation } from "wouter";

import { useRecents } from "../stores/useRecents";
import {
  BrowserCard,
  BrowserGridContainer,
  BrowserItemContainer,
} from "./BrowserCard";
import { EmptyState } from "./EmptyState";

export const Recents = memo(() => {
  const [_, setLocation] = useLocation();
  const recents = useRecents((s) => [...s.recents].reverse());
  const [__, copy] = useCopyToClipboard();

  return (
    <Stack sx={{ height: "100%" }}>
      <Helmet>
        <title>Recents · Promaton Viewer</title>
      </Helmet>
      <Typography variant="h5" sx={{ paddingY: 1 }}>
        Recents
      </Typography>
      {recents.length ? (
        <VirtuosoGrid
          style={{
            height: "100%",
            width: "100%",
            margin: "0 -0.5rem",
          }}
          data={recents}
          components={{
            Item: BrowserItemContainer,
            // @ts-ignore
            List: BrowserGridContainer,
          }}
          itemContent={(_, recent) => {
            const link = recent.path;
            const name = recent.title;
            let location = recent.path.split("/").slice(-3)[0];

            if (recent.path.startsWith("/scene")) {
              location = "Shared";
            }

            if (recent.path.startsWith("/dlh2")) {
              location = "DLH v2";
            }

            return (
              <BrowserCard
                key={recent.path}
                link={link}
                onClick={(e) => {
                  if (!link || e.metaKey || e.ctrlKey) return;
                  e.preventDefault();
                  setLocation(link);
                }}
                thumbnail={recent.screenshot}
                title={name}
                subtitle={`${location} · viewed on ${new Date(
                  recent.date
                ).toLocaleString("en-GB", {
                  month: "short",
                  day: "numeric",
                })}`}
                secondary={
                  <CardActions>
                    <Button
                      startIcon={<Copy />}
                      className="hide"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        copy(`${window.location.origin}${link}`);
                      }}
                    >
                      Copy link
                    </Button>
                  </CardActions>
                }
              />
            );
          }}
        />
      ) : (
        <EmptyState>
          <ObjectCube fontSize="large" />
          <Typography>Recently viewed items will appear here</Typography>
        </EmptyState>
      )}
    </Stack>
  );
});
