import { Popover } from "@mui/material";
import { FC, useState } from "react";
import ga4 from "react-ga4";
import { useLocation } from "wouter";

import { createRouteLink, routes } from "../../helpers/routes";
import { trpc } from "../../hooks/trpc";
import { S3FolderBrowser } from "../S3FolderBrowser";
import { COMPARISON_QUERY_PARAM } from "../S3LoadingDialog";
import { SceneTitle } from "./SceneTitle";

export const S3SceneTitle: FC<{
  folder: string;
  bucket: string;
  onClose: () => void;
}> = ({ folder, bucket, onClose }) => {
  const [open, setOpen] = useState<HTMLElement>();
  const [_, setLocation] = useLocation();
  const params = new URLSearchParams(window.location.search);

  const comparisonFolder = params.get(COMPARISON_QUERY_PARAM) || undefined;
  const path = folder.split("/");
  const title = path.at(-1);
  const parent = path.slice(-2)[0];
  const nextScanQuery = trpc.storage.listSubDirectories.useQuery({
    bucket,
    folder: path.slice(0, -1).join("/"),
    count: 2,
    cursor: folder,
  });
  const nextScanFolder = nextScanQuery.data?.CommonPrefixes?.[1]?.Prefix;

  return (
    <>
      <SceneTitle
        title={title}
        subtitle={`from ${parent || bucket}`}
        onNext={
          nextScanFolder
            ? () =>
                setLocation(
                  `${createRouteLink(routes.s3, {
                    bucket,
                    folder: nextScanFolder,
                  })}?${params.toString()}`
                )
            : undefined
        }
        onClose={onClose}
        onClickTitle={(e) => {
          setOpen(e.currentTarget as HTMLElement);
        }}
      />
      <Popover
        open={!!open}
        anchorEl={open}
        transitionDuration={0}
        onClose={() => {
          setOpen(undefined);
        }}
        sx={{
          [".MuiPaper-root"]: {
            borderRadius: 2,
            marginTop: -1,
          },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <S3FolderBrowser
          activePrefix={`${folder}/`}
          bucket={bucket}
          folder={decodeURIComponent(path.slice(0, -1).join("/") || "")}
          comparisonFolder={comparisonFolder}
          onCompare={(prefix) => {
            const params = new URLSearchParams();
            prefix && params.set(COMPARISON_QUERY_PARAM, prefix);
            ga4.event({
              category: "browsing",
              action: "Folder open for comparison",
            });
            setLocation(
              `${createRouteLink(routes.s3, {
                bucket,
                folder,
              })}?${params.toString()}`
            );
          }}
        />
      </Popover>
    </>
  );
};
