import { memo, useEffect } from "react";
import ga4 from "react-ga4";
import { useLocation } from "wouter";

const isPresentation =
  new URLSearchParams(window.location.search).get("presentation") === "true";
import.meta.env.PROD && !isPresentation && ga4.initialize("G-6K2N35GVWW");

export const Analytics = memo(() => {
  const [location] = useLocation();

  useEffect(() => {
    ga4.send({
      hitType: "pageview",
      page: location,
    });
  }, [location]);

  return null;
});
