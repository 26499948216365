import {
  Avatar,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  styled,
} from "@mui/material";
import { useCommandPalette } from "@promaton/frontend-common";
import { CaretRight, Clock, Cloud, HelpCircle, Upload } from "@promaton/icons";
import { pascalCase } from "change-case";
import { FC, ReactNode, useEffect, useState } from "react";
import { QueryParamProvider } from "use-query-params";
import { WindowHistoryAdapter } from "use-query-params/adapters/window";
import { Link, Route, Switch, useLocation } from "wouter";

import { createRouteLink, routes } from "../helpers/routes";
import { useAppState } from "../stores/useAppState";
import { AddBanner } from "./AddBanner";
import { AiAssistant } from "./AiAssistant";
import { Dlh2Browser } from "./Dlh2Browser";
import { FavoriteList } from "./FavoriteList";
import { LoginButton } from "./LoginButton";
import { Profile } from "./Profile";
import { PublicRootPage } from "./PublicRootPage";
import { Recents } from "./Recents";
import { S3FolderBrowser } from "./S3FolderBrowser";
import { SharedProjects } from "./SharedProjects";

interface PageTab {
  label: string;
  route?: string;
  icon: ReactNode;
  subRoutes?: {
    label: string;
    route: string;
  }[];
}

const PageTabs: PageTab[] = [
  { label: "Recently viewed", route: routes.recent, icon: <Clock /> },
  { label: "Shared projects", route: routes.shared, icon: <Upload /> },
  {
    label: "S3",
    subRoutes: [
      {
        label: "Promaton DS",
        route: createRouteLink(routes.browseS3, {
          bucket: "promaton-ds",
          folder: "",
        }),
      },
      {
        label: "Evaluation Dev",
        route: createRouteLink(routes.browseS3, {
          bucket: "promaton-evaluation-dev",
          folder: "",
        }),
      },
      {
        label: "Models",
        route: createRouteLink(routes.browseS3, {
          bucket: "promaton-models",
          folder: "",
        }),
      },
      {
        label: "DLH v2 extractions",
        route: createRouteLink(routes.browseS3, {
          bucket: "lakehouse-v2-extracted-main",
          folder: "",
        }),
      },
      {
        label: "Annotator Upload",
        route: createRouteLink(routes.browseS3, {
          bucket: "promaton-annotator-upload",
          folder: "",
        }),
      },
    ],
    icon: <Cloud />,
  },
  { label: "DLH v2", route: routes.browseDlh2, icon: <Cloud /> },
];

export const InternalRootPage: FC<{
  onOpenFiles: (files: FileList) => void;
}> = ({ onOpenFiles }) => {
  const [location, setLocation] = useLocation();
  const sessionToken = useAppState((s) => s.session);

  useEffect(() => {
    location === "/" &&
      PageTabs[0].route &&
      setLocation(PageTabs[0].route, {
        replace: true,
      });
  }, [location]);

  const updateCommand = useCommandPalette((s) => s.updateCommand);

  useEffect(() => {
    PageTabs.forEach((r) => {
      if (r.route) {
        updateCommand(`browse${pascalCase(r.label)}`, {
          name: `Browse ${r.label}`,
          description: r.route,
          action: () => setLocation(r.route!),
        });
      }
      if (r.subRoutes) {
        r.subRoutes.forEach((sr) => {
          updateCommand(`browse${pascalCase(sr.label)}`, {
            name: `Browse ${sr.label}`,
            description: sr.route,
            action: () => setLocation(sr.route),
          });
        });
      }
    });
  }, []);

  const logo = (
    <ListItem
      sx={{
        marginTop: 2,
        padding: 3,
        paddingLeft: 5,
        marginBottom: 2,
        position: "sticky",
        top: 0,
        zIndex: 1,
        background: "inherit",
      }}
    >
      <ListItemAvatar>
        <Avatar
          src="/promaton-logo.png"
          imgProps={{
            draggable: false,
          }}
          sx={{
            filter: `drop-shadow(0px 1px 0px rgba(0,0,0,0.2))`,
          }}
        />
      </ListItemAvatar>
      <ListItemText
        primary="Promaton Viewer"
        primaryTypographyProps={{
          variant: "h6",
          fontWeight: "medium",
        }}
      />
    </ListItem>
  );

  return (
    <Stack
      flexDirection="row"
      sx={{
        position: "absolute",
        inset: 0,
        height: "fill-available",
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1,
      }}
    >
      <Paper
        sx={{
          width: {
            xs: 300,
            lg: 350,
          },
          display: "flex",
          flexDirection: "column",
          height: "100%",
          boxSizing: "border-box",
          borderRadius: 0,
        }}
        elevation={1}
      >
        {logo}
        <Stack sx={{ flex: 1, overflowY: "auto", padding: 2 }}>
          <List
            sx={{
              background: "inherit",
              gap: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {PageTabs.map((tab) => (
              <MenuTab tab={tab} key={tab.route} />
            ))}
          </List>

          <FavoriteList />
        </Stack>
        <Stack sx={{ padding: 2, paddingTop: 2, paddingBottom: 2, gap: 0 }}>
          <AiAssistant
            buttonProps={{
              fullWidth: true,
              sx: {
                width: (t) => `calc(100% - ${t.spacing(4)})`,
                px: 2,
                py: 1.5,
                borderRadius: 1,
                alignSelf: "stretch",
                boxSizing: "border-box",
                gap: 2,
                flexDirection: "row",
                color: (t) => t.palette.secondary.main,
                mx: 2,
                textAlign: "left",
                justifyContent: "flex-start",
              },
            }}
          />
          <MenuTab
            tab={{
              route:
                "https://www.notion.so/promaton/Viewer-App-User-Guide-352fdb0f3c73440c92e54b19a5d955bf?pvs=4",
              label: "User guide",
              icon: <HelpCircle />,
            }}
          />
          <ListItem>
            {sessionToken ? (
              <Profile showName />
            ) : (
              <LoginButton fullWidth size="large" />
            )}
          </ListItem>
        </Stack>
      </Paper>

      <Stack sx={{ flex: "1 1 0", minWidth: 0, height: "100%" }}>
        <Stack
          sx={{
            paddingTop: {
              xs: 1,
              md: 4,
            },
            paddingBottom: {
              sm: 2,
              md: 4,
            },
            flexDirection: "row",
            gap: 2,
          }}
        >
          <Container>
            <AddBanner onOpenFiles={onOpenFiles} sx={{ flex: 1 }} />
          </Container>
        </Stack>
        <Container sx={{ flex: "1 1 0", minHeight: 0, overflowY: "auto" }}>
          <Switch>
            <Route path={routes.public}>
              <PublicRootPage onOpenFiles={onOpenFiles} />
            </Route>
            <Route path={routes.recent}>
              <Recents />
            </Route>
            <Route path={routes.shared}>
              <QueryParamProvider adapter={WindowHistoryAdapter}>
                <SharedProjects />
              </QueryParamProvider>
            </Route>
            <Route path={routes.browseDlh2}>
              <QueryParamProvider adapter={WindowHistoryAdapter}>
                <Dlh2Browser />
              </QueryParamProvider>
            </Route>
            <Route path={routes.browseS3}>
              {({ folder, bucket }) => (
                <S3FolderBrowser
                  compact={false}
                  folder={decodeURIComponent(folder || "")}
                  bucket={bucket}
                  sx={{
                    flex: 1,
                    width: "100%",
                    height: "100%",
                    maxHeight: "initial",
                  }}
                />
              )}
            </Route>
          </Switch>
        </Container>
      </Stack>
    </Stack>
  );
};

const ExternalLink = styled("a")`
  width: 100%;
  color: inherit;
  text-decoration: none;
`;

const MenuTab: FC<{ tab: PageTab }> = ({ tab }) => {
  const [location] = useLocation();
  const isActive = !!(tab.route && location.includes(tab.route));
  const LinkComponent = tab.route?.startsWith("http") ? ExternalLink : Link;
  const hasSubRoutes = tab.subRoutes?.length;

  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  return (
    <ListItem key={tab.route} sx={{ paddingY: 0 }}>
      {!hasSubRoutes ? (
        <LinkComponent href={tab.route || ""} target="_blank">
          <ListItemButton
            selected={isActive}
            sx={{
              borderRadius: 1,
              alignItems: "center",
              display: "flex",
              gap: 2,
            }}
          >
            {tab.icon}
            <ListItemText primary={tab.label} />
          </ListItemButton>
        </LinkComponent>
      ) : (
        <>
          <ListItemButton
            onClick={
              hasSubRoutes
                ? (e) => {
                    setAnchor(e.currentTarget);
                  }
                : undefined
            }
            sx={{
              borderRadius: 1,
              alignItems: "center",
              display: "flex",
              gap: 2,
            }}
          >
            {tab.icon}
            <ListItemText primary={tab.label} />

            {hasSubRoutes && <CaretRight />}
          </ListItemButton>
          <Menu
            anchorEl={anchor}
            open={!!anchor}
            onClose={() => {
              setAnchor(null);
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            disablePortal
          >
            {tab.subRoutes?.map((subRoute) => (
              <LinkComponent
                key={subRoute.route}
                href={subRoute.route}
                onClick={() => {
                  setAnchor(null);
                }}
              >
                <MenuItem selected={location.includes(subRoute.route)}>
                  {subRoute.label}
                </MenuItem>
              </LinkComponent>
            ))}
          </Menu>
        </>
      )}
    </ListItem>
  );
};
