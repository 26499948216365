import { List, ListItemButton, ListItemText, Popover } from "@mui/material";
import { useCommandPalette } from "@promaton/frontend-common";
import { Cut, Grid, ViewFullscreen, ViewSidebar } from "@promaton/icons";
import {
  SliceToolWindow,
  StockTool,
  ToolbarButton,
  useTools,
  useViews,
  ViewOrientation,
} from "@promaton/scan-viewer";
import { useShortcut } from "@promaton/scan-viewer";
import { FC, memo, ReactNode, useEffect, useMemo, useState } from "react";

import { useAppState, ViewMode } from "../stores/useAppState";
import { OnboardingStepSelector } from "../stores/useOnboarding";
import {
  SIDE_BY_SIDE_TOOL,
  SideBySideViewToolWindow,
} from "./SideBySideToolWindow";

interface ViewOption {
  mode: ViewMode;
  label: string;
  description: string;
  icon: ReactNode;
  keywords?: string;
}

const viewOptions: ViewOption[] = [
  {
    mode: ViewMode.SINGLE,
    label: "Single view",
    description: "Full-screen",
    icon: <ViewFullscreen />,
    keywords: "fullscreen 3D view",
  },
  {
    mode: ViewMode.SPLIT,
    label: "Split view",
    description: "3D + Orthogonal views",
    icon: <Grid />,
    keywords: "Quadrants four 3D 2D",
  },
  {
    mode: ViewMode.CUSTOM,
    label: "Cut view",
    description: "View a custom cross-section",
    icon: <Cut />,
    keywords: "cut cross-section slice",
  },
  {
    mode: ViewMode.SIDE_BY_SIDE,
    label: "Side-by-side view",
    description: "Compare in synchronized views",
    icon: <ViewSidebar />,
    keywords: "comparison mode",
  },
];

export const ViewToggle: FC<{}> = memo(() => {
  const setView = useViews((s) => s.updateView);
  const viewMode = useAppState((s) => s.viewMode);
  const setViewMode = useAppState((s) => s.setViewMode);
  const registerToolWindow = useTools((s) => s.registerToolWindow);
  const removeToolWindow = useTools((s) => s.removeToolWindow);
  const viewModeIndex = useMemo(
    () => viewOptions.findIndex((i) => i.mode === viewMode),
    [viewMode]
  );

  const activeMode = viewOptions[viewModeIndex];
  const label = "View";

  const [active, setActive] = useState<HTMLElement>();

  const setPerspectiveView = (orientation: ViewOrientation) => {
    const perspectiveViews = Object.entries(useViews.getState().views).filter(
      (entry) => {
        return entry[1]?.isPerspective === true;
      }
    );

    perspectiveViews.forEach((perspectiveView) => {
      setView(perspectiveView[0], {
        ...perspectiveView[1],
        orientation,
      });
    });
  };

  const centerOrientation = (orientation: ViewOrientation) => {
    const view = Object.values(useViews.getState().views).find((entry) => {
      return entry?.orientation === orientation;
    });

    view?.recenter && view.recenter();
  };

  const handleChange = (mode?: ViewMode) => {
    setViewMode(
      mode ||
        viewOptions[(viewModeIndex + 1) % viewOptions.length]?.mode ||
        ViewMode.SINGLE
    );
    setPerspectiveView(ViewOrientation["3D"]);
  };

  useShortcut(
    "v",
    (e) => {
      if (e.metaKey || e.ctrlKey) return;
      if ((e.target as HTMLElement)?.tagName === "INPUT") return;
      handleChange();
    },
    [viewMode, viewModeIndex]
  );

  useShortcut(
    "1",
    (e) => {
      if (e.metaKey || e.ctrlKey) return;
      if (viewMode === ViewMode.SPLIT) return;
      setPerspectiveView(ViewOrientation["3D"]);
    },
    [viewMode]
  );

  useShortcut(
    "2",
    (e) => {
      if (e.metaKey || e.ctrlKey) return;
      if (viewMode === ViewMode.SINGLE || viewMode === ViewMode.SIDE_BY_SIDE) {
        setPerspectiveView(ViewOrientation.AXI);
      }
      centerOrientation(ViewOrientation.AXI);
    },
    [viewMode]
  );

  useShortcut(
    "3",
    (e) => {
      if (e.metaKey || e.ctrlKey) return;
      if (viewMode === ViewMode.SINGLE || viewMode === ViewMode.SIDE_BY_SIDE) {
        setPerspectiveView(ViewOrientation.COR);
      }
      centerOrientation(ViewOrientation.COR);
    },
    [viewMode]
  );

  useShortcut(
    "4",
    (e) => {
      if (e.metaKey || e.ctrlKey) return;
      if (viewMode === ViewMode.SINGLE || viewMode === ViewMode.SIDE_BY_SIDE) {
        setPerspectiveView(ViewOrientation.SAG);
      }
      centerOrientation(ViewOrientation.SAG);
    },
    [viewMode]
  );

  // Register tool windows
  useEffect(() => {
    registerToolWindow(StockTool.SLICE, () => (
      <SliceToolWindow onClose={() => handleChange(ViewMode.SINGLE)} />
    ));

    registerToolWindow(SIDE_BY_SIDE_TOOL, SideBySideViewToolWindow);

    return () => {
      removeToolWindow(StockTool.SLICE);
      removeToolWindow(SIDE_BY_SIDE_TOOL);
    };
  }, []);

  const activeTool = useTools((s) => s.activeTool);
  const setActiveTool = useTools((s) => s.setActiveTool);

  // Activate side-by-side tool when switching to side-by-side view
  useEffect(() => {
    if (!activeTool && viewMode === ViewMode.SIDE_BY_SIDE) {
      setActiveTool(SIDE_BY_SIDE_TOOL);
    } else if (
      activeTool === SIDE_BY_SIDE_TOOL &&
      viewMode !== ViewMode.SIDE_BY_SIDE
    ) {
      setActiveTool(null);
    }
  }, [activeTool, viewMode]);

  const updateCommand = useCommandPalette((s) => s.updateCommand);
  const removeCommand = useCommandPalette((s) => s.removeCommand);

  // Register commands
  useEffect(() => {
    viewOptions.forEach((option) => {
      updateCommand(option.mode, {
        name: `Use ${option.label}`,
        description: option.description,
        action: () => {
          handleChange(option.mode);
        },
        keywords: option.keywords,
      });
    });

    return () => {
      viewOptions.forEach((option) => {
        removeCommand(option.mode);
      });
    };
  }, []);

  return (
    <>
      <ToolbarButton
        id={OnboardingStepSelector.VIEWS}
        icon={activeMode?.icon}
        label={label}
        tooltip={`Switch view mode (V)`}
        onClick={(e) => {
          setActive(e.currentTarget as HTMLElement);
        }}
      />
      <Popover
        open={!!active}
        anchorEl={active}
        onClose={() => {
          setActive(undefined);
        }}
        sx={{
          [".MuiPaper-root"]: {
            borderRadius: 2,
          },
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <List sx={{ gap: 0.5, display: "flex", flexDirection: "column" }}>
          {viewOptions.map((option) => (
            <ListItemButton
              sx={{ gap: 2, borderRadius: 1, marginLeft: 1, marginRight: 1 }}
              key={option.mode}
              onClick={() => {
                handleChange(option.mode);
              }}
              selected={viewMode === option.mode}
            >
              {option.icon}
              <ListItemText
                primary={option.label}
                secondary={option.description}
              />
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </>
  );
});
