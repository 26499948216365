import { FileType, ViewerObject } from "@promaton/scan-viewer";

import { BaseColors } from "./baseColors";

const DesignServicesPalette = {
  arch: BaseColors.base,
  abutment: BaseColors.blue,
  anatomy: BaseColors.gold,
  analog: BaseColors.green,
  gingiva: BaseColors.blue,
  locatorFemale: BaseColors.gold,
  locatorMale: BaseColors.pink,
  primary: BaseColors.orange,
  sleeve: BaseColors.blue,
  screwHole: BaseColors.rust,
  waxup: BaseColors.purple,
  alpha: {
    high: 0.8,
    medium: 0.4,
    low: 0.1,
  },
};

export const applyDesignServicesConvention = (
  id: string,
  data: ViewerObject
) => {
  if (data.color) return;
  if (data.objectType === FileType.PLY) return;

  if (id.match(/arch/i)) {
    data.color = DesignServicesPalette.arch;
    data.opacity = DesignServicesPalette.alpha.medium;
  } else if (id.match(/anatomy/i)) {
    data.color = DesignServicesPalette.anatomy;
    data.opacity = DesignServicesPalette.alpha.high;
  } else if (id.match(/abutment/i)) {
    data.color = DesignServicesPalette.abutment;
  } else if (id.match(/analog/i)) {
    data.color = DesignServicesPalette.analog;
  } else if (id.match(/primary/i)) {
    data.color = DesignServicesPalette.primary;
  } else if (id.match(/gingiva/i)) {
    data.color = DesignServicesPalette.gingiva;
    data.opacity = DesignServicesPalette.alpha.medium;
  } else if (id.match(/locator/i)) {
    if (id.match(/female/i)) {
      data.color = DesignServicesPalette.locatorFemale;
      data.opacity = DesignServicesPalette.alpha.medium;
    } else {
      data.color = DesignServicesPalette.locatorMale;
    }
  } else if (id.match(/sleeve/i)) {
    data.color = DesignServicesPalette.sleeve;
  } else if (id.match(/screwhole/i)) {
    data.color = DesignServicesPalette.screwHole;
  } else if (id.match(/waxup/i)) {
    data.color = DesignServicesPalette.waxup;
    data.opacity = DesignServicesPalette.alpha.medium;
    // data.excludeInOrientations = [ViewOrientation["3D"]];
  }
};
