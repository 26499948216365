import styled from "@emotion/styled";
import {
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { CaretLeft, CaretRight, Close, ViewSidebar } from "@promaton/icons";
import { getNameFromID, useObjects } from "@promaton/scan-viewer";
import { useCallback } from "react";
import { shallow } from "zustand/shallow";

import { ViewName } from "../helpers/viewNames";
import { useAppState, ViewMode } from "../stores/useAppState";

export const SIDE_BY_SIDE_TOOL = "Split View";

const Position = {
  LEFT: "left",
  RIGHT: "right",
  BOTH: "both",
  MIXED: "mixed",
} as const;

type Position = (typeof Position)[keyof typeof Position];

export const SideBySideViewToolWindow = () => {
  const setViewMode = useAppState((s) => s.setViewMode);
  const onClose = useCallback(() => {
    setViewMode(ViewMode.SINGLE);
  }, [setViewMode]);

  const selection = useObjects((s) => Object.keys(s.selection), shallow);
  const selectionPosition = useObjects((s) => {
    const settings = Object.keys(s.selection).map(
      (i) => s.objects[i]?.excludeInViews
    );

    if (settings.length === 0) return;

    const excludedViews = new Set<string>();
    const hasUnspecifiedMembers = settings.some((s) => s === undefined);
    settings.forEach((s) => {
      if (s) {
        s.forEach((v) => excludedViews.add(v));
      }
    });

    if (excludedViews.size === 0) {
      return Position.BOTH;
    }

    if (hasUnspecifiedMembers || excludedViews.size > 1) {
      return Position.MIXED;
    }

    if (excludedViews.size === 1) {
      if (excludedViews.has(ViewName.LEFT)) {
        return Position.RIGHT;
      } else {
        return Position.LEFT;
      }
    }
  });

  const updateObject = useObjects((s) => s.updateObject);
  const handleChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newPosition: Position) => {
      if (newPosition === Position.LEFT) {
        selection.forEach((id) => {
          updateObject(id, { excludeInViews: [ViewName.RIGHT] });
        });
      } else if (newPosition === Position.RIGHT) {
        selection.forEach((id) => {
          updateObject(id, { excludeInViews: [ViewName.LEFT] });
        });
      } else if (newPosition === Position.BOTH) {
        selection.forEach((id) => {
          updateObject(id, { excludeInViews: undefined });
        });
      }
    },
    [selection, updateObject]
  );

  return (
    <ToolWindowContent sx={{ paddingX: 0 }} gap={1}>
      <ViewSidebar sx={{ ml: 1 }} />
      <Typography variant="body2" sx={{ mr: 1 }}>
        {selection.length === 0
          ? "Select objects to move to side"
          : selection.length === 1
          ? getNameFromID(selection[0])
          : `${selection.length} objects`}
      </Typography>
      <ToggleButtonGroup
        disabled={selection.length === 0}
        color="primary"
        exclusive
        size="small"
        value={selectionPosition}
        onChange={handleChange}
      >
        <ToggleButton value={Position.LEFT}>
          <CaretLeft />
        </ToggleButton>
        <ToggleButton value={Position.BOTH}>Both</ToggleButton>
        <ToggleButton value={Position.RIGHT}>
          <CaretRight />
        </ToggleButton>
      </ToggleButtonGroup>
      <Tooltip title="Close side-by-side view">
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Tooltip>
    </ToolWindowContent>
  );
};

const ToolWindowContent = styled(Stack)`
  flex-direction: row;
  align-items: center;
  font-variant-numeric: tabular-nums;
  flex-basis: auto;
  flex-shrink: 1;
  max-width: 100%;
  width: 100%;
`;
