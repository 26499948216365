import { Button } from "@mui/material";
import { processPickedFiles } from "@promaton/file-processing";
import { UploadFolder } from "@promaton/icons";
import { useObjects } from "@promaton/scan-viewer";
import { FC, memo } from "react";
import { useLocation } from "wouter";

export const LocalFilePickerButton: FC<{ accept?: string }> = memo(
  ({ accept }) => {
    const setObjects = useObjects((s) => s.setObjects);
    const [_, setLocation] = useLocation();

    const onOpenFiles = async (files: FileList) => {
      const { objects } = await processPickedFiles(files);
      setObjects(objects);
      setLocation("/");
    };

    return (
      <>
        <input
          style={{ display: "none" }}
          id="contained-button-file"
          multiple
          type="file"
          accept={accept}
          onChange={(e) => {
            const files = e.target.files;
            if (files) {
              onOpenFiles(files);
            }
          }}
        />
        <label htmlFor="contained-button-file">
          <Button
            aria-label="Open files"
            component="span"
            startIcon={<UploadFolder />}
          >
            Open
          </Button>
        </label>
      </>
    );
  }
);
