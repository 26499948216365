import { useState } from "react";
import { useDebounce } from "react-use";
import { StringParam, useQueryParam } from "use-query-params";

export const useDebouncedQueryState = (
  name: string,
  initialValue?: string,
  delay = 100
) => {
  const [param, setParam] = useQueryParam(name, StringParam);
  const [value, setValue] = useState<string | undefined>(param || initialValue);

  useDebounce(
    () => {
      setParam(value);
    },
    delay,
    [value]
  );

  return [param || value, value, setValue] as const;
};
