import {
  FileType,
  inferTypeFromUrl,
  RenderConstants,
  ViewerObjectMap,
} from "@promaton/scan-viewer";

import { BaseColors, getToothColor } from "./baseColors";
import { trimFilePathDepth } from "./util";

const ToothRegex = /(tooth)?.?([1-8]{2})(.\d)?[^/]*$/i;
const ScanRegex = /(scan)?\.?(upper|lower|mandibular|maxilla)[^/]*?$/i;
const GingivaRegex = /gingiva/i;
const BboxRegex = /bbox/i;
const OssSegmentationRegex = /segmentations[^/]*$/i;

export const applyUniversalConvention = async (objects: ViewerObjectMap) => {
  const containedTeeth = Object.keys(objects)
    .map(
      (i) =>
        !BboxRegex.test(i) &&
        !OssSegmentationRegex.test(i) &&
        !/(crown|implant|abutment)/i.test(i) &&
        ToothRegex.exec(i)?.[0]
    )
    .filter((i) => !!i) as string[];
  const hasDuplicateTeeth =
    containedTeeth.length !== new Set(containedTeeth).size;

  const prefix = (group: string | undefined, name: string) => {
    if (hasDuplicateTeeth && group?.includes(name)) return group;
    return hasDuplicateTeeth && group ? `${group} ${name}` : name;
  };

  Object.entries(objects).map(([_id, viewerObject]) => {
    const id = trimFilePathDepth(_id);
    const fileType =
      viewerObject.objectType ?? inferTypeFromUrl(viewerObject.url);

    if (/(crown)/i.test(id)) {
      viewerObject.color = BaseColors.gold;
      viewerObject.group = prefix(viewerObject.group, "Crowns");
    } else if (/(implant)/i.test(id)) {
      viewerObject.color = BaseColors.rust;
      viewerObject.group = prefix(viewerObject.group, "Implants");
    } else if (/(sleeve)/i.test(id)) {
      viewerObject.color = BaseColors.beige;
      viewerObject.opacity = 0.5;
      viewerObject.group = prefix(viewerObject.group, "Sleeves");
    } else if (/(abutment)/i.test(id)) {
      viewerObject.color = BaseColors.gold;
      viewerObject.group = prefix(viewerObject.group, "Abutments");
    } else if (/(preparation)/i.test(id)) {
      viewerObject.color = BaseColors.pink;
      viewerObject.group = prefix(viewerObject.group, "Preparations");
    } else if (/(transition|polyline)/i.test(id)) {
      viewerObject.group = prefix(viewerObject.group, "Transition");
      viewerObject.renderOrder = RenderConstants.renderOrder.MIDDLE;
      viewerObject.color = BaseColors.orange;
    } else if (GingivaRegex.test(id)) {
      viewerObject.group = prefix(viewerObject.group, "Gingiva");
      viewerObject.color = BaseColors.beige;
      viewerObject.renderOrder = RenderConstants.renderOrder.MIDDLE;
      viewerObject.isMetadata = viewerObject.objectType === FileType.JSON;
    } else if (ScanRegex.test(id) || fileType === FileType.DICOM) {
      viewerObject.renderOrder = RenderConstants.renderOrder.FIRST;
      viewerObject.color = viewerObject.color = BaseColors.beige;
      viewerObject.group = prefix(viewerObject.group, "Scans");
    } else if (/attachments/i.test(id)) {
      viewerObject.color = BaseColors.pink;
      viewerObject.group = prefix(viewerObject.group, "Attachments");
    } else if (/screw.+channel/i.test(id)) {
      viewerObject.color = BaseColors.green;
      viewerObject.group = prefix(viewerObject.group, "Screw Channels");
    } else if (/(guide)/i.test(id)) {
      viewerObject.color = BaseColors.azure;
      viewerObject.opacity = 0.8;
      viewerObject.group = prefix(viewerObject.group, "Guides");
    } else if (ToothRegex.test(id) || /tooth/i.test(id)) {
      viewerObject.renderOrder = RenderConstants.renderOrder.MIDDLE;
      const match = ToothRegex.exec(id);
      if (match) {
        viewerObject.color = getToothColor(
          BaseColors.blue,
          parseInt(match?.[2] ?? "0")
        );
      }
      viewerObject.group = prefix(viewerObject.group, "Teeth");

      if (viewerObject.objectType === FileType.JSON) {
        viewerObject.group = prefix(viewerObject.group, "Landmarks");
      }

      if (OssSegmentationRegex.test(id)) {
        viewerObject.hidden = true;
        viewerObject.group = prefix(viewerObject.group, "Segmentations");
      }
    } else if (/corridor/i.test(id)) {
      viewerObject.group = prefix(viewerObject.group, "Corridors");
      viewerObject.dirty = true;

      const buccal = /buccal/i.test(id);
      if (/cervical/i.test(id)) {
        viewerObject.color = buccal ? BaseColors.pink : BaseColors.rust;
      } else if (/equatorial/i.test(id)) {
        viewerObject.color = buccal ? BaseColors.blue : BaseColors.purple;
      } else if (/groove/i.test(id)) {
        viewerObject.color = BaseColors.green;
      } else if (/cusp/i.test(id)) {
        viewerObject.color = BaseColors.orange;
      }
    }

    if (BboxRegex.test(id)) {
      viewerObject.hidden = true;
      viewerObject.group = prefix(viewerObject.group, "Bounding Boxes");
    }
  });
};
