import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Brush, CaretDown1px, CaretRight, Close } from "@promaton/icons";
import { FC, MouseEventHandler, ReactNode } from "react";
import { Helmet } from "react-helmet";

export const SceneTitle: FC<{
  title?: string;
  subtitle?: string;
  onNext?: () => void;
  onClose: () => void;
  onClickTitle?: MouseEventHandler;
  onEditTitle?: () => void;
  children?: ReactNode;
}> = ({
  title,
  subtitle,
  onClose,
  onNext,
  onClickTitle,
  onEditTitle,
  children,
}) => {
  return (
    <>
      <Helmet>
        <title>{title ? `${title} · ` : " "}Promaton Viewer</title>
      </Helmet>
      <Stack
        gap={1}
        flexDirection="row"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <Stack
          onClick={onClickTitle}
          justifyContent={"center"}
          sx={{
            px: 1,
            height: "100%",
            ["&:hover"]: {
              background: (t) =>
                onClickTitle ? t.palette.action.hover : undefined,
              cursor: onClickTitle ? "pointer" : "auto",
            },
          }}
        >
          <Typography
            variant="body1"
            title={title}
            sx={{
              maxWidth: "18vw",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
            }}
          >
            {title || "Loading..."}
            {onEditTitle && (
              <IconButton size="small" onClick={onEditTitle} title="Edit title">
                <Brush fontSize="small" />
              </IconButton>
            )}
            {onClickTitle && <CaretDown1px />}
          </Typography>
          {subtitle && (
            <Typography
              sx={{
                maxWidth: "15vw",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              variant="caption"
            >
              {subtitle}
            </Typography>
          )}
        </Stack>
        {children}
        {onNext && (
          <Tooltip title="Next scan">
            <IconButton size="small" onClick={onNext}>
              <CaretRight />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Close">
          <IconButton size="small" onClick={onClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </Stack>
    </>
  );
};
