import { ObjectWidget, useObjects, useTools } from "@promaton/scan-viewer";
import { useEffect, useRef } from "react";
import { Box3, Box3Helper, Color, Group } from "three";

import { useAppState } from "../stores/useAppState";

export const BoundingBox = () => {
  const registerWidget = useTools((s) => s.registerObjectWidget);

  useEffect(() => {
    registerWidget("BoundingBox", BoundingBoxWidget);
  }, [registerWidget]);

  return null;
};

const BoundingBoxWidget: ObjectWidget = (props) => {
  const container = useRef<Group>(null);
  const helper = useRef<Box3Helper>(null);
  const toolActive = useAppState((s) => s.showBounds);
  const active = useObjects(
    (s) =>
      toolActive &&
      !!(s.selection[props.objectID] || s.highlight === props.objectID)
  );

  useEffect(() => {
    const main =
      container.current?.getObjectByName("main") || container.current;
    if (!main || !active) return;
    main.updateWorldMatrix(true, true);
    helper.current!.box.setFromObject(main, true);
  }, [container.current, helper.current, active]);

  return (
    <group ref={container}>
      {props.children}
      {active && (
        <box3Helper args={[new Box3(), new Color("#aaaaaa")]} ref={helper} />
      )}
    </group>
  );
};
