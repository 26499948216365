import { Stack, styled, SvgIcon, Tooltip, Typography } from "@mui/material";
import { VisibilityOffAlt } from "@promaton/icons";
import { getNameFromID, useObjects } from "@promaton/scan-viewer";
import { ReactComponent as Canine } from "@promaton/theme/assets/Canine.svg";
import { ReactComponent as Incisor } from "@promaton/theme/assets/Incisor.svg";
import { ReactComponent as Molar } from "@promaton/theme/assets/Molar.svg";
import { ReactComponent as Premolar } from "@promaton/theme/assets/Premolar.svg";
import { FC, memo, MouseEvent, useCallback, useMemo } from "react";
import { shallow } from "zustand/shallow";

const Container = styled("div")`
  display: grid;
  width: 100%;
  box-sizing: border-box;
  grid-template-columns: repeat(16, 1fr);
  gap: 1px;
`;

export const FDIMap: FC<{}> = () => {
  const numbers = useMemo(() => {
    return new Array(4 * 8).fill(0).map((_, i) => {
      const q = i >= 3 * 8 ? 3 : i >= 2 * 8 ? 4 : i >= 8 ? 2 : 1;
      const reverse = q == 1 || q == 4;
      const tooth = reverse ? 8 - (i % 8) : 1 + (i % 8);
      return parseInt(`${q}${tooth}`);
    });
  }, []);

  return (
    <Container>
      {numbers.map((num) => (
        <FDITooth number={num} key={num} />
      ))}
    </Container>
  );
};

const FDITooth: FC<{ number: number }> = memo(({ number }) => {
  const q = useMemo(() => {
    return parseInt(`${number}`.charAt(0));
  }, [number]);
  const tooth = useMemo(() => {
    return parseInt(`${number}`.charAt(1));
  }, [number]);

  const [id, obj] = useObjects((s) => {
    const keys = Object.keys(s.objects).filter((key) => {
      const name = key.split("/").at(-1);
      return (
        name?.includes(`${number}`) &&
        !name.match(/detection/i) &&
        !name.match(/[0-9]{3,}/)
      );
    });
    const key = keys.find((key) => !s.objects[key]?.hidden) ?? keys[0];
    return [key || null, key ? s.objects[key] : undefined];
  }, shallow);

  const setHighlight = useObjects((s) => s.setHighlight);
  const setSelection = useObjects((s) => s.setSelection);
  const addToSelection = useObjects((s) => s.addToSelection);
  const removeFromSelection = useObjects((s) => s.removeFromSelection);
  const setEditor = useObjects((s) => s.setObjectEditor);

  const isSelected = useObjects((s) => {
    return !!(id && s.selection[id]);
  });

  const Icon = useMemo(() => {
    return tooth > 5
      ? Molar
      : tooth > 3
      ? Premolar
      : tooth > 2
      ? Canine
      : Incisor;
  }, [tooth]);

  const isLower = q === 3 || q === 4;
  const isRight = q === 3 || q === 2;
  const name = useMemo(() => id && getNameFromID(id), [id]);

  const onEnter = useCallback(() => {
    setHighlight(id);
  }, [id, setHighlight]);
  const onExit = useCallback(() => {
    setHighlight(null);
  }, [id, setHighlight]);

  const onClick = useCallback(
    (e: MouseEvent) => {
      if (!id || obj?.hidden) return;
      if (e.metaKey || e.ctrlKey) {
        isSelected ? removeFromSelection(id) : addToSelection({ [id]: {} });
      } else {
        setSelection(isSelected ? {} : { [id]: {} });
      }
    },
    [id, obj, setSelection, addToSelection, isSelected]
  );

  const onContextMenu = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();

      id &&
        setEditor({
          id: isSelected ? Object.keys(useObjects.getState().selection) : id,
          x: e.clientX,
          y: e.clientY,
        });
    },
    [isSelected, id, setEditor]
  );

  return (
    <Tooltip
      TransitionProps={{
        timeout: 800,
      }}
      placement={isLower ? "bottom" : "top"}
      title={name ?? `Missing ${number}`}
    >
      <Stack
        onClick={onClick}
        onPointerEnter={onEnter}
        onPointerLeave={onExit}
        onContextMenu={onContextMenu}
        sx={{
          flexDirection: isLower ? "column" : "column-reverse",
          alignItems: "center",
          opacity: obj ? 1 : 0.5,
          cursor: "pointer",
          userSelect: "none",
          background: isSelected ? (t) => t.palette.action.selected : "",
          borderRadius: 0.5,

          ["&:hover"]: {
            background: (t) => t.palette.action.hover,
          },
        }}
      >
        <Stack sx={{ position: "relative" }}>
          <SvgIcon
            component={Icon}
            inheritViewBox
            sx={{
              transform: `${isRight ? `scaleX(-1)` : ""} ${
                isLower ? `scaleY(-1)` : ""
              }`,
              width: "100%",
              height: "auto",
              opacity: obj?.hidden ? 0.5 : 1,
              ["& *"]: {
                fill: obj?.color ?? "transparent",
                stroke: "currentColor",
                fillOpacity: 0.7,
              },
            }}
          />
          {obj?.hidden && (
            <VisibilityOffAlt
              fontSize="small"
              sx={{
                background: (t) => t.palette.background.paper,
                borderRadius: "100rem",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                top: isLower ? "20%" : "50%",
              }}
            />
          )}
        </Stack>
        <Typography variant="caption">{number}</Typography>
      </Stack>
    </Tooltip>
  );
});
