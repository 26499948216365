import { useEffect, useMemo, useState } from "react";
import { useInterval } from "react-use";

export const useWaitForElement = (
  selector: string,
  onFail?: () => void,
  attempts = 10
) => {
  const [attempt, setAttempt] = useState(0);
  const element = useMemo(() => {
    return document.querySelector(selector);
  }, [selector, attempt]);

  useInterval(
    () => {
      setAttempt(attempt + 1);
    },
    !element && attempt < attempts ? 100 : null
  );

  useEffect(() => {
    if (!element) {
      setAttempt(0);
    }
  }, [selector, element]);

  useEffect(() => {
    if (attempt === attempts) {
      onFail?.();
      console.warn(selector);
    }
  }, [attempt]);

  return element;
};
