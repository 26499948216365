import {
  FileType,
  useViewerContext,
  ViewerObject,
} from "@promaton/scan-viewer";
import { Mesh } from "three";
import { STLExporter } from "three/examples/jsm/exporters/STLExporter";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";

const meshToStl = (mesh: Mesh) => {
  const exporter = new STLExporter();
  const stl = exporter.parse(mesh, { binary: true });
  return new Blob([stl], { type: "application/octet-stream" });
};

/** Gets mesh from the current scene as STL */
export const getMeshAsStl = async (id: string) => {
  const obj = useViewerContext.getState().getMeshByObjectId(id);
  if (!obj) throw new Error("Object not found");
  return meshToStl(obj);
};

/** Creates STL from STL {@link ViewerObject}, but with transforms applied. */
export const getViewerObjectAsStl = async (viewerObject: ViewerObject) => {
  const loader = new STLLoader();
  if (
    viewerObject.objectType !== FileType.STL ||
    Array.isArray(viewerObject.url)
  ) {
    throw new Error("Object is not an STL");
  }

  const geometry = await loader.loadAsync(viewerObject.url);
  const mesh = new Mesh(geometry);
  mesh.matrixAutoUpdate = false;
  if (viewerObject.transform) {
    mesh.matrix = viewerObject.transform.clone();
    mesh.matrixWorld = viewerObject.transform.clone();
    mesh.updateMatrixWorld(true);
  }

  return meshToStl(mesh);
};
