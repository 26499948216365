import {
  createNotification,
  useCommandPalette,
} from "@promaton/frontend-common";
import {
  getNameFromID,
  useObjects,
  useShortcut,
  useStaging,
  useViews,
  ViewOrientation,
} from "@promaton/scan-viewer";
import { useCallback, useEffect } from "react";
import { z } from "zod";

const SceneState = z.object({
  views: z.array(
    z.object({
      camera: z.object({
        position: z.array(z.number()).optional(),
        quaternion: z.array(z.number()).optional(),
        zoom: z.number().optional(),
        target: z.array(z.number()).optional(),
      }),
      orientation: z.nativeEnum(ViewOrientation).optional(),
    })
  ),
  slices: z.record(z.nativeEnum(ViewOrientation), z.number()),
  objects: z.record(
    z.object({
      color: z.string().optional(),
      opacity: z.number().optional(),
    })
  ),
});

type SceneState = z.infer<typeof SceneState>;

export const CopySceneState = () => {
  const copySceneState = useCallback(() => {
    const { views } = useViews.getState();

    const state: SceneState = {
      views: Object.values(views).map((view) => {
        return {
          camera: {
            position: view!.camera?.position.toArray(),
            quaternion: view!.camera?.quaternion.toArray(),
            zoom: view!.camera?.zoom,
            target: view!.controls?.target.toArray(),
          },
          orientation: view!.orientation,
        };
      }),
      slices: useStaging.getState().slices,
      objects: Object.fromEntries(
        Object.entries(useObjects.getState().objects)
          .filter(([_, v]) => !v?.hidden)
          .map(([key, value]) => {
            return [
              key,
              {
                color: value?.color,
                opacity: value?.opacity,
              },
            ];
          })
      ),
    };

    navigator.clipboard.writeText(JSON.stringify(state));
    createNotification({
      color: "info",
      text: "Scene state copied (paste to apply)",
    });
  }, []);

  useShortcut(
    "c",
    (e) => {
      if (e.ctrlKey || e.metaKey) {
        copySceneState();
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [copySceneState]
  );

  const updateCommand = useCommandPalette((s) => s.updateCommand);
  const removeCommand = useCommandPalette((s) => s.removeCommand);

  useEffect(() => {
    updateCommand("copy-scene-state", {
      name: "Copy scene state",
      description: "Copy the current scene state to the clipboard",
      action: copySceneState,
    });

    return () => {
      removeCommand("copy-scene-state");
    };
  }, []);

  useEffect(() => {
    const onPaste = async (e: ClipboardEvent) => {
      const text = e.clipboardData?.getData("text/plain");
      if (text) {
        try {
          const state = SceneState.parse(JSON.parse(text));
          const targetState = useViews.getState();

          console.log(targetState.views, state.views);

          state.views.forEach((view) => {
            const target =
              Object.entries(targetState.views).find(
                ([, targetView]) => targetView?.orientation === view.orientation
              ) || Object.entries(targetState.views)[0];
            console.log(view, target);
            if (target) {
              const [id, v] = target;
              targetState.updateView(id, {
                orientation: view.orientation,
              });
              if (v?.camera) {
                v.camera.zoom = view.camera.zoom || 1;
                v.camera.updateProjectionMatrix();
                v.camera.quaternion.fromArray(view.camera.quaternion || []);
                v.camera.position.fromArray(view.camera.position || []);
                view.camera.target &&
                  v.controls?.target.fromArray(view.camera.target);
              }
            }
          });

          const targetObjects = useObjects.getState();
          const objectNames = Object.keys(targetObjects.objects);
          Object.entries(state.objects).forEach(([key, value]) => {
            const fileName = getNameFromID(key);
            const targetName = objectNames.find(
              (name) => getNameFromID(name) === fileName
            );
            if (targetName) {
              targetObjects.updateObject(targetName, {
                color: value.color,
                opacity: value.opacity,
              });
            }
          });

          Object.entries(state.slices).forEach(([orientation, position]) => {
            useStaging
              .getState()
              .setSlice(
                orientation as Exclude<ViewOrientation, "3D">,
                position
              );
          });

          createNotification({
            color: "success",
            text: "Scene state applied",
          });
        } catch (e) {
          //
        }
      }
    };

    window.addEventListener("paste", onPaste);
    return () => window.removeEventListener("paste", onPaste);
  }, []);

  return null;
};
