import { Divider, Stack, Typography } from "@mui/material";
import { FC, memo } from "react";

import { FDIMap } from "./FDIMap";
import { Metadata } from "./Metadata";

export const InfoTab: FC<{}> = memo(() => {
  return (
    <Stack
      sx={{
        flex: 1,
        overflowY: "auto",
        padding: 2,
        gap: 2,
        alignItems: "stretch",
        overflowX: "hidden",
      }}
    >
      <Typography variant="subtitle2">FDI Map</Typography>
      <FDIMap />
      <Divider />
      <Metadata />
    </Stack>
  );
});
