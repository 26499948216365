import { useTheme } from "@mui/material";
import { useMemo } from "react";

export const useIsDarkMode = () => {
  const theme = useTheme();
  const isDark = useMemo(() => {
    return theme.palette.mode === "dark";
  }, [theme]);

  return isDark;
};
