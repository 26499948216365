import { ThemeProvider, useTheme } from "@mui/material";
import { CommentPopover, commentTool, CustomCursor, makeAvatar } from "@promaton/frontend-common";
import {
  HtmlNode,
  ObjectWidget,
  useStaging,
  useTools,
  ViewContext,
} from "@promaton/scan-viewer";
import { ThreeEvent } from "@react-three/fiber";
import { FC, useCallback, useContext, useEffect, useRef } from "react";
import { create } from "zustand";

import { ClientProvider } from "../components/ClientProvider";
import { CommentCard } from "../components/CommentCard";
import { type APIOutput, trpc } from "../hooks/trpc";
import { useAppState } from "../stores/useAppState";

type Thread = APIOutput["comment"]["getThreads"][number];

interface OpenDraft {
  type: "draft";
  position: { x: number; y: number; z: number };
  viewId: string;
}

interface OpenExistingThread {
  type: "existing-thread";
  id: string;
  viewId: string;
}

const useOpenThread = create<{
  openThread: OpenDraft | OpenExistingThread | null;
  setOpenThread: (openThread: OpenDraft | OpenExistingThread | null) => void;
}>((set) => ({
  openThread: null,
  setOpenThread: (openThread) => set({ openThread }),
}));

export const SceneComments: FC<{ scene: string }> = ({ scene }) => {
  const viewId = useContext(ViewContext);
  const overlay = useRef(document.getElementById(viewId));
  const session = useAppState((s) => s.session);
  const origin = useStaging((s) => s.scanSize.origin);
  const threads = trpc.comment.getThreads.useQuery(
    { scene },
    {
      enabled: !!session,
    }
  );
  const registerWidget = useTools((s) => s.registerObjectWidget);
  const theme = useTheme();
  const toolActive = useTools((s) => s.activeTool === commentTool);
  const { openThread, setOpenThread } = useOpenThread();

  useEffect(() => {
    registerWidget(commentTool, CommentableWidget);
  }, [registerWidget]);

  if (!toolActive) return null;

  return (
    <group position={[-origin[0], -origin[2], -origin[1]]}>
      <CustomCursor cursor="comment" active={toolActive} />
      {openThread?.type === "draft" && openThread?.viewId === viewId && (
        <HtmlNode
          center
          position={objectPositionToArray(openThread.position)}
          portal={overlay}
        >
          <ThemeProvider theme={theme}>
            <ClientProvider>
              <CommentPopover
                isOpen
                onClose={() => {
                  setOpenThread(null);
                }}
                avatar={makeAvatar(session)}
                renderCard={({ onClose }) => (
                  <CommentCard
                    draftPosition={openThread.position}
                    onClose={onClose}
                    onCreateThread={(id) => {
                      setOpenThread({ type: "existing-thread", id, viewId });
                    }}
                  />
                )}
              />
            </ClientProvider>
          </ThemeProvider>
        </HtmlNode>
      )}

      {threads.data?.filter(threadHasPosition).map((thread) => (
        <HtmlNode
          center
          position={[thread.position.x, thread.position.y, thread.position.z]}
          portal={overlay}
          key={thread.id}
        >
          <ThemeProvider theme={theme}>
            <ClientProvider>
              <CommentPopover
                isOpen={
                  openThread?.type === "existing-thread" &&
                  openThread.id === thread.id &&
                  openThread.viewId === viewId
                }
                onClose={() => setOpenThread(null)}
                onOpen={() =>
                  setOpenThread({
                    type: "existing-thread",
                    id: thread.id,
                    viewId,
                  })
                }
                avatar={makeAvatar(thread.user)}
                renderCard={({ onClose }) => (
                  <CommentCard thread={thread} onClose={onClose} />
                )}
              />
            </ClientProvider>
          </ThemeProvider>
        </HtmlNode>
      ))}
    </group>
  );
};

const CommentableWidget: ObjectWidget = (props) => {
  const toolActive = useTools((s) => s.activeTool === commentTool);
  const viewID = useContext(ViewContext);
  const setOpenThread = useOpenThread((s) => s.setOpenThread);

  const handleCreate = useCallback(
    async (e: ThreeEvent<MouseEvent>) => {
      e.stopPropagation();
      const point = e.point.toArray();
      const origin = useStaging.getState().scanSize.origin;
      setOpenThread({
        type: "draft",
        viewId: viewID,
        position: {
          x: point[0] + origin[0],
          y: point[1] + origin[2],
          z: point[2] + origin[1],
        },
      });
    },
    [setOpenThread, viewID]
  );

  return (
    <group onClick={toolActive ? handleCreate : undefined}>
      {props.children}
    </group>
  );
};

function threadHasPosition(
  thread: Thread
): thread is Thread & { position: NonNullable<Thread["position"]> } {
  return thread.position !== null;
}

function objectPositionToArray(obj: { x: number; y: number; z: number }) {
  return [obj.x, obj.y, obj.z] as const;
}
