import { Backdrop, Stack, styled, Typography } from "@mui/material";
import { FC, memo } from "react";

export const DropOverlay: FC<{ active: boolean }> = memo(({ active }) => {
  return (
    <Backdrop open={active} sx={{ zIndex: 10000, color: "white" }}>
      <Stack
        alignItems={"center"}
        gap={3}
        sx={{ filter: "drop-shadow(0px 10px 40px black);" }}
      >
        {active && (
          <GradientText variant="h4" fontWeight={"bold"}>
            Drop your files to load
          </GradientText>
        )}
      </Stack>
    </Backdrop>
  );
});

const GradientText = styled(Typography)`
  background: linear-gradient(
    45deg,
    #ffa246 0%,
    #e834b1 25%,
    #e3e834 50%,
    #47ffaf 75%,
    #ffa246 100%
  );
  -webkit-text-fill-color: transparent;
  background-clip: text;
  animation:
    scroll 0.3s linear infinite,
    scale 0.5s ease;

  background-size: 50%;

  @keyframes scroll {
    from {
      background-position: left 0%;
    }
    to {
      background-position: right 0%;
    }
  }

  @keyframes scale {
    from {
      opacity: 0;
      transform: scale(0.6);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;
