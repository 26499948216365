import { Comment } from "@promaton/icons";
import { ToolbarToggle } from "@promaton/scan-viewer";
import { FC } from "react";

import { commentTool } from "./commentTool";

export const CommentToggle: FC<{ showBadge: boolean }> = ({ showBadge }) => (
  <ToolbarToggle
    shortcut="c"
    tool={commentTool}
    icon={<Comment />}
    badge={showBadge}
  />
);
