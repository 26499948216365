import { Button, Tooltip } from "@mui/material";
import { createNotification } from "@promaton/frontend-common";
import { useObjects, ViewerObject } from "@promaton/scan-viewer";
import { useShortcut } from "@promaton/scan-viewer";
import { FC, memo } from "react";

import { trpc } from "../hooks/trpc";
import { useAppState } from "../stores/useAppState";

export const UpdateButton: FC<{ id: string }> = memo(({ id }) => {
  const session = useAppState((s) => s.session);
  const scene = trpc.scene.get.useQuery(id, {
    enabled: !!session,
  });
  const utils = trpc.useContext();
  const update = trpc.scene.update.useMutation();

  const handleUpdate = async () => {
    try {
      const objects = useObjects.getState().objects as {
        [key: string]: ViewerObject;
      };

      await update.mutateAsync({
        id,
        name: scene.data?.name,
        data: objects,
      });

      scene.data &&
        utils.scene.get.setData(id, () => ({
          ...scene.data,
          data: { ...objects },
        }));

      createNotification({ color: "success", text: "Scene updated" });
    } catch (error) {
      createNotification({ color: "error", text: "Failed to update" });
    }
  };

  useShortcut(
    "s",
    (e) => {
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
        handleUpdate();
      }
    },
    []
  );

  return (
    <Tooltip title={`Save changes (⌘ + S)`}>
      <Button variant="text" onClick={handleUpdate} disabled={update.isLoading}>
        Update
      </Button>
    </Tooltip>
  );
});
