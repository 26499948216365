import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { CommentToggle,useCommandPalette } from "@promaton/frontend-common";
import {
  Close,
  Menu as MenuIcon,
  Properties,
  SculptPinch,
} from "@promaton/icons";
import {
  StockTool,
  ToolbarToggle,
  useObjects,
  useTools,
  ViewerToolbar,
} from "@promaton/scan-viewer";
import { useShortcut } from "@promaton/scan-viewer";
import { FC, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Route, Switch, useLocation } from "wouter";

import { routes } from "../helpers/routes";
import { trpc } from "../hooks/trpc";
import { useIsPresentationMode } from "../hooks/useIsPresentationMode";
import { useIsPublicViewer } from "../hooks/useIsPublicViewer";
import { SidebarTab, useAppState } from "../stores/useAppState";
import { AddButton } from "./AddButton";
import { AiAssistant } from "./AiAssistant";
import { BoundsToggle } from "./BoundsToggle";
import { useBrowseHistory } from "./BrowseHistory";
import { DownloadButtons } from "./DownloadButtons";
import { KeyboardShortcuts } from "./KeyboardShortcuts";
import { LoginButton } from "./LoginButton";
import { Profile } from "./Profile";
import { S3SceneTitle } from "./SceneTitle/S3SceneTitle";
import { SceneTitle } from "./SceneTitle/SceneTitle";
import { SharedSceneTitle } from "./SceneTitle/SharedSceneTitle";
import { ShareDialog } from "./ShareDialog";
import { ViewToggle } from "./ViewToggle";

export const Menu: FC<{ onOpenFiles: (files: FileList) => void }> = ({
  onOpenFiles,
}) => {
  const [_, setLocation] = useLocation();
  const [shareDialog, setShareDialog] = useState(false);
  const hasObjects = useObjects((s) => Object.keys(s.objects).length > 0);
  const setObjects = useObjects((s) => s.setObjects);
  const sidebarVisible = useAppState((s) => s.sidebarVisible);
  const setSidebarVisible = useAppState((s) => s.setSidebarVisible);
  const showSettings = useAppState((s) => s.showSettings);
  const isOnline = useAppState((s) => s.isOnline);
  const isPublic = useIsPublicViewer();

  const toggleLayerList = () => {
    setSidebarVisible(!sidebarVisible);
  };

  useShortcut(
    "q",
    (e) => {
      if (e.metaKey || e.ctrlKey) return;
      toggleLayerList();
    },
    [sidebarVisible]
  );

  const sessionToken = useAppState((s) => s.session);

  const closeRoute = useCallback(() => {
    const lastLocation = useBrowseHistory.getState().lastLocation;
    setObjects({});
    setLocation(lastLocation || "/");
  }, [setLocation]);

  const isPresentationMode = useIsPresentationMode();

  const updateCommand = useCommandPalette((s) => s.updateCommand);
  const removeCommand = useCommandPalette((s) => s.removeCommand);

  useEffect(() => {
    updateCommand("toggleLayerList", {
      name: sidebarVisible ? "Hide sidebar" : "Show sidebar",
      description: "Sets visibility of sidebar",
      action: () => toggleLayerList(),
    });
    updateCommand("showInfoTab", {
      name: "Show info tab",
      description: "Shows info tab including FDI map, JSON files and metadata",
      action: () => {
        setSidebarVisible(true);
        useAppState.getState().setSidebarTab(SidebarTab.INFO);
      },
    });
    updateCommand("showLayerTab", {
      name: "Show layers tab",
      description: "Shows grouped list of all objects in the scene",
      action: () => {
        setSidebarVisible(true);
        useAppState.getState().setSidebarTab(SidebarTab.LAYERS);
      },
    });

    updateCommand("moveTool", {
      action: () => {
        useTools.getState().setActiveTool(StockTool.TRANSFORM);
      },
      name: "Use move tool",
      description: "Change the position and rotation of objects",
      keywords: "transform edit",
    });
    updateCommand("measureTool", {
      action: () => {
        useTools.getState().setActiveTool(StockTool.MEASURE);
      },
      name: "Use measure tool",
      description: "Measure distancs between points",
      keywords: "length distance ruler",
    });
    updateCommand("sculptTool", {
      name: "Use sculpt tool",
      description: "Sculpt and smoothen the surface of objects",
      action: () => {
        useTools.getState().setActiveTool(StockTool.SCULPT);
      },
      keywords: "sculpt sculpting brush edit shape morph warp scale deform",
    });

    updateCommand("closeRoute", {
      name: "Close scene",
      description: "Close the current scene and return to the home screen",
      action: () => {
        closeRoute();
      },
    });

    updateCommand("togglePresentationMode", {
      name: isPresentationMode
        ? "Exit presentation mode"
        : "Enter presentation mode",
      description:
        "Presentation mode hides all UI and automatically rotates the camera",
      action: () => {
        window.location.search = `?presentation=${!isPresentationMode}`;
      },
    });

    return () => {
      removeCommand("toggleLayerList");
      removeCommand("showInfoTab");
      removeCommand("showLayerTab");
      removeCommand("moveTool");
      removeCommand("measureTool");
      removeCommand("sculptTool");
      removeCommand("closeRoute");
      removeCommand("togglePresentationMode");
    };
  }, [toggleLayerList, sidebarVisible, isPresentationMode]);

  if (isPresentationMode) return null;

  return (
    <AppBar
      position="static"
      color="inherit"
      variant="outlined"
      sx={{
        borderWidth: 0,
        borderBottomWidth: 1,
        position: "relative",
        zIndex: 3,
        height: {
          xs: 120,
          sm: "initial",
        },
      }}
    >
      <Toolbar
        sx={{
          gap: (t) => t.spacing(1),
          paddingLeft: {
            xs: 2,
            sm: 1,
          },
          paddingRight: {
            xs: 8,
            sm: 2,
          },
        }}
      >
        <Tooltip title="Toggle layer list (Q)" enterDelay={500}>
          <IconButton onClick={toggleLayerList}>
            <MenuIcon />
          </IconButton>
        </Tooltip>

        <Switch>
          <Route path={routes.s3}>
            {({ folder, bucket }) => {
              return (
                <S3SceneTitle
                  folder={decodeURIComponent(folder ?? "")}
                  bucket={bucket ?? ""}
                  onClose={closeRoute}
                />
              );
            }}
          </Route>
          <Route path={routes.scene}>
            {({ id }) => (
              <SharedSceneTitle id={id ?? ""} onClose={closeRoute} />
            )}
          </Route>
          <Route path={routes.dlh1}>
            {({ slug }) => <SceneTitle title={slug} onClose={closeRoute} />}
          </Route>
          <Route path={routes.dlh2}>
            {({ slug }) => <SceneTitle title={slug} onClose={closeRoute} />}
          </Route>
          <Route>
            <Helmet>
              <title>Promaton Viewer</title>
            </Helmet>
            <AddButton onOpenFiles={onOpenFiles} />
            {hasObjects && (
              <Tooltip title="Clear view">
                <IconButton
                  disableRipple
                  onClick={closeRoute}
                  sx={{
                    position: {
                      xs: "absolute",
                      sm: "initial",
                    },
                    right: (t) => t.spacing(2),
                  }}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            )}
          </Route>
        </Switch>

        <span style={{ flexGrow: 1 }} />

        <KeyboardShortcuts />

        <Switch>
          <Route path={routes.s3}>
            {({ folder }) => (
              <DownloadButtons name={folder?.split("/").pop() || "scan"} />
            )}
          </Route>
          <Route path={routes.scene}>
            {({ id }) => <DownloadButtons name={id ?? ""} />}
          </Route>
          <Route>
            <DownloadButtons name="promaton-archive" />
          </Route>
        </Switch>

        {isPublic && (
          <IconButton onClick={() => showSettings(true)}>
            <Properties />
          </IconButton>
        )}

        {isPublic ? null : sessionToken ? (
          <>
            {hasObjects && (
              <Button
                disabled={!isOnline}
                onClick={() => setShareDialog(true)}
                variant="outlined"
                sx={{ textTransform: "none", fontWeight: "bold", mr: 1 }}
              >
                Share
              </Button>
            )}
            <ShareDialog
              onClose={() => setShareDialog(false)}
              onOpen={() => setShareDialog(true)}
              open={shareDialog}
            />

            <Profile />
          </>
        ) : (
          <LoginButton />
        )}
      </Toolbar>
      <Box
        component="div"
        sx={{
          position: "absolute",
          display: "flex",
          borderTop: (t) => ({
            xs: `1px solid ${t.palette.divider}`,
            sm: "none",
          }),
          justifyContent: {
            xs: "flex-start",
            sm: "center",
          },
          width: { xs: "100%", sm: "auto" },
          maxWidth: "100%",
          overflowX: {
            xs: "scroll",
            sm: "initial",
          },
          left: { sm: "50%" },
          height: {
            sm: "100%",
          },
          transform: {
            sm: `translateX(-50%)`,
          },
          bottom: {
            xs: 0,
            sm: "initial",
          },
        }}
      >
        <ViewerToolbar sx={{ gap: 1 }}>
          <Route path={routes.scene}>
            {({ id }) => <ServerAwareCommentToggle sceneId={id} />}
          </Route>
          <ToolbarToggle
            tool={StockTool.SCULPT}
            shortcut="l"
            icon={<SculptPinch />}
          />
          <AiAssistant />
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              display: {
                xs: "none",
                sm: "block",
              },
            }}
          />
          <ViewToggle />
          <BoundsToggle />
        </ViewerToolbar>
      </Box>
    </AppBar>
  );
};

const ServerAwareCommentToggle = ({ sceneId }: { sceneId: string | undefined }) => {
  const threads = trpc.comment.getThreads.useQuery(
    {
      scene: sceneId ?? "",
    },
    { enabled: sceneId !== undefined }
  );

  return <CommentToggle showBadge={(threads.data?.length || 0) > 0} />;
};
