import React, { useCallback, useEffect, useMemo, useState } from "react";

export interface DropAreaState {
  over: boolean;
}

export interface DropAreaBond {
  onDragOver: React.DragEventHandler;
  onDragEnter: React.DragEventHandler;
  onDragLeave: React.DragEventHandler;
  onDrop: React.DragEventHandler;
  onPaste: React.ClipboardEventHandler;
}

export interface DropAreaOptions {
  onDataTransfer: (dataTransfer: DataTransferItemList) => void;
}

const createProcess =
  (options: DropAreaOptions) => (dataTransfer: DataTransfer) => {
    options.onDataTransfer(dataTransfer.items);
  };

export const useDrop = (options: DropAreaOptions): DropAreaState => {
  const [over, setOverRaw] = useState<boolean>(false);
  const setOver = useCallback(setOverRaw, [setOverRaw]);
  const process = useMemo(() => createProcess(options), [options]);

  useEffect(() => {
    const onDragOver = (event: DragEvent) => {
      event.preventDefault();
      const types = event.dataTransfer?.types;
      if (!types?.find((s) => s === "Files")) return;
      setOver(true);
    };

    const onDragEnter = (event: DragEvent) => {
      event.preventDefault();
      const types = event.dataTransfer?.types;
      if (!types?.find((s) => s === "Files")) return;
      setOver(true);
    };

    const onDragLeave = () => {
      setOver(false);
    };

    const onDragExit = () => {
      setOver(false);
    };

    const onDrop = (event: DragEvent) => {
      event.preventDefault();
      setOver(false);
      const types = event.dataTransfer?.types;
      if (!types?.find((s) => s === "Files")) return;
      event.dataTransfer && process(event.dataTransfer);
    };

    const onPaste = (event: ClipboardEvent) => {
      const types = event.clipboardData?.types;
      if (!types?.find((s) => s === "Files")) return;
      event.clipboardData && process(event.clipboardData);
    };

    document.addEventListener("dragover", onDragOver);
    document.addEventListener("dragenter", onDragEnter);
    document.addEventListener("dragleave", onDragLeave);
    document.addEventListener("dragexit", onDragExit);
    document.addEventListener("drop", onDrop);
    document.addEventListener("paste", onPaste);

    return () => {
      document.removeEventListener("dragover", onDragOver);
      document.removeEventListener("dragenter", onDragEnter);
      document.removeEventListener("dragleave", onDragLeave);
      document.removeEventListener("dragexit", onDragExit);
      document.removeEventListener("drop", onDrop);
      document.removeEventListener("paste", onPaste);
    };
  }, [process, setOver]);

  return { over };
};
