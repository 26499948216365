import { FileType, ViewerObjectMap } from "@promaton/scan-viewer";

import { BaseColors, getToothColor } from "./baseColors";

const SiabRegex = /siab/i;
const SiabToothRegex = /TOOTH \([0-9]{1,2}\)/i;

/** Somewhat hacky way of detecting we are looking at an SiaB output. */
export const checkIsSmileInABox = (objects: ViewerObjectMap) => {
  const entries = Object.entries(objects);

  const hasSiabResults = !!entries.find(([key]) => key.match(SiabRegex));

  return hasSiabResults;
};

export const applySmileInABoxConvention = (objects: ViewerObjectMap) => {
  Object.entries(objects).map(([id, viewerObject]) => {
    if (viewerObject.objectType === FileType.DICOM) {
      viewerObject.group = "CBCT";
    } else if (id.match(/(Maxilla|Mandible)/i)) {
      viewerObject.group = "Jaws";
      viewerObject.color = BaseColors.beige;
    } else if (id.match(/nerve/i)) {
      viewerObject.color = BaseColors.orange;
      viewerObject.group = "Nerves";
    } else if (id.match(/screw/i)) {
      viewerObject.color = BaseColors.pink;
      viewerObject.group = "Screws";
    } else if (id.match(/guide/i)) {
      viewerObject.color = BaseColors.green;
      viewerObject.group = "Guides";
      viewerObject.opacity = 0.5;
    } else if (id.match(/cut/i)) {
      viewerObject.color = BaseColors.purple;
      viewerObject.group = "Cuts";
      viewerObject.opacity = 0.5;
    } else if (id.split("/").at(-1)?.match(SiabRegex)) {
      viewerObject.group = "Smile in a Box";
      viewerObject.color = BaseColors.gold;
    } else if (id.match(SiabToothRegex)) {
      viewerObject.group = "Segmentations";
      viewerObject.color = getToothColor(
        BaseColors.blue,
        parseInt(id.match(/[0-9]{2}$/)?.[0] ?? "0")
      );
    }
  });
};
