import type { APIRouter } from "@promaton/viewer-server/src";
import { createTRPCReact, TRPCClientError } from "@trpc/react-query";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";

export const trpc = createTRPCReact<APIRouter>();

export type TError = TRPCClientError<APIRouter>;

export type APIInput = inferRouterInputs<APIRouter>;
export type APIOutput = inferRouterOutputs<APIRouter>;
