import { ViewerObjectMap } from "@promaton/scan-viewer";

import { applyDesignServicesConvention } from "./conventionDesignServices";
import { applyCBCTConvention, checkIsCBCT } from "./conventionInternalCBCT";
import { applyOSSConvention, checkIsOSS } from "./conventionInternalOSS";
import {
  applySmileInABoxConvention,
  checkIsSmileInABox,
} from "./conventionSmileInABox";
import { applyUniversalConvention } from "./conventionUniversal";
import { applyViewerConfig } from "./viewerConfigFile";

export enum Convention {
  OSS = "OSS",
  CBCT = "CBCT",
  SIAB = "SIAB",
  OTHER = "OTHER",
}

export const applyColorCoding = async (
  objects: ViewerObjectMap
): Promise<Convention | undefined> => {
  let convention: Convention | undefined = undefined;
  applyUniversalConvention(objects);
  if (await checkIsOSS(objects)) {
    await applyOSSConvention(objects);
    convention = Convention.OSS;
  } else if (checkIsCBCT(objects)) {
    applyCBCTConvention(objects);
    convention = Convention.CBCT;
  } else if (checkIsSmileInABox(objects)) {
    applySmileInABoxConvention(objects);
    convention = Convention.SIAB;
  } else {
    Object.entries(objects).map(([id, viewerObject]) => {
      applyDesignServicesConvention(id, viewerObject);
    });
    convention = Convention.OTHER;
  }

  await applyViewerConfig(objects).catch((error) => {
    console.error("Failed to apply viewer config", error);
  });

  return convention;
};
