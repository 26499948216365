import { Avatar, Button, InputBase, Stack, Typography } from "@mui/material";
import { type ReactNode, useEffect } from "react";
import { useForm } from "react-hook-form";

import type { AvatarData } from "./AvatarData";

export type CommentFormData = {
  text: string;
};

interface AddCommentFormProps {
  avatar?: AvatarData;
  isDraft: boolean;
  placeholder: string;
  autoFocus?: boolean;
  onSubmit: (data: CommentFormData) => Promise<void>;
  otherActions?: ReactNode;
}

export const AddCommentForm = ({
  avatar,
  isDraft,
  placeholder,
  autoFocus = false,
  onSubmit,
  otherActions,
}: AddCommentFormProps) => {
  const { formState, register, handleSubmit, reset } =
    useForm<CommentFormData>();

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ text: "" });
    }
  }, [formState, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        padding={2}
        gap={1}
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Stack flexDirection="row" gap={2}>
          {avatar !== undefined && (
            <Avatar
              src={avatar.picture}
              imgProps={{
                draggable: false,
              }}
            >
              {avatar.name?.slice(0, 2)}
            </Avatar>
          )}
          <InputBase
            autoFocus={autoFocus}
            placeholder={placeholder}
            {...register("text", {
              required: true,
              minLength: 1,
            })}
            autoComplete="off"
            multiline
            maxRows={4}
            sx={{ flex: 1 }}
            onKeyDown={(e) => {
              if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
                handleSubmit(onSubmit)();
              }
            }}
          />
        </Stack>
        <Stack flexDirection="row" gap={1} alignItems="center">
          {formState.isValid && <SendShortcut />}
          <div style={{ flex: 1 }} />
          {otherActions}
          <Button
            variant="contained"
            type="submit"
            disabled={
              !formState.isDirty || !formState.isValid || formState.isSubmitting
            }
          >
            {isDraft ? "Post" : "Reply"}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

const SendShortcut = () => {
  const isMac = navigator.platform.includes("Mac");

  return (
    <Typography
      variant="caption"
      sx={{
        color: "text.secondary",
        "*:not(:focus-within) + * > &": { display: "none" },
      }}
    >
      {isMac ? "⌘ + Enter" : "Ctrl+Enter"} to send
    </Typography>
  );
};
