import {
  AcdTask,
  AlignmentTask,
  CbctTask,
  OssTask,
  SippTask,
  TscTask,
} from "@promaton/api-client";
import { createNotification } from "@promaton/frontend-common";
import ga4 from "react-ga4";

import {
  AiTaskData,
  AiTaskType,
  useAiAssistantState,
} from "../../stores/useAiAssistantState";
import { loadAcdTaskResults } from "./loadAcdTaskResults";
import { loadAlignmentTaskResults } from "./loadAlignmentTaskResults";
import { loadCbctResults } from "./loadCbctResults";
import { loadOssTaskResults } from "./loadOssTaskResults";
import { loadSippTaskResults } from "./loadSippTaskResults";
import { loadTscTaskResults } from "./loadTscTaskResults";

export const loadAiTaskResults = async (task: AiTaskData) => {
  try {
    if (task.type === AiTaskType.ALIGNMENT) {
      await loadAlignmentTaskResults(task as any as AlignmentTask);
      ga4.event({
        category: "AI",
        action: "LoadAlignmentTaskResult",
      });
    } else if (task.type === AiTaskType.OPTICAL) {
      await loadOssTaskResults(task as OssTask);
      ga4.event({
        category: "AI",
        action: "LoadOssTaskResult",
      });
    } else if (task.type === AiTaskType.CBCT) {
      await loadCbctResults(task as CbctTask);
      ga4.event({
        category: "AI",
        action: "LoadCbctTaskResult",
      });
    } else if (task.type === AiTaskType.ACD) {
      await loadAcdTaskResults(task as AcdTask);
      ga4.event({
        category: "AI",
        action: "LoadAcdTaskResult",
      });
    } else if (task.type === AiTaskType.TSC) {
      await loadTscTaskResults(task as TscTask);
      ga4.event({
        category: "AI",
        action: "LoadTscTaskResult",
      });
    } else if (task.type === AiTaskType.SIPP) {
      await loadSippTaskResults(task as SippTask);
      ga4.event({
        category: "AI",
        action: "LoadTscTaskResult",
      });
    } else if (task.type === AiTaskType.CBCT_FILLED_JAW) {
      await loadCbctResults(task as CbctTask);
      ga4.event({
        category: "AI",
        action: "LoadJawFillingTaskResult",
      });
    }
  } catch (error) {
    console.warn(error);
    createNotification({
      color: "error",
      text: "Failed to load task results",
    });
  }
  useAiAssistantState.setState({
    resultLoadingProgress: 0,
    latestTaskLoaded: task,
  });
};
