import { IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { Plus } from "@promaton/icons";
import { useShortcut } from "@promaton/scan-viewer";
import React, { useState } from "react";

import { useIsPublicViewer } from "../hooks/useIsPublicViewer";
import { useAppState } from "../stores/useAppState";
import { S3LoadingDialog } from "./S3LoadingDialog";
export function AddButton({
  onOpenFiles,
}: {
  onOpenFiles: (files: FileList) => void;
}) {
  const filePicker = React.useRef<HTMLInputElement>(null);
  const [s3LoadingDialog, setS3LoadingDialog] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const isOnline = useAppState((s) => s.isOnline);
  const isPublic = useIsPublicViewer();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useShortcut("o", (e) => {
    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
      if (e.shiftKey) {
        if (!isOnline) return;
        setS3LoadingDialog(true);
      } else {
        filePicker.current?.click();
      }
    }
  });

  if (isPublic) {
    return (
      <>
        <input
          ref={filePicker}
          style={{ display: "none" }}
          id="contained-button-file"
          multiple
          type="file"
          onChange={(e) => {
            const files = e.target.files;
            if (files) {
              onOpenFiles(files);
            }
          }}
        />
        <label htmlFor="contained-button-file">
          <Tooltip title="Add files to view">
            <IconButton aria-label="Open files" component="span">
              <Plus />
            </IconButton>
          </Tooltip>
        </label>
      </>
    );
  }

  return (
    <>
      <Tooltip title="Add files">
        <IconButton
          aria-label="Open files"
          onClick={handleOpenUserMenu}
          component="span"
        >
          <Plus />
        </IconButton>
      </Tooltip>
      <S3LoadingDialog
        open={s3LoadingDialog}
        onClose={() => setS3LoadingDialog(false)}
      />
      <input
        ref={filePicker}
        style={{ display: "none" }}
        id="contained-button-file"
        multiple
        type="file"
        onChange={(e) => {
          const files = e.target.files;
          if (files) {
            onOpenFiles(files);
          }
        }}
      />

      <Menu
        sx={{
          mt: "45px",
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <label htmlFor="contained-button-file">
          <MenuItem
            onClick={() => {
              handleCloseUserMenu();
            }}
          >
            <Typography textAlign="center">Open files</Typography>
          </MenuItem>
        </label>
        <MenuItem
          disabled={!isOnline}
          onClick={() => {
            setS3LoadingDialog(true);
            handleCloseUserMenu();
          }}
        >
          <Typography textAlign="center">Load from S3</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
