import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { Keyboard } from "@promaton/icons";
import { FC, Fragment, memo, useState } from "react";

import { altKey, metaKey } from "../helpers/userAgent";
import { useIsMobile } from "../hooks/useIsMobile";
import { OnboardingStepSelector } from "../stores/useOnboarding";

export const KeyboardShortcuts = memo(() => {
  const [open, setOpen] = useState(false);
  const mobile = useIsMobile();

  if (mobile) return null;

  return (
    <>
      <Tooltip title={"Keyboard shortcuts"}>
        <IconButton
          onClick={() => setOpen(true)}
          id={OnboardingStepSelector.KEYBOARD_SHORTCUTS}
        >
          <Keyboard sx={{ pointerEvents: "none" }} />
        </IconButton>
      </Tooltip>
      <Drawer
        PaperProps={{
          variant: "outlined",
          elevation: undefined,
        }}
        anchor={"right"}
        open={open}
        onClose={() => setOpen(false)}
      >
        <List
          sx={{
            width: 350,
            overflowY: "scroll",
          }}
        >
          <ListItem>
            <Typography variant="h6">Shortcuts</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="subtitle1">General</Typography>
          </ListItem>
          <Entry label={"Open file"} keys={[metaKey, "O"]} />
          <Entry label={"Open from S3"} keys={[metaKey, "Shift", "O"]} />
          <Entry label={"Load from clipboard"} keys={[metaKey, "V"]} />
          <Entry label={"Center on point"} keys={["Middle click"]} />
          <Entry label={"Center on point (alt)"} keys={[altKey, "click"]} />
          <Entry label={"Modify object"} keys={["Right click"]} />
          <Entry label={"Focus object"} keys={["Double click"]} />
          <Entry label={"Select object(s)"} keys={[metaKey, "Click"]} />
          <Entry label={"Close tool / selection"} keys={["ESC"]} />
          <Entry label={"Show settings"} keys={[metaKey, ","]} />
          <Entry label={"Copy scene state"} keys={[metaKey, "C"]} />
          <Entry label={"Paste scene state"} keys={[metaKey, "V"]} />
          <Entry label={"Switch view mode"} keys={["V"]} />
          <Entry label={"Recenter (on selection)"} keys={["1"]} />
          <Entry label={"View Axial (and center)"} keys={["2"]} />
          <Entry label={"View Coronal (and center)"} keys={["3"]} />
          <Entry label={"View Sagittal (and center)"} keys={["4"]} />

          <ListItem sx={{ marginTop: 3 }}>
            <Typography variant="subtitle1">Tools</Typography>
          </ListItem>
          <Entry label={"Layer list"} keys={["Q"]} />
          <Entry label={"Measure"} keys={["E"]} />
          <Entry label={"Bounds"} keys={["B"]} />

          <ListItem sx={{ marginTop: 3 }}>
            <Typography variant="subtitle1">CBCT views</Typography>
          </ListItem>
          <Entry label={"Scroll slices"} keys={[altKey, "Scrollwheel"]} />
        </List>
      </Drawer>
    </>
  );
});

const Entry: FC<{ label: string; keys: string[] }> = memo(({ label, keys }) => {
  return (
    <ListItem divider sx={{ paddingY: 1.5 }}>
      <ListItemText
        secondary={label}
        secondaryTypographyProps={{
          sx: { color: (theme) => theme.palette.text.primary },
        }}
      />
      <ListItemSecondaryAction
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 0.5,
        }}
      >
        {keys.map((key) => (
          <Fragment key={key}>
            <Paper
              sx={{
                padding: 1,
                paddingTop: 0.5,
                paddingBottom: 0.5,
                boxShadow: "0px 1px 1px 1px rgba(0,0,0,0.3)",
                background: (theme) => theme.palette.background.default,
              }}
            >
              <Typography variant="body2">{key}</Typography>
            </Paper>
          </Fragment>
        ))}
      </ListItemSecondaryAction>
    </ListItem>
  );
});
