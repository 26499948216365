import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  Stack,
} from "@mui/material";
import {
  createNotification,
  useCommandPalette,
} from "@promaton/frontend-common";
import { Copy } from "@promaton/icons";
import { useObjects, useStaging } from "@promaton/scan-viewer";
import { FC, memo, useEffect, useMemo } from "react";
import ga4 from "react-ga4";
import { useForm } from "react-hook-form";
import { useCopyToClipboard, useCounter } from "react-use";
import { useLocation, useRoute } from "wouter";

import { createRouteLink, routes } from "../helpers/routes";
import { trpc } from "../hooks/trpc";

interface SceneCreateForm {
  name: string;
}

export const ShareDialog: FC<{
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
}> = memo(({ open, onClose: handleClose, onOpen }) => {
  const [_, setLocation] = useLocation();
  const [__, copy] = useCopyToClipboard();
  const [___, sceneParams] = useRoute(routes.scene);
  const utils = trpc.useContext();
  const shareURL = useMemo(
    () =>
      sceneParams &&
      `${location.origin}${createRouteLink(routes.scene, {
        id: sceneParams.id,
      })}`,
    [sceneParams]
  );
  const createScene = trpc.scene.create.useMutation();
  const [total, { set: setTotal }] = useCounter(0);
  const [loaded, { inc: incLoaded, set: setLoaded }] = useCounter(0);

  const { register, handleSubmit, formState } = useForm<SceneCreateForm>();

  const handleCreate = async (formData: SceneCreateForm) => {
    try {
      const data = useObjects.getState().objects;
      const coordinateSystem = useStaging.getState().coordinateSystem;

      // First create scene, then make sure files are uploaded / moved
      const [scene, filesToUpload] = await createScene.mutateAsync({
        name: formData.name,
        data: data,
        coordinateSystem,
      });

      utils.scene.list.invalidate();

      setTotal(filesToUpload.length);

      /**
       * If user doesn't wait for upload to be completed, the scene won't
       * fully load. This is probably acceptable, as the scene would get cleaned
       * up after a while anyway.
       */
      await Promise.all(
        filesToUpload.map(async ({ sourceUrl, uploadUrl }) => {
          const file = await (await fetch(sourceUrl)).blob();
          await fetch(uploadUrl, { method: "put", body: file });
          incLoaded(1);
        })
      );

      setLoaded(0);
      setTotal(0);

      setLocation(createRouteLink(routes.scene, { id: scene.id }));

      ga4.event({
        category: "sharing",
        action: "scene shared",
      });
    } catch (error) {
      createNotification({ color: "error", text: "Failed to create project" });
      setLoaded(0);
      setTotal(0);
    }
  };

  const updateCommand = useCommandPalette((s) => s.updateCommand);
  const removeCommand = useCommandPalette((s) => s.removeCommand);

  useEffect(() => {
    updateCommand("openShareDialog", {
      name: "Share project",
      description: "Get a shareable link to the currently loaded files",
      keywords: "share link scene upload project",
      action: () => {
        onOpen();
      },
    });

    return () => {
      removeCommand("openShareDialog");
    };
  }, [onOpen]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (total > 0 && !sceneParams) return;
        handleClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Stack sx={{ maxWidth: "100%", width: 600 }}>
        <DialogTitle id="alert-dialog-title">Share scene</DialogTitle>
        <DialogContent>
          {shareURL ? (
            <Stack gap={2} width={"100%"}>
              <DialogContentText id="alert-dialog-description">
                Anyone at Promaton has access to view the scene.
              </DialogContentText>
              <FormControl fullWidth variant="filled">
                <InputLabel htmlFor="copy-button">URL</InputLabel>
                <FilledInput
                  id="copy-button"
                  contentEditable={false}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          copy(shareURL);
                          createNotification({
                            color: "success",
                            text: "Copied to clipboard",
                          });
                        }}
                      >
                        <Copy />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={shareURL}
                />
              </FormControl>
            </Stack>
          ) : total > 0 || createScene.isLoading ? (
            <Stack gap={2}>
              <DialogContentText id="alert-dialog-description">
                {total > 0
                  ? `Uploading ${loaded} of ${total} files.`
                  : `Creating scene`}
              </DialogContentText>
              <LinearProgress
                value={total ? (100 * loaded) / total : undefined}
                variant={total ? "determinate" : "indeterminate"}
              />
            </Stack>
          ) : (
            <Stack gap={2}>
              <DialogContentText id="alert-dialog-description">
                Create a shareable link to give others viewing access. This will
                upload / copy the loaded files and store the scene for 60 days.
                You can update or delete the scene at any time.
              </DialogContentText>
              <form onSubmit={handleSubmit(handleCreate)}>
                <Stack flexDirection="row" gap={2}>
                  <FormControl fullWidth variant="filled">
                    <InputLabel htmlFor="copy-button">Scene name</InputLabel>
                    <FilledInput
                      autoComplete="off"
                      {...register("name", {
                        required: true,
                        minLength: 1,
                        disabled: formState.isSubmitting,
                      })}
                    />
                  </FormControl>
                  <Button
                    disabled={!formState.isDirty || formState.isSubmitting}
                    variant="contained"
                    type="submit"
                    sx={{
                      flex: "0 0 auto",
                    }}
                  >
                    Create link
                  </Button>
                </Stack>
              </form>
            </Stack>
          )}
        </DialogContent>
      </Stack>
    </Dialog>
  );
});
