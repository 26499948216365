import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { FC, memo } from "react";

import { LoginButton } from "./LoginButton";

export const LoginDialog: FC<{
  title: string;
  open: boolean;
  onClose: () => void;
}> = memo(({ title, open, onClose: handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <Stack sx={{ width: 500, padding: 2 }}>
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <LoginButton
            size="large"
            sx={{ width: "100%", marginTop: 2 }}
            label="Sign in with Google"
          />
        </DialogContent>
      </Stack>
    </Dialog>
  );
});
