import {
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  LinearProgress,
  ListItem,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Brush,
  CrossCircle,
  FolderAlt,
  ObjectCube,
  Search,
} from "@promaton/icons";
import { FC, memo, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Virtuoso } from "react-virtuoso";
import { useLocation } from "wouter";

import { formatDate } from "../helpers/dateFormatter";
import { createRouteLink, routes } from "../helpers/routes";
import { APIInput, APIOutput, trpc } from "../hooks/trpc";
import { useDebouncedQueryState } from "../hooks/useDebouncedQueryState";
import { useAppState } from "../stores/useAppState";
import { BrowserItem } from "./BrowserItem";
import { EmptyState } from "./EmptyState";
import { LoginDialog } from "./LoginDialog";

type ScanType = APIInput["storage"]["dlh2"]["query"]["scanType"];
const scanTypeOptions: Record<Exclude<ScanType, undefined>, string> = {
  IOS: "IOS",
  CBCT: "CBCT",
};

const AnnotationStateFilter = {
  ALL: "All",
  UNANNOTATED: "Unannotated",
  ANNOTATED: "Annotated",
} as const;

type Source = APIInput["storage"]["dlh2"]["query"]["source"];

const sources: Record<Exclude<Source, undefined>, string> = {
  scanandshape: "Scan and Shape",
  apiupload: "API Upload",
  clearcorrect: "ClearCorrect",
  dentalwings: "Dental Wings",
  designservices: "Design Services",
  cdx: "coDiagnostiX",
  altman: "Altman",
};

type CaseData = APIOutput["storage"]["dlh2"]["query"][number];

export const Dlh2Browser = memo(() => {
  const [_, setLocation] = useLocation();
  const [slug, searchInput, setSearchInput] = useDebouncedQueryState("search");
  const [source, sourceInput, setSourceInput] =
    useDebouncedQueryState("source");
  const [scanType, scanTypeInput, setScanTypeInput] =
    useDebouncedQueryState("scanType");
  const [annotationState, annotationStateInput, setAnnotationStateInput] =
    useDebouncedQueryState("annotationState");

  const session = useAppState((s) => s.session);
  let annotatedQueryInput: boolean | undefined = undefined;

  if (annotationState === AnnotationStateFilter.ANNOTATED) {
    annotatedQueryInput = true;
  }

  if (annotationState === AnnotationStateFilter.UNANNOTATED) {
    annotatedQueryInput = false;
  }

  const caseData = trpc.storage.dlh2.query.useInfiniteQuery(
    {
      slug: slug || undefined,
      source: (source || undefined) as Source,
      annotated: annotatedQueryInput,
      scanType: (scanType || undefined) as ScanType,
    },
    {
      enabled: !!session,
      getNextPageParam: (lastPage) => lastPage.at(-1)?.id,
    }
  );

  const cases = useMemo(() => {
    return caseData.data?.pages.flat();
  }, [caseData.data]);

  if (!session) {
    return (
      <LoginDialog
        title="Log in to browse datalake"
        onClose={() => {
          setLocation("/");
        }}
        open
      />
    );
  }

  return (
    <Stack sx={{ height: "100%" }}>
      <Helmet>
        <title>DLH v2 · Promaton Viewer</title>
      </Helmet>
      <ListItem
        sx={{
          margin: "0 auto",
          marginBottom: 1,
        }}
        disablePadding
      >
        <Typography variant="h5">Data Lake House v2.0</Typography>
      </ListItem>
      <Stack
        sx={{
          paddingTop: 1,
          paddingBottom: 2,
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
        direction="row"
        gap={2}
      >
        <TextField
          placeholder="Search by slug"
          fullWidth
          value={searchInput}
          type="search"
          InputProps={{
            sx: {
              backgroundColor: (t) => t.palette.action.hover,
            },
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <FormControl sx={{ width: 250 }}>
          <InputLabel>Source</InputLabel>
          <Select
            value={sourceInput}
            sx={{
              backgroundColor: (t) => t.palette.action.hover,
            }}
            onChange={(e) => {
              setSourceInput(e.target.value);
            }}
            label={"Source"}
          >
            <MenuItem value={""}>Any</MenuItem>
            {Object.entries(sources).map(([option, label]) => (
              <MenuItem key={option} value={option}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: 250 }}>
          <InputLabel>Scan type</InputLabel>
          <Select
            value={scanTypeInput}
            sx={{
              backgroundColor: (t) => t.palette.action.hover,
            }}
            onChange={(e) => {
              setScanTypeInput(e.target.value);
            }}
            label={"Scan Type"}
          >
            <MenuItem value={""}>Any</MenuItem>
            {Object.entries(scanTypeOptions).map(([option, label]) => (
              <MenuItem key={option} value={option}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: 250 }}>
          <InputLabel>Annotation state</InputLabel>
          <Select
            value={annotationStateInput || AnnotationStateFilter.ALL}
            sx={{
              backgroundColor: (t) => t.palette.action.hover,
            }}
            onChange={(e) => {
              setAnnotationStateInput(e.target.value);
            }}
            label={"Annotation state"}
          >
            {Object.entries(AnnotationStateFilter).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      {caseData.isLoading && <LinearProgress />}
      {caseData.error && (
        <EmptyState>
          <CrossCircle fontSize="large" color="error" />
          <Typography variant="h6">Failed to load DLH v2 data</Typography>
          <Typography variant="body2">Are you connected to the VPN?</Typography>
        </EmptyState>
      )}
      {!caseData.error && !cases?.length && !caseData.isLoading && (
        <EmptyState>
          <ObjectCube fontSize="large" />
          <Typography>No results.</Typography>
        </EmptyState>
      )}
      {cases?.length && (
        <Virtuoso
          endReached={() => {
            caseData.fetchNextPage();
          }}
          style={{ height: "100%", width: "100%", scrollbarWidth: "thin" }}
          data={cases}
          itemContent={(_, caseData) => {
            return <Dlh2BrowserItem caseData={caseData} key={caseData.id} />;
          }}
        />
      )}
    </Stack>
  );
});

const Dlh2BrowserItem: FC<{ caseData?: CaseData }> = ({ caseData }) => {
  const [_, setLocation] = useLocation();

  const annotationCount = useMemo(() => {
    return caseData?.scans.reduce(
      (acc, scan) => acc + scan._count?.annotations || 0,
      0
    );
  }, [caseData]);

  if (!caseData) {
    return <div style={{ height: 1 }} />;
  }
  const link = createRouteLink(routes.dlh2, { slug: caseData.slug });
  const name = caseData.slug;

  return (
    <BrowserItem
      key={caseData.id}
      link={link}
      onClick={(e) => {
        if (!link || e.metaKey || e.ctrlKey) return;
        e.preventDefault();
        setLocation(link);
      }}
      icon={<ObjectCube />}
      title={name}
      secondary={
        <Stack flexDirection={"row"} gap={1}>
          <Chip
            sx={{ width: 150 }}
            variant={"outlined"}
            icon={<Brush fontSize="small" />}
            label={`${annotationCount ?? 0} annotations`}
          />
          <Chip
            sx={{ width: 100 }}
            icon={<FolderAlt fontSize="small" />}
            variant={"outlined"}
            label={`${caseData.scans.length} scans`}
          />
        </Stack>
      }
      subtitle={`${caseData.scans.at(0)?.type} · ${
        sources[caseData.data_source.provider]
      } · Created on ${formatDate(caseData.created_at)}`}
    />
  );
};
