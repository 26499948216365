import { useShortcut } from "@promaton/scan-viewer";
import { memo } from "react";

import { useAppState } from "../stores/useAppState";

export const BoundsToggle = memo(() => {
  const active = useAppState((s) => s.showBounds);
  const setActive = useAppState((s) => s.setShowBounds);

  useShortcut(
    "b",
    (e) => {
      if ((e.target as HTMLElement)?.tagName === "INPUT") return;
      setActive(!active);
    },
    [active]
  );

  return null;
});
