import { useObjects } from "@promaton/scan-viewer";
import ga4 from "react-ga4";
import { create } from "zustand";
import { persist } from "zustand/middleware";

import { useOnboarding } from "./useOnboarding";

type Session = {
  name: string | null;
  picture: string | null;
  email: string;
} | null;

export type Resolution = "auto" | number;

export enum ViewMode {
  SINGLE = "single",
  SPLIT = "split",
  CUSTOM = "custom",
  SIDE_BY_SIDE = "side-by-side",
}

export enum SidebarTab {
  LAYERS = "Layers",
  INFO = "Info",
  COPILOT = "Copilot",
}

export enum ThemeMode {
  SYSTEM = "System",
  LIGHT = "Light",
  DARK = "Dark",
}

export enum ControlMode {
  ORBIT = "orbit",
  ARCBALL = "arcball",
}

export enum AxisDirection {
  Y_UP_RIGHT_HANDED = "Y_UP_RIGHT_HANDED",
  Z_UP_RIGHT_HANDED = "Z_UP_RIGHT_HANDED",
}

interface AppState {
  isOnline: boolean;
  setOnline: (isOnline: boolean) => void;

  sidebarVisible: boolean;

  setSidebarVisible: (to: boolean) => void;
  sidebarTab: SidebarTab;

  setSidebarTab: (to: SidebarTab) => void;

  session: Session;
  setSession: (session: Session) => void;

  viewMode: ViewMode;
  setViewMode: (viewMode: ViewMode) => void;

  showBounds: boolean;
  setShowBounds: (showBounds: boolean) => void;
  onboardingComplete: boolean;
  setOnboardingComplete: (complete: boolean) => void;
  themeMode: ThemeMode;
  setThemeMode: (themeMode: ThemeMode) => void;

  showWireframe: boolean;
  setShowWireframe: (showWireFrame: boolean) => void;

  showIntersectionHeatmaps: boolean;
  setShowIntersectionHeatmaps: (showIntersectionHeatmaps: boolean) => void;

  controlMode: ControlMode;
  setControlMode: (controlMode: ControlMode) => void;

  axisDirection: AxisDirection;
  setAxisDirection: (axisDirection: AxisDirection) => void;

  resolution: Resolution;
  setResolution: (resolution: Resolution) => void;

  settings: boolean;
  showSettings: (to: boolean) => void;

  maxLandmarkSliceDistance: number;
  setMaxLandmarkSliceDistance: (maxLandmarkSliceDistance: number) => void;
}

export const useAppState = create<AppState>()(
  persist(
    (set) => ({
      sidebarVisible: true,
      setSidebarVisible: (to) =>
        set((state) => ({ ...state, sidebarVisible: to })),

      sidebarTab: SidebarTab.LAYERS,
      setSidebarTab: (to) => set((state) => ({ ...state, sidebarTab: to })),

      session: null,
      setSession: (token) => set((state) => ({ ...state, session: token })),

      viewMode: ViewMode.SINGLE,
      setViewMode: (viewMode) => set((state) => ({ ...state, viewMode })),

      showBounds: false,
      setShowBounds: (showBounds) => set((state) => ({ ...state, showBounds })),

      isOnline: true,
      setOnline: (isOnline) => {
        set({ isOnline });
      },

      onboardingComplete: true,
      setOnboardingComplete: (onboardingComplete) => {
        useOnboarding.setState({ activeStep: 0 });
        onboardingComplete && useObjects.getState().setSelection({});
        set({ onboardingComplete });
      },

      themeMode: ThemeMode.SYSTEM,
      setThemeMode: (themeMode) => {
        set({ themeMode });
      },

      showWireframe: false,
      setShowWireframe: (showWireFrame) => {
        set({ showWireframe: showWireFrame });
      },

      showIntersectionHeatmaps: false,
      setShowIntersectionHeatmaps: (showIntersectionHeatmaps) => {
        set({ showIntersectionHeatmaps });
      },

      settings: false,
      showSettings: (settings) => {
        set({ settings });
        ga4.event({
          category: "settings",
          action: "showSettings",
        });
      },

      controlMode: ControlMode.ORBIT,
      setControlMode: (controlMode) => {
        set({ controlMode });
      },

      axisDirection: AxisDirection.Y_UP_RIGHT_HANDED,
      setAxisDirection: (axisDirection) => {
        set({ axisDirection });
      },

      resolution: "auto",
      setResolution: (resolution) => {
        set({ resolution });
      },

      maxLandmarkSliceDistance: 0.3,
      setMaxLandmarkSliceDistance: (maxLandmarkSliceDistance) => {
        set({ maxLandmarkSliceDistance });
      },
    }),
    {
      name: "appState",
      version: 1,
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !["isOnline", "settings"].includes(key)
          )
        ),
    }
  )
);
