import {
  ViewerObject,
  ViewerObjectMap,
  ViewOrientation,
} from "@promaton/scan-viewer";
import { minimatch } from "minimatch";
import { Matrix4 } from "three";
import { z } from "zod";

import { TypeToZod } from "./types/TypeToZod";

const VIEWER_CONFIG_FILE = /viewerconfig(\.json)?$/i;

/** Partial {@link ViewerObject} converted to zod schema for runtime validation. */
const zodViewerObject: Omit<
  TypeToZod<ViewerObject>,
  "url" | "objectType" | "side" | "customMaterial"
> = {
  color: z.string().refine((value) => /^#[0-9A-Fa-f]{6}$/.test(value)),
  dirty: z.boolean(),
  opacity: z.number().min(0).max(1),
  hidden: z.boolean(),
  group: z.string(),
  subGroups: z.array(z.string()),
  excludeInOrientations: z.array(z.nativeEnum(ViewOrientation)),
  excludeInViews: z.array(z.string()),
  transform: z
    .array(z.number())
    .length(16)
    .transform((v) => new Matrix4().fromArray(v)),
  clipToPlanes: z.boolean(),
  renderVolume: z.boolean(),
  isMetadata: z.boolean(),
  renderOrder: z.number().int(),
  depthOffsetFactor: z.number().int(),
  depthWrite: z.boolean(),
  roughness: z.number().min(0).max(1),
  metalness: z.number().min(0).max(1),
  vertexColors: z.boolean(),
  flatShading: z.boolean(),
  showAxis: z.enum(["x", "y", "z"]),
  crossSectionAlongCurve: z.boolean(),
  detectOrientation: z.boolean(),
  showMeshInPanorama: z.boolean(),
};

const viewerConfigSchema = z.object({
  /** Override default styles for objects matching a given glob pattern. */
  objectStyles: z.record(z.object(zodViewerObject).partial()).optional(),
});

export const applyViewerConfig = async (objectMap: ViewerObjectMap) => {
  const configObjectKey = Object.keys(objectMap).find((key) =>
    VIEWER_CONFIG_FILE.test(key)
  );

  if (!configObjectKey) {
    return;
  }

  const configObjectJson = await (
    await fetch(objectMap[configObjectKey].url as string)
  ).json();

  const config = await viewerConfigSchema.parseAsync(configObjectJson);

  Object.assign(objectMap[configObjectKey], {
    isMetadata: true,
  });

  if (config.objectStyles) {
    Object.entries(config.objectStyles).forEach(([glob, styles]) => {
      Object.entries(objectMap).forEach(([key, object]) => {
        if (
          minimatch(key, glob, {
            nocase: true,
          })
        ) {
          Object.assign(object, styles);
        }
      });
    });
  }
};
