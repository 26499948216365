import {
  Avatar,
  Badge,
  Box,
  IconButton,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { CrossCircle } from "@promaton/icons";
import React, { useEffect, useState } from "react";

import { trpc } from "../hooks/trpc";
import { useAppState } from "../stores/useAppState";
export function Profile({ showName }: { showName?: boolean }) {
  const showSettings = useAppState((s) => s.showSettings);
  const isOnline = useAppState((s) => s.isOnline);
  const session = useAppState((s) => s.session);
  const setSession = useAppState((s) => s.setSession);
  const setOnboardingComplete = useAppState((s) => s.setOnboardingComplete);
  const logout = trpc.user.logout.useMutation();
  const utils = trpc.useContext();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const sessionCheck = trpc.user.session.useQuery(undefined, {
    retry: false,
    trpc: {},
  });

  useEffect(() => {
    if (sessionCheck.isLoading) return;
    if (
      (sessionCheck.error &&
        sessionCheck.error.data?.code === "UNAUTHORIZED") ||
      (sessionCheck.isSuccess && !sessionCheck.data)
    ) {
      handleLogout();
    }
  }, [sessionCheck.error, sessionCheck.isSuccess]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    await logout.mutateAsync();
    setSession(null);
    utils.user.session.invalidate();
    handleCloseUserMenu();
  };

  if (!session) return null;

  const avatar = (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      badgeContent={
        isOnline ? undefined : (
          <CrossCircle
            color="error"
            sx={{
              background: "white",
              borderRadius: 100,
              fontSize: "1.2rem",
            }}
          />
        )
      }
    >
      <Avatar
        src={session.picture || undefined}
        imgProps={{
          draggable: false,
          referrerPolicy: "no-referrer",
        }}
        sx={isOnline ? undefined : { opacity: 0.5 }}
      >
        {(session.name || session.email || "").slice(0, 2)}
      </Avatar>
    </Badge>
  );

  return (
    <Box
      component="div"
      sx={{
        flexGrow: 0,
        width: showName ? "100%" : "initial",
      }}
    >
      {showName ? (
        <ListItemButton
          onClick={handleOpenUserMenu}
          sx={{ gap: 2, borderRadius: 1 }}
        >
          {avatar}{" "}
          <ListItemText
            primary={session.name}
            secondary={session.email}
            primaryTypographyProps={{
              sx: {
                width: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              },
            }}
            secondaryTypographyProps={{
              sx: {
                width: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              },
            }}
          />
        </ListItemButton>
      ) : (
        <IconButton
          disabled={!isOnline}
          onClick={handleOpenUserMenu}
          sx={{
            p: 0,
          }}
        >
          {avatar}
        </IconButton>
      )}
      <Menu
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {!showName && (
          <MenuItem
            onClick={() => {
              setOnboardingComplete(false);
              handleCloseUserMenu();
            }}
          >
            <Typography textAlign="center">Tutorial</Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            showSettings(true);
            handleCloseUserMenu();
          }}
        >
          <Typography textAlign="center">Settings</Typography>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}
