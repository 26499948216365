import { useObjects } from "@promaton/scan-viewer";
import { produce } from "immer";
import { create } from "zustand";

import { altKey, isMacOS, metaKey } from "../helpers/userAgent";
import { SidebarTab, useAppState } from "./useAppState";

export interface OnboardingStep {
  position?: "right" | "left" | "top" | "bottom";
  selector: string;
  title: string;
  description: string;
  init?: () => void;
}

interface OnboardingStore {
  steps: OnboardingStep[];
  activeStep: number;
  incrementActiveStep: (increment?: number) => void;
}

export enum OnboardingStepSelector {
  VIEWS = "view-selector",
  KEYBOARD_SHORTCUTS = "keyboard-shortcuts-selector",
  SIDE_BAR = "sidebar-selector",
  VIEW_3D = "view-3d-container",
  SELECTION_WIDGET = "selection-tool-window",
}

export const useOnboarding = create<OnboardingStore>((set) => ({
  activeStep: 0,
  incrementActiveStep: (increment = 1) => {
    set(
      produce<OnboardingStore>((s) => {
        s.activeStep = s.activeStep + increment;
      })
    );
  },
  steps: [
    {
      selector: `#${OnboardingStepSelector.VIEWS}`,
      title: "View selector",
      description:
        "Switch between different viewing modes, like a split view (for orthogonal views) or a custom intersection view.",
    },
    {
      selector: `#${OnboardingStepSelector.SIDE_BAR} li[data-testid="layer-group-header"]`,
      title: "Layer List",
      position: "right",
      description:
        "The objects in your project are shown in groups here. Click a group to open it. You can hide or change the appearance of groups and indivual objects.",
      init: () => {
        useAppState.getState().setSidebarVisible(true);
        useAppState.getState().setSidebarTab(SidebarTab.LAYERS);
      },
    },
    {
      selector: `#${OnboardingStepSelector.SIDE_BAR} li[data-testid="layer-group-header"] .MuiBox-root`,
      title: "Layer Editor",
      position: "right",
      description:
        "You can edit (groups of) objects with the layer editor. You can access the editor by right clicking objects in the 3D view, or by clicking the color patches here.",
      init: () => {
        useAppState.getState().setSidebarVisible(true);
        useAppState.getState().setSidebarTab(SidebarTab.LAYERS);
      },
    },
    {
      selector: `.${OnboardingStepSelector.VIEW_3D} .MuiPaper-root`,
      title: "3D View",
      description: `This is your main 3D view. Focus on objects by double clicking them, or center on a point by clicking with your scroll wheel or by pressing ${altKey} while clicking.`,
      init: () => {
        useAppState.getState().setSidebarVisible(true);
      },
    },
    {
      selector: `#${OnboardingStepSelector.SELECTION_WIDGET}`,
      title: "Selection",
      description: `When you select one or more objects (select multiple using ${
        isMacOS ? metaKey : "Ctrl"
      } + click), you can see your selection here. With the buttons you can center the camera on them, edit their properties or clear your selection.`,
      init: () => {
        const objects = useObjects.getState();
        const select = Object.entries(objects.objects);
        const selection: { [key: string]: {} } = {};
        select
          .slice(0, 4)
          .filter((i) => !!i[1])
          .forEach(([id]) => {
            selection[id] = {};
          });
        objects.setSelection(selection);
      },
    },
    {
      selector: `#${OnboardingStepSelector.KEYBOARD_SHORTCUTS}`,
      title: "Shortcuts and controls",
      description:
        "Learn the mouse and keyboard shortcuts to get the most out of the viewer.",
      init() {
        useObjects.getState().setSelection({});
      },
    },
  ],
}));
