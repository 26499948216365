import "@promaton/frontend-common/src/sentry";

import { ThemeProvider, useMediaQuery } from "@mui/material";
import { darkTheme, lightTheme } from "@promaton/theme";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import ReactDOM from "react-dom/client";
import { registerSW } from "virtual:pwa-register";

import App from "./App";
import { ClientProvider } from "./components/ClientProvider";
import { ThemeMode, useAppState } from "./stores/useAppState";

registerSW();

ReactDOM.createRoot(document.getElementById("root")!).render(<AppContainer />);

function AppContainer() {
  const systemDark = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = useAppState((s) => {
    const isDark =
      s.themeMode === ThemeMode.DARK ||
      (s.themeMode === ThemeMode.SYSTEM && systemDark);

    return isDark ? darkTheme : lightTheme;
  });

  return (
    <React.StrictMode>
      <GoogleOAuthProvider clientId="1043422067614-9uraatv8p5arh57rtb1jhbkqicaflita.apps.googleusercontent.com">
        <ThemeProvider theme={theme}>
          <ClientProvider>
            <App />
          </ClientProvider>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </React.StrictMode>
  );
}
