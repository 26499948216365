import { processS3Files } from "@promaton/file-processing";
import { FileType, useObjects } from "@promaton/scan-viewer";
import { FC, memo } from "react";
import ga4 from "react-ga4";
import { useAsync } from "react-use";
import { useLocation } from "wouter";

import { trpc } from "../hooks/trpc";
import { useAppState } from "../stores/useAppState";
import { useRecents } from "../stores/useRecents";
import { LoginDialog } from "./LoginDialog";

export const Dlh2Loader: FC<{
  slug: string;
}> = memo(({ slug }) => {
  const [location, setLocation] = useLocation();
  const session = useAppState((s) => !!s.session);
  const setObjects = useObjects((s) => s.setObjects);
  const addRecent = useRecents((s) => s.addRecent);
  const caseData = trpc.storage.dlh2.load.useQuery(
    {
      slug,
    },
    {
      enabled: !!session,
    }
  );

  useAsync(async () => {
    if (!caseData.data) return;
    ga4.event({
      category: "loading",
      action: "dlh2 content loaded",
    });
    const files = await processS3Files(caseData.data.files);

    const metadataJSON = URL.createObjectURL(
      new Blob([JSON.stringify(caseData.data)])
    );

    setObjects({
      ...files.objects,
      lakehouseMetadata: {
        objectType: FileType.JSON,
        isMetadata: true,
        url: metadataJSON,
      },
    });

    addRecent({
      title: slug,
      date: new Date(),
      path: location,
    });
  }, [caseData.data]);

  if (!session) {
    return (
      <LoginDialog
        title="Log in to load content from Datalake"
        onClose={() => {
          setLocation("/");
        }}
        open
      />
    );
  }
  return null;
});
