import { oss } from "@promaton/api-client";
import { OssTask } from "@promaton/api-client";
import { applyOSSConvention } from "@promaton/file-processing/src/conventionInternalOSS";
import { applyUniversalConvention } from "@promaton/file-processing/src/conventionUniversal";
import { createNotification } from "@promaton/frontend-common";
import {
  CameraUtils,
  FileType,
  useObjects,
  ViewerObject,
  ViewerObjectMap,
} from "@promaton/scan-viewer";

import { getAiAssistantHeaders } from "../../hooks/useAiAssistantHeaders";
import { useAiAssistantState } from "../../stores/useAiAssistantState";
import { parseAiTaskMetadata } from "./aiTaskMetadata";

export const loadOssTaskResults = async (
  task: OssTask,
  load = true,
  displayResultProgress = true,
  loadPreviousObjects = true
) => {
  if (!task.result) return;
  const structures = Object.keys(task.result.structures);
  const options = getAiAssistantHeaders();

  let resultLoadingProgress = 0.0001;
  displayResultProgress &&
    useAiAssistantState.setState({ resultLoadingProgress });

  const stls = await Promise.all(
    structures.map((structure) =>
      oss
        .getOssIndividualStructure(task.id, structure, options)
        .then(
          (t) =>
            [
              `tooth.${structure}`,
              {
                url: URL.createObjectURL(t.data),
                objectType: FileType.STL,
                clipToPlanes: true,
              },
            ] as [string, ViewerObject]
        )
        .finally(() => {
          resultLoadingProgress += 1 / structures.length;
          displayResultProgress &&
            useAiAssistantState.setState({ resultLoadingProgress });
        })
    )
  );

  try {
    const polyline = await oss.getOssPolyLine(task.id, options).then((t) => {
      return [
        "Polyline",
        {
          url: URL.createObjectURL(new Blob([JSON.stringify(t.data)])),
          objectType: FileType.JSON,
          clipToPlanes: true,
        },
      ] as [string, ViewerObject];
    });

    stls.push(polyline);
  } catch (error) {
    createNotification({
      color: "warning",
      text: "Failed to load polyline",
    });
  }

  try {
    const gingiva = await oss.getOssGingivaStructure(task.id, options).then(
      (t) =>
        [
          "Gingiva",
          {
            url: URL.createObjectURL(t.data),
            objectType: FileType.STL,
            clipToPlanes: true,
          },
        ] as [string, ViewerObject]
    );

    stls.push(gingiva);
  } catch (error) {
    createNotification({
      color: "warning",
      text: "Failed to load gingiva",
    });
  }

  stls.push([
    "result",
    {
      url: URL.createObjectURL(new Blob([JSON.stringify(task.result)])),
      objectType: FileType.JSON,
      clipToPlanes: true,
    },
  ] as [string, ViewerObject]);

  const objects = Object.fromEntries(stls) as ViewerObjectMap;
  const metadata = parseAiTaskMetadata(task.meta_data);
  const previousObjects = useObjects.getState().objects;
  const input = Object.keys(previousObjects).find((k) =>
    k.includes(metadata.name.split("/").at(-1)!)
  );
  if (input) {
    Object.entries(previousObjects).forEach(([id, value]) => {
      const shouldLoadPreviousObject = loadPreviousObjects
        ? true
        : Boolean(value && id === input);
      if (shouldLoadPreviousObject && !objects[id] && value) {
        objects[id] = { ...value };
      }
    });

    objects[input]!.hidden = true;
  }

  applyUniversalConvention(objects);
  await applyOSSConvention(objects);
  if (load) {
    useObjects.getState().setObjects(objects, false);
    setTimeout(() => {
      CameraUtils.recenterAllViews();
    }, 200);
  }

  return objects;
};
