import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FilledInput,
  FormControl,
  InputLabel,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { Convention, processS3Files } from "@promaton/file-processing";
import { createNotification, useDialogs } from "@promaton/frontend-common";
import {
  CoordinateSystem,
  FileType,
  useObjects,
  useStaging,
  ViewerObject,
  ViewerObjectMap,
  ViewOrientation,
} from "@promaton/scan-viewer";
import { RenderConstants } from "@promaton/scan-viewer";
import { FC, FormEvent, memo, useCallback, useEffect, useState } from "react";
import ga4 from "react-ga4";
import { useAsync } from "react-use";
import { useLocation } from "wouter";

import { createRouteLink, routes } from "../helpers/routes";
import { ViewName } from "../helpers/viewNames";
import { trpc } from "../hooks/trpc";
import { useIsPresentationMode } from "../hooks/useIsPresentationMode";
import { useAppState, ViewMode } from "../stores/useAppState";
import { useRecents } from "../stores/useRecents";
import { LoadingMetric } from "./LoadingMetric";
import { LoginDialog } from "./LoginDialog";

enum InputFormat {
  URL = "Load S3 URL",
  SLUG = "Load by scan slug",
}

const DS_BUCKET = "s3://promaton-ds";
const CBCT_GROUNDTRUTH_LOCATION = "training/cbct/2022-11-29-d46e";
export const COMPARISON_QUERY_PARAM = "compare";

export const S3LoadingDialog: FC<{ open: boolean; onClose: () => void }> = memo(
  ({ open, onClose: handleClose }) => {
    const [url, setURL] = useState("");
    const [_, setLocation] = useLocation();
    const [inputFormat, setInputFormat] = useState(InputFormat.URL);
    const loadFromS3 = trpc.storage.loadFolder.useMutation();
    const setObjects = useObjects((s) => s.setObjects);
    const setConfirmation = useDialogs((s) => s.setConfirmation);

    const onSubmit = useCallback(
      async (e: FormEvent) => {
        e.preventDefault();
        try {
          const _url =
            inputFormat === InputFormat.SLUG
              ? `${DS_BUCKET}/${CBCT_GROUNDTRUTH_LOCATION}/${url}`
              : url;
          const path = _url.indexOf("s3://") === 0 ? _url.substring(5) : _url;
          const [bucket, ...folder] = path.split("/");

          let res = await loadFromS3.mutateAsync({
            bucket,
            folder: folder.join("/"),
          });

          if (res.length >= 1000) {
            try {
              await new Promise<void>((resolve, reject) =>
                setConfirmation({
                  title: "Large folder",
                  description:
                    "The folder you are trying to load seems quite big, are you sure you want to load it (max 10000 files)? This may crash the viewer.",
                  onConfirm: () => {
                    resolve();
                  },
                  onCancel: () => {
                    reject();
                  },
                })
              );

              res = await loadFromS3.mutateAsync({
                bucket,
                folder: folder.join("/"),
                loadBigFolders: true,
              });
            } catch {
              return;
            }
          }

          const objects = await processS3Files(res);
          if (Object.keys(objects).length === 0) {
            createNotification({ color: "error", text: "No objects found" });
            return;
          }

          setLocation(
            createRouteLink(routes.s3, {
              bucket,
              folder: folder.join("/"),
            })
          );

          ga4.event({
            category: "loading",
            action: "s3 content loaded manually",
          });
          handleClose();
        } catch (error) {
          createNotification({ color: "error", text: "Failed to load" });
        }
      },
      [url, loadFromS3, setObjects, inputFormat]
    );

    const session = useAppState((s) => s.session);
    if (!session) {
      return (
        <LoginDialog
          title="Log in to load content from S3"
          onClose={handleClose}
          open={open}
        />
      );
    }

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="s3-loading-dialog-title"
        aria-describedby="s3-loading-dialog-description"
      >
        <form onSubmit={onSubmit}>
          <Stack sx={{ width: 600 }}>
            <DialogTitle id="s3-loading-dialog-title">Load from S3</DialogTitle>
            <DialogContent>
              <Stack gap={2}>
                <ToggleButtonGroup
                  value={inputFormat}
                  exclusive
                  sx={{ width: "100%" }}
                  onChange={(_, value) => setInputFormat(value)}
                >
                  <ToggleButton value={InputFormat.URL} sx={{ flex: 1 }}>
                    {InputFormat.URL}
                  </ToggleButton>
                  <ToggleButton value={InputFormat.SLUG} sx={{ flex: 1 }}>
                    {InputFormat.SLUG}
                  </ToggleButton>
                </ToggleButtonGroup>
                <DialogContentText id="s3-loading-dialog-description">
                  {inputFormat === InputFormat.SLUG
                    ? "Enter a CBCT slug for a specific scan here to load its contents."
                    : "Paste a S3 link to a directory below to load its contents."}
                </DialogContentText>
                <FormControl fullWidth variant="filled">
                  <InputLabel>
                    {inputFormat === InputFormat.SLUG ? "Slug" : "URL"}
                  </InputLabel>
                  <FilledInput
                    disabled={loadFromS3.isLoading}
                    autoFocus
                    onChange={(e) => {
                      setURL(e.target.value);
                    }}
                    placeholder={
                      inputFormat === InputFormat.SLUG
                        ? "jacquelyn-safe-guppy"
                        : "s3://bucket/path"
                    }
                    value={url}
                  />
                </FormControl>
                {inputFormat === InputFormat.URL && (
                  <Typography variant="caption">
                    Currently only the <code>promaton-ds</code> and{" "}
                    <code>api-v2-results-prod</code> bucket are supported.
                  </Typography>
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button type="submit" disabled={!url || loadFromS3.isLoading}>
                Load
              </Button>
            </DialogActions>
          </Stack>
        </form>
      </Dialog>
    );
  }
);

const slugRegex = /[a-z]+-[a-z]+-[a-z]+/;
type OverrideData = Record<string, Omit<ViewerObject, "url">>;

export const S3LoadingURLParam: FC<{ bucket: string; folder: string }> = memo(
  ({ bucket, folder }) => {
    const [location, setLocation] = useLocation();
    const session = useAppState((s) => !!s.session);
    const loadFromS3 = trpc.storage.loadFolder.useMutation();
    const setObjects = useObjects((s) => s.setObjects);
    const setOverrides = useObjects((s) => s.setObjectOverrides);
    const [overrideData, setOverrideData] = useState<OverrideData | null>(null);
    const setCoordinateSystem = useStaging((s) => s.setCoordinateSystem);
    const setConfirmation = useDialogs((s) => s.setConfirmation);
    const addRecent = useRecents((s) => s.addRecent);
    const theme = useTheme();
    const params = new URLSearchParams(window.location.search);
    const comparisonFolder = params.get(COMPARISON_QUERY_PARAM);
    const setGroupState = useObjects((s) => s.setGroupState);
    const isPresentationMode = useIsPresentationMode();

    /**
     * Fetches scan for slugs if it is not in folder
     */
    const fetchRelatedData = useCallback(
      async (objects: ViewerObjectMap) => {
        const slug = folder.match(slugRegex);
        if (slug) {
          const isMissingScan = !Object.values(objects).find(
            (obj) =>
              obj.objectType === FileType.DICOM ||
              obj.objectType === FileType.XRAY
          );

          if (!isMissingScan) return;

          try {
            const scanData = await loadFromS3.mutateAsync({
              bucket: "promaton-ds",
              // TODO: make it possible to switch dataset.
              folder: `${CBCT_GROUNDTRUTH_LOCATION}/${slug}`,
            });

            const { objects: scanObjects } = await processS3Files(scanData);

            Object.values(scanObjects).forEach((obj) => {
              if (obj.objectType === FileType.PSG) {
                obj.hidden = true;
                obj.group = "Annotations";
              } else if (
                obj.objectType === FileType.DICOM ||
                obj.objectType === FileType.XRAY
              ) {
                obj.excludeInOrientations = [ViewOrientation["3D"]];
              }
            });

            Object.assign(objects, scanObjects);
          } catch {
            console.warn("couldn't find matching scan data.");
          }
        }
      },
      [bucket, folder]
    );

    const fetchObjects = useCallback(
      async (bucket: string, folder: string) => {
        let res = await loadFromS3.mutateAsync({ bucket, folder });
        if (res.length >= 1000) {
          try {
            await new Promise<void>((resolve, reject) =>
              setConfirmation({
                title: "Large folder",
                description:
                  "The folder you are trying to load seems quite big, are you sure you want to load it (max 10000 files)? This may crash the viewer.",
                onConfirm: () => {
                  resolve();
                },
                onCancel: () => {
                  reject();
                },
              })
            );

            res = await loadFromS3.mutateAsync({
              bucket,
              folder,
              loadBigFolders: true,
            });
          } catch {
            return;
          }
        }
        return res;
      },
      [setConfirmation, loadFromS3]
    );

    useAsync(async () => {
      if (!session) return;

      const res = await fetchObjects(bucket, folder);
      if (!res) return;

      const { objects, convention } = await processS3Files(res);
      const overrides: OverrideData = {};

      await fetchRelatedData(objects);

      if (comparisonFolder) {
        Object.values(objects).forEach((obj) => {
          if (obj.objectType === FileType.DICOM) return;
          obj.excludeInViews = [ViewName.RIGHT];
        });

        const activeFolder = folder.split("/").at(-1);
        const comparisonRes = await fetchObjects(
          bucket,
          `${comparisonFolder}/${activeFolder}`
        );
        const comparisonGroups = new Set<string>();
        if (comparisonRes) {
          const { objects: comparisonObjects } = await processS3Files(
            comparisonRes
          );

          Object.entries(comparisonObjects).forEach(([key, obj]) => {
            if (!key.toUpperCase().includes(comparisonFolder.toUpperCase()))
              return;

            if (obj.objectType === FileType.DICOM) {
              obj.hidden = true;
            }
            obj.group = `Comparison/${obj.group}`;
            comparisonGroups.add(obj.group);
            overrides[key] = {
              color: theme.palette.error.main,
              renderOrder: RenderConstants.renderOrder.FIRST,
              opacity: 0.5,
            };
            obj.excludeInViews = [ViewName.LEFT];
          });
          Object.assign(objects, comparisonObjects);
        }

        Array.from(comparisonGroups.values()).forEach((group) => {
          setGroupState({
            [group]: {
              order: 10000,
              expanded: false,
            },
          });
        });
      }

      if (Object.keys(objects).length === 0) {
        createNotification({ color: "error", text: "No objects found" });
        return;
      }

      ga4.event({
        category: "loading",
        action: "s3 content loaded via URL",
      });

      if (!isPresentationMode) {
        addRecent({
          title: folder.split("/").at(-1) ?? folder,
          date: new Date(),
          path: location,
        });
      }

      if (isPresentationMode) {
        Object.values(objects).forEach((obj) => {
          if (obj.objectType === FileType.DICOM) {
            obj.hidden = true;
          }
        });
      }

      setObjects(objects);
      setCoordinateSystem(
        convention === Convention.SIAB
          ? CoordinateSystem.IJK
          : CoordinateSystem.LPS
      );
      setOverrideData(overrides);
    }, [bucket, folder, comparisonFolder, fetchRelatedData, session]);

    const viewMode = useAppState((s) => s.viewMode);

    useEffect(() => {
      if (!overrideData) return;
      if (viewMode === ViewMode.SIDE_BY_SIDE) {
        setOverrides(() => ({}));
      } else {
        setTimeout(() => {
          setOverrides(() => overrideData);
        }, 100);
      }
    }, [viewMode, overrideData, setOverrides]);

    if (!session) {
      return (
        <LoginDialog
          title="Log in to load content from S3"
          onClose={() => {
            setLocation("/");
          }}
          open
        />
      );
    }

    if (loadFromS3.isLoading) {
      return (
        <Dialog open>
          <DialogContent
            sx={{ display: "flex", alignItems: "center", gap: 2, padding: 4 }}
          >
            <CircularProgress />
            <DialogContentText>Loading content from S3...</DialogContentText>
          </DialogContent>
        </Dialog>
      );
    }

    return <LoadingMetric />;
  }
);
