import { useMemo } from "react";

const FORCE_PUBLIC =
  import.meta.env.VITE_FORCE_PUBLIC_VIEWER === "true" && import.meta.env.DEV;

export const useIsPublicViewer = () => {
  return useMemo(
    () => location.hostname.includes("promaton.com") || FORCE_PUBLIC,
    []
  );
};
