import { acd, alignment, sipp, SippTask } from "@promaton/api-client";
import { applyUniversalConvention } from "@promaton/file-processing/src/conventionUniversal";
import { FileType, useObjects, ViewerObjectMap } from "@promaton/scan-viewer";

import { getAiAssistantHeaders } from "../../hooks/useAiAssistantHeaders";
import {
  AiTaskType,
  useAiAssistantState,
} from "../../stores/useAiAssistantState";
import { parseAiTaskMetadata } from "./aiTaskMetadata";
import { loadAcdTaskResults } from "./loadAcdTaskResults";
import { loadAlignmentTaskResults } from "./loadAlignmentTaskResults";

export const loadSippTaskResults = async (
  task: SippTask,
  load = true,
  displayResultProgress = true
) => {
  if (!task.result) return;
  const metadata = parseAiTaskMetadata(task.meta_data);
  const objects: ViewerObjectMap = {};
  const options = getAiAssistantHeaders();
  let resultLoadingProgress = 0.0001;

  const implants = Object.keys(task.result.implantMeshes);
  const sleeves = Object.keys(task.result.sleeveMeshes);
  const structureCount = implants.length + sleeves.length;

  displayResultProgress &&
    useAiAssistantState.setState({ resultLoadingProgress });

  await Promise.all(
    implants.map((implant) =>
      sipp
        .getSippImplantStlStructure(task.id, implant, options)
        .then(
          (t) =>
            (objects[`implant.${implant}`] = {
              url: URL.createObjectURL(t.data),
              objectType: FileType.STL,
              clipToPlanes: true,
            })
        )
        .finally(() => {
          resultLoadingProgress += 1 / structureCount;
          displayResultProgress &&
            useAiAssistantState.setState({ resultLoadingProgress });
        })
    )
  );

  await Promise.all(
    sleeves.map((sleeve) =>
      sipp
        .getSippSleeveStlStructure(task.id, sleeve, options)
        .then(
          (t) =>
            (objects[`sleeve.${sleeve}`] = {
              url: URL.createObjectURL(t.data),
              objectType: FileType.STL,
              clipToPlanes: true,
            })
        )
        .finally(() => {
          resultLoadingProgress += 1 / structureCount;
          displayResultProgress &&
            useAiAssistantState.setState({ resultLoadingProgress });
        })
    )
  );

  applyUniversalConvention(objects);

  const alignmentTaskId = metadata.related?.find(
    (t) => t.type === AiTaskType.ALIGNMENT
  )?.id;

  if (alignmentTaskId) {
    const alignmentTask = await alignment.getAlignmentTask(
      alignmentTaskId,
      options
    );
    const alignmentObjects = await loadAlignmentTaskResults(
      alignmentTask.data,
      false,
      displayResultProgress
    );
    Object.assign(objects, alignmentObjects);
  }

  const acdTaskId = metadata.related?.find((t) => t.type === AiTaskType.ACD)
    ?.id;

  if (acdTaskId) {
    const acdTask = await acd.getAcd(acdTaskId, options);
    const acdObjects = await loadAcdTaskResults(
      acdTask.data,
      false,
      displayResultProgress
    );
    if (acdObjects) {
      Object.values(acdObjects).forEach((obj) => {
        obj.transform = undefined;
      });
      Object.keys(acdObjects).forEach((key) => {
        if (!key.includes("crown")) {
          delete acdObjects[key];
        }
      });
      Object.assign(objects, acdObjects);
    }
  }

  if (load) {
    useObjects.getState().setObjects(objects, false);
  }

  return objects;
};
