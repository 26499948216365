import { IconButton, Tooltip } from "@mui/material";
import {
  createNotification,
  useCommandPalette,
} from "@promaton/frontend-common";
import { Camera, Download } from "@promaton/icons";
import { isImageFile, takeScreenshot, useObjects } from "@promaton/scan-viewer";
import { memo, useCallback, useEffect, useState } from "react";
import ga4 from "react-ga4";
import { generateUUID } from "three/src/math/MathUtils";

import { getMeshAsStl } from "../helpers/getMeshAsStl";
import { useIsPublicViewer } from "../hooks/useIsPublicViewer";

export const DownloadButtons = memo(({ name }: { name: string }) => {
  const [loading, setLoading] = useState(false);
  const isPublic = useIsPublicViewer();
  const updateCommand = useCommandPalette((s) => s.updateCommand);
  const removeCommand = useCommandPalette((s) => s.removeCommand);

  const downloadAsZip = useCallback(
    async (
      /** Opt to download edited versions of meshes rather than originals. */
      downloadEdited = false
    ) => {
      setLoading(true);

      const destroyNotification = createNotification({
        color: "info",
        text: "Creating zip...",
      });
      const jszip = await import("jszip");

      const zip = new jszip.default();

      await Promise.allSettled(
        Object.entries(useObjects.getState().objects).map(
          async ([key, obj]) => {
            if (!obj) return;

            const fileName = key.split("/").at(-1)!;
            let name = `${obj.group ? `${obj.group}/` : ""}${fileName}`;

            const ensureUnique = (path: string) => {
              if (zip.filter((p) => p === path).length > 0) {
                return `${generateUUID().split("-")[0]}-${path}`;
              }
              return path;
            };

            if (downloadEdited && !obj.hidden && !isImageFile(obj.objectType)) {
              const blob = await getMeshAsStl(key);
              const path = ensureUnique(`${name}.stl`);
              zip.file<"blob">(path, blob);
              return;
            }

            if (Array.isArray(obj.url)) {
              const baseKey = name.replace(/[0-9]+$/, "");
              zip.file,
                await Promise.all(
                  obj.url.map(async (url, i) => {
                    const blob = await (await fetch(url)).blob();
                    const path = `${baseKey}${i.toString().padStart(4, "0")}.${
                      obj.objectType
                    }`;

                    zip.file<"blob">(path, blob);
                  })
                );
              return;
            }

            const blob = (await fetch(obj.url)).blob();

            if (
              obj.objectType &&
              !name.toLowerCase().endsWith(`.${obj.objectType}`)
            ) {
              name = `${name}.${obj.objectType}`;
            }

            const path = ensureUnique(name);
            zip.file<"blob">(path, blob);
          }
        )
      );

      ga4.event({
        category: "export",
        action: "Downloaded zip",
      });

      const blob = await zip.generateAsync({ type: "blob" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${name}.zip`;
      link.click();
      link.remove();
      destroyNotification();
      setLoading(false);
    },
    []
  );

  const handleTakeScreenshot = useCallback(async () => {
    setLoading(true);
    const canvas = document.querySelector("canvas");
    if (!canvas) return;
    const url = takeScreenshot(canvas, true, name);

    navigator.clipboard.write([
      new ClipboardItem({
        "image/png": await fetch(url).then((r) => r.blob()),
      }),
    ]);
    createNotification({ text: "Copied to clipboard!", color: "success" });
    setLoading(false);
  }, [name]);

  useEffect(() => {
    updateCommand("downloadAsZip", {
      action: downloadAsZip,
      name: "Download as zip",
      keywords: "download export zip archive files",
      description: "Download all files as a zip",
    });
    updateCommand("downloadEdited", {
      action: () => downloadAsZip(true),
      name: "Download edited files",
      keywords: "download export zip archive files",
      description:
        "Download meshes with edits and transforms applied in a zip file",
    });
    !isPublic &&
      updateCommand("takeScreenshot", {
        action: handleTakeScreenshot,
        name: "Take screenshot",
        keywords: "camera photo printscreen png export capture",
        description: "Take a screenshot of the current scene",
      });

    return () => {
      removeCommand("downloadAsZip");
      removeCommand("takeScreenshot");
    };
  }, [handleTakeScreenshot, downloadAsZip]);

  return (
    <>
      {!isPublic && (
        <Tooltip
          title="Take screenshot"
          sx={{
            display: {
              xs: "none",
              sm: "flex",
            },
          }}
        >
          <IconButton disabled={loading} onClick={handleTakeScreenshot}>
            <Camera />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip
        title="Download files"
        sx={{
          display: {
            xs: "none",
            sm: "flex",
          },
        }}
      >
        <IconButton onClick={() => downloadAsZip()} disabled={loading}>
          <Download />
        </IconButton>
      </Tooltip>
    </>
  );
});
