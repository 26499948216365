import { cbct } from "@promaton/api-client";
import { CbctTask } from "@promaton/api-client";
import { applyCBCTConvention } from "@promaton/file-processing/src/conventionInternalCBCT";
import { applyUniversalConvention } from "@promaton/file-processing/src/conventionUniversal";
import {
  CameraUtils,
  FileType,
  useObjects,
  ViewerObject,
  ViewerObjectMap,
} from "@promaton/scan-viewer";

import { getAiAssistantHeaders } from "../../hooks/useAiAssistantHeaders";
import { useAiAssistantState } from "../../stores/useAiAssistantState";
import { parseAiTaskMetadata } from "./aiTaskMetadata";

export const loadCbctResults = async (
  task: CbctTask,
  load = true,
  displayResultProgress = true
) => {
  if (!task.result) return;
  const structures = task.result.structures;
  const options = getAiAssistantHeaders();
  let resultLoadingProgress = 0.0001;
  displayResultProgress &&
    useAiAssistantState.setState({ resultLoadingProgress });
  const stls = await Promise.all(
    structures.map((structure) =>
      cbct
        .getCbctIndividualStructure(
          task.id,
          structure.label,
          structure.region === "unassigned"
            ? structure.path.match(/unassigned.+$/i)?.[0] || "unassigned/1"
            : structure.region,
          options
        )
        .then(
          (t) =>
            [
              `${structure.label.toUpperCase()}_${structure.region.toUpperCase()}`,
              {
                url: URL.createObjectURL(t.data),
                objectType: FileType.STL,
                clipToPlanes: true,
              },
            ] as [string, ViewerObject]
        )
        .finally(() => {
          resultLoadingProgress += 1 / structures.length;
          displayResultProgress &&
            useAiAssistantState.setState({ resultLoadingProgress });
        })
    )
  );

  stls.push([
    "result",
    {
      url: URL.createObjectURL(new Blob([JSON.stringify(task.result)])),
      objectType: FileType.JSON,
      clipToPlanes: true,
    },
  ] as [string, ViewerObject]);

  const objects = Object.fromEntries(stls) as ViewerObjectMap;
  const metadata = parseAiTaskMetadata(task.meta_data);
  const input = useObjects.getState().objects[metadata.name];
  if (input) {
    objects[metadata.name] = { ...input };
  }

  applyUniversalConvention(objects);
  applyCBCTConvention(objects);

  if (load) {
    useObjects.getState().setObjects(objects, false);
    setTimeout(() => {
      CameraUtils.recenterAllViews();
    }, 200);
  }

  return objects;
};
