import {
  Avatar,
  Card,
  CardActionArea,
  CardActionAreaTypeMap,
  CardContent,
  CardMedia,
  ExtendButtonBase,
  ListItemButtonProps,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { FC, ReactNode } from "react";

const BrowserItemCard = styled(
  CardActionArea as ExtendButtonBase<CardActionAreaTypeMap<{}, "a">>
)`
  transition-duration: 0s;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  .hide {
    opacity: 0;
  }

  &:hover {
    .hide {
      opacity: 1;
    }

    .MuiCardMedia-root {
      transform: scale(1.05);
    }
  }
`;

export const BrowserGridContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;

export const BrowserItemContainer = styled("div")`
  padding: ${({ theme }) => theme.spacing(1)};
  width: 33%;
  display: flex;
  flex: none;
  align-content: stretch;
  box-sizing: border-box;
  height: 350px;

  @media (max-width: 1024px) {
    width: 50%;
  }

  @media (max-width: 300px) {
    width: 100%;
  }
`;

export const BrowserCard: FC<
  {
    link?: string;
    title: string;
    subtitle?: string;
    thumbnail?: string;
    avatar?: string;
    secondary?: ReactNode;
  } & ListItemButtonProps<"a">
> = ({
  link,
  thumbnail,
  onClick,
  title,
  avatar,
  subtitle,
  secondary,
  ...rest
}) => {
  return (
    <Card
      variant="outlined"
      sx={{
        height: 330,
        width: "100%",
        variant: "outlined",
        borderRadius: 3,
      }}
    >
      <BrowserItemCard
        disableRipple
        draggable={false}
        {...rest}
        sx={
          rest.sx || {
            margin: "auto",
          }
        }
        href={link || ""}
        onClick={(e) => {
          if (!link || e.metaKey || e.ctrlKey) return;
          e.preventDefault();
          return onClick && onClick(e);
        }}
      >
        <Stack
          sx={{
            background: (t) => t.palette.action.hover,
            height: 200,
            overflow: "hidden",
          }}
        >
          <CardMedia
            image={thumbnail || ""}
            sx={{ height: "100%", transition: "0.15s all ease" }}
          />
        </Stack>
        <CardContent sx={{ flex: 1 }}>
          <Stack flexDirection="row" alignItems={"center"} gap={2}>
            {avatar && <Avatar src={avatar || undefined} />}
            <Stack flex={1} sx={{ maxWidth: "100%" }}>
              <Typography variant="body1" fontWeight={"bold"}>
                {title}
              </Typography>
              {subtitle && (
                <Typography
                  variant="body2"
                  title={subtitle}
                  sx={{
                    opacity: 0.7,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    width: "100%",
                  }}
                >
                  {subtitle}
                </Typography>
              )}
            </Stack>
          </Stack>
        </CardContent>
        {secondary}
      </BrowserItemCard>
    </Card>
  );
};
