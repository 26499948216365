/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Promaton HTTP API
 * This API allows you to upload STL and 3D CBCT images for segmentation.

## Tasks
Because inferences take longer than some proxy servers allow open
connection for, we accept all uploads as a `task` which you
can monitor until it reaches completion.

Monitoring can be done via polling.

## Authentication

Authentication is done by setting your api key in the `Authorization`
header, prefixed with `Bearer`, like so: `Bearer <yourtoken>`.

Depending on your API token, you can have access to either OSS, CBCT,
ACD or all.

## Uploading your first STL
You can upload your first STL using the following curl command:

```sh
curl -X POST "https://api.promaton.com/oss/lower" \
    -H "Content-Type: model/stl" \
    --data-binary "@path_to_stl.lower.stl" \
    --header "Authorization: Bearer <your token>" \
    --http1.1 --verbose \
```

After that, you can get the status of your task using
the id of the task returned to you, in the following
curl command:

```sh
curl "https://api.promaton.com/oss/{your task id}" \
    --header "Authorization: Bearer <your token>" \
    --http1.1\
```

Until it reaches the completed state.

You can then use either the `stl` output, or `polyline` output endpoints
to get the result. If an error occurs, the state will switch to `errored`.

## Response times

We shut down our servers whenever possible to reduce cost. You therefore
may experience a "cold start" when first calling our API. This takes
at most five minutes. After that, the response times should be on par.

## Task pagination
All tasks offer a pagination endpoint. Because tasks can be added while
you are paginating, we work with a pointer-based approach using a task id.

If you want to start paginating from the most recent task to older tasks,
you can call (for example) `/cbct?sort_order=desc&limit=10`. Then, for the
next 10 items, you take the id last item of the previous result, and send
that along as the `from_id` like this:
`/cbct?sort_order=desc&limit=15&from_id=ckbulxexk0016xapiz8h3g2pn`.

## Known issues
- Currently, there is an issue with HTTP-v2 and curl. Please use
  the `--http1.1` command to circumvent it.

## Changelog
The changelog has moved and is available <a href="/docs/changelog">here</a>

## Patents
The following products are protected by patents in the U.S. and elsewhere, all
or some of which are third party intellectual property rights. This website is
provided to satisfy the virtual patent marking provisions of various jurisdictions
including the virtual patent marking provisions of the America Invents Act. The
following list of products may not be all inclusive, and other products not
listed here may be protected by one or more patents.

`US Patent Number 11,379,975, US Patent Number 11,568,533` - for Segmentation of 3D anatomical structures

`US Patent Number 11,455,774` - for Root Shape Prediction
 * OpenAPI spec version: 9.7.1
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  OssListTasks,
  ErrorResponse,
  UnauthorizedResponse,
  GetOssTasksParams,
  OssTask,
  ForbiddenResponse,
  CreateOssParams,
  NotFoundResponse,
  GoneResponse,
  PolyLine
} from '../model'


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


/**
 * @summary Gets all tasks for the current authenticated user
 */
export const getOssTasks = (
    params?: GetOssTasksParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<OssListTasks>> => {
    return axios.get(
      `/oss`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetOssTasksQueryKey = (params?: GetOssTasksParams,) => [`/oss`, ...(params ? [params]: [])] as const;
  

    
export const getGetOssTasksQueryOptions = <TData = Awaited<ReturnType<typeof getOssTasks>>, TError = AxiosError<ErrorResponse | UnauthorizedResponse>>(params?: GetOssTasksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOssTasks>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getOssTasks>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOssTasksQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOssTasks>>> = ({ signal }) => getOssTasks(params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetOssTasksQueryResult = NonNullable<Awaited<ReturnType<typeof getOssTasks>>>
export type GetOssTasksQueryError = AxiosError<ErrorResponse | UnauthorizedResponse>

/**
 * @summary Gets all tasks for the current authenticated user
 */
export const useGetOssTasks = <TData = Awaited<ReturnType<typeof getOssTasks>>, TError = AxiosError<ErrorResponse | UnauthorizedResponse>>(
 params?: GetOssTasksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOssTasks>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOssTasksQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * Starts inference on any optical scan segmentation in the STL format
 * @summary Run optical scan segmentation
 */
export const createOss = (
    jawType: 'upper' | 'lower',
    createOssBody: Blob,
    params?: CreateOssParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<OssTask>> => {
    return axios.post(
      `/oss/${jawType}`,
      createOssBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getCreateOssMutationOptions = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOss>>, TError,{jawType: 'upper' | 'lower';data: Blob;params?: CreateOssParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createOss>>, TError,{jawType: 'upper' | 'lower';data: Blob;params?: CreateOssParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createOss>>, {jawType: 'upper' | 'lower';data: Blob;params?: CreateOssParams}> = (props) => {
          const {jawType,data,params} = props ?? {};

          return  createOss(jawType,data,params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateOssMutationResult = NonNullable<Awaited<ReturnType<typeof createOss>>>
    export type CreateOssMutationBody = Blob
    export type CreateOssMutationError = AxiosError<UnauthorizedResponse | ForbiddenResponse>

    /**
 * @summary Run optical scan segmentation
 */
export const useCreateOss = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOss>>, TError,{jawType: 'upper' | 'lower';data: Blob;params?: CreateOssParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateOssMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Gets the task object for an OSS inference
 */
export const getOss = (
    taskId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<OssTask>> => {
    return axios.get(
      `/oss/${taskId}`,options
    );
  }


export const getGetOssQueryKey = (taskId: string,) => [`/oss/${taskId}`] as const;
  

    
export const getGetOssQueryOptions = <TData = Awaited<ReturnType<typeof getOss>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOss>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getOss>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOssQueryKey(taskId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOss>>> = ({ signal }) => getOss(taskId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(taskId), ...queryOptions}}

export type GetOssQueryResult = NonNullable<Awaited<ReturnType<typeof getOss>>>
export type GetOssQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets the task object for an OSS inference
 */
export const useGetOss = <TData = Awaited<ReturnType<typeof getOss>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOss>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOssQueryOptions(taskId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Deletes a task
 */
export const deleteOss = (
    taskId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.delete(
      `/oss/${taskId}`,options
    );
  }



export const getDeleteOssMutationOptions = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteOss>>, TError,{taskId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteOss>>, TError,{taskId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteOss>>, {taskId: string}> = (props) => {
          const {taskId} = props ?? {};

          return  deleteOss(taskId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteOssMutationResult = NonNullable<Awaited<ReturnType<typeof deleteOss>>>
    
    export type DeleteOssMutationError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

    /**
 * @summary Deletes a task
 */
export const useDeleteOss = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteOss>>, TError,{taskId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDeleteOssMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Gets the polyline result of an OSS inference
 */
export const getOssPolyLine = (
    taskId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PolyLine>> => {
    return axios.get(
      `/oss/${taskId}/polyline`,options
    );
  }


export const getGetOssPolyLineQueryKey = (taskId: string,) => [`/oss/${taskId}/polyline`] as const;
  

    
export const getGetOssPolyLineQueryOptions = <TData = Awaited<ReturnType<typeof getOssPolyLine>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOssPolyLine>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getOssPolyLine>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOssPolyLineQueryKey(taskId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOssPolyLine>>> = ({ signal }) => getOssPolyLine(taskId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(taskId), ...queryOptions}}

export type GetOssPolyLineQueryResult = NonNullable<Awaited<ReturnType<typeof getOssPolyLine>>>
export type GetOssPolyLineQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets the polyline result of an OSS inference
 */
export const useGetOssPolyLine = <TData = Awaited<ReturnType<typeof getOssPolyLine>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOssPolyLine>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOssPolyLineQueryOptions(taskId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Gets the segmented gingiva structure in the STL format; this is not strictly gingiva but rather the identified structure meshes subtracted from the input.
 */
export const getOssGingivaStructure = (
    taskId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Blob>> => {
    return axios.get(
      `/oss/${taskId}/gingiva`,{
        responseType: 'blob',
    ...options,}
    );
  }


export const getGetOssGingivaStructureQueryKey = (taskId: string,) => [`/oss/${taskId}/gingiva`] as const;
  

    
export const getGetOssGingivaStructureQueryOptions = <TData = Awaited<ReturnType<typeof getOssGingivaStructure>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOssGingivaStructure>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getOssGingivaStructure>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOssGingivaStructureQueryKey(taskId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOssGingivaStructure>>> = ({ signal }) => getOssGingivaStructure(taskId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(taskId), ...queryOptions}}

export type GetOssGingivaStructureQueryResult = NonNullable<Awaited<ReturnType<typeof getOssGingivaStructure>>>
export type GetOssGingivaStructureQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets the segmented gingiva structure in the STL format; this is not strictly gingiva but rather the identified structure meshes subtracted from the input.
 */
export const useGetOssGingivaStructure = <TData = Awaited<ReturnType<typeof getOssGingivaStructure>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOssGingivaStructure>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOssGingivaStructureQueryOptions(taskId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Gets an individual structure in the STL format
 */
export const getOssIndividualStructure = (
    taskId: string,
    structureId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Blob>> => {
    return axios.get(
      `/oss/${taskId}/stl/${structureId}`,{
        responseType: 'blob',
    ...options,}
    );
  }


export const getGetOssIndividualStructureQueryKey = (taskId: string,
    structureId: string,) => [`/oss/${taskId}/stl/${structureId}`] as const;
  

    
export const getGetOssIndividualStructureQueryOptions = <TData = Awaited<ReturnType<typeof getOssIndividualStructure>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string,
    structureId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOssIndividualStructure>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getOssIndividualStructure>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOssIndividualStructureQueryKey(taskId,structureId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOssIndividualStructure>>> = ({ signal }) => getOssIndividualStructure(taskId,structureId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(taskId && structureId), ...queryOptions}}

export type GetOssIndividualStructureQueryResult = NonNullable<Awaited<ReturnType<typeof getOssIndividualStructure>>>
export type GetOssIndividualStructureQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets an individual structure in the STL format
 */
export const useGetOssIndividualStructure = <TData = Awaited<ReturnType<typeof getOssIndividualStructure>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string,
    structureId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOssIndividualStructure>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOssIndividualStructureQueryOptions(taskId,structureId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

