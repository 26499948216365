import {
  Button,
  CardActions,
  LinearProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useDialogs } from "@promaton/frontend-common";
import { CrossCircle, ObjectCube, Trash } from "@promaton/icons";
import { memo, useMemo } from "react";
import { Helmet } from "react-helmet";
import { VirtuosoGrid } from "react-virtuoso";
import { useLocation } from "wouter";

import { createRouteLink, routes } from "../helpers/routes";
import { trpc } from "../hooks/trpc";
import { useDebouncedQueryState } from "../hooks/useDebouncedQueryState";
import { useAppState } from "../stores/useAppState";
import {
  BrowserCard,
  BrowserGridContainer,
  BrowserItemContainer,
} from "./BrowserCard";
import { EmptyState } from "./EmptyState";
import { LoginDialog } from "./LoginDialog";

const TabLabel = {
  MY_PROJECTS: "My projects",
  SHARED_PROJECTS: "Shared projects",
} as const;

type TabLabel = (typeof TabLabel)[keyof typeof TabLabel];

export const SharedProjects = memo(() => {
  const [_, setLocation] = useLocation();
  const utils = trpc.useContext();
  const [__, tab, setTab] = useDebouncedQueryState("tab", TabLabel.MY_PROJECTS);
  const session = useAppState((s) => s.session);
  const sceneQuery = trpc.scene.list.useInfiniteQuery(
    {
      own: tab === TabLabel.MY_PROJECTS,
    },
    {
      enabled: !!session,
      getNextPageParam: (lastPage) => lastPage.at(-1)?.id,
    }
  );

  const setConfirmation = useDialogs((s) => s.setConfirmation);
  const deleteScene = trpc.scene.delete.useMutation();

  const scenes = useMemo(() => {
    if (!sceneQuery.data) return [];
    return sceneQuery.data.pages.flat();
  }, [sceneQuery.data]);

  if (!session) {
    return (
      <LoginDialog
        title="Log in to browse projects"
        onClose={() => {
          setLocation("/");
        }}
        open
      />
    );
  }

  return (
    <Stack sx={{ height: "100%" }}>
      <Helmet>
        <title>Projects · Promaton Viewer</title>
      </Helmet>
      <Tabs
        textColor="inherit"
        value={tab}
        onChange={(_, value: TabLabel) => setTab(value)}
      >
        {Object.values(TabLabel).map((label) => (
          <Tab
            key={label}
            label={label}
            value={label}
            sx={{
              fontSize: (t) => t.typography.h6.fontSize,
              padding: 0,
              mr: 2,
            }}
          />
        ))}
      </Tabs>
      {sceneQuery.isLoading ? (
        <LinearProgress />
      ) : sceneQuery.error ? (
        <EmptyState>
          <CrossCircle fontSize="large" color="error" />
          <Typography variant="h6">Failed to load your projects</Typography>
          <Typography variant="body2">Are you connected to the VPN?</Typography>
        </EmptyState>
      ) : !scenes?.length ? (
        <EmptyState>
          <ObjectCube fontSize="large" />
          <Typography>
            No projects yet. Load some files and hit share.
          </Typography>
        </EmptyState>
      ) : (
        <VirtuosoGrid
          style={{ height: "100%", width: "100%", margin: "0 -0.5rem" }}
          data={scenes}
          endReached={() => {
            sceneQuery.fetchNextPage();
          }}
          components={{
            Item: BrowserItemContainer,
            // @ts-ignore
            List: BrowserGridContainer,
          }}
          itemContent={(index, scene) => {
            const link = createRouteLink(routes.scene, { id: scene.id });
            const name = scene.name;
            const isOwn = scene.owner.email === session.email;
            return (
              <BrowserCard
                key={scene.id}
                link={link}
                onClick={(e) => {
                  if (!link || e.metaKey || e.ctrlKey) return;
                  e.preventDefault();
                  setLocation(link);
                }}
                thumbnail={scene.thumbnail || undefined}
                title={name}
                subtitle={`Created on ${new Date(
                  scene.createdAt
                ).toLocaleString("en-GB", {
                  month: "short",
                  day: "numeric",
                })} by ${scene.owner.name}`}
                avatar={scene.owner.picture || undefined}
                secondary={
                  isOwn ? (
                    <CardActions>
                      <Button
                        className="hide"
                        color="error"
                        disabled={deleteScene.isLoading}
                        startIcon={<Trash />}
                        onClick={async (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          await new Promise<void>((resolve, reject) => {
                            setConfirmation({
                              title: `Are you sure you want to delete ${scene.name}?`,
                              description: "This can not be undone.",
                              onConfirm: () => {
                                resolve();
                              },
                              onCancel: () => {
                                reject();
                              },
                            });
                          });

                          await deleteScene.mutateAsync({ id: scene.id });
                          utils.scene.list.invalidate();
                        }}
                      >
                        Delete
                      </Button>
                    </CardActions>
                  ) : undefined
                }
              />
            );
          }}
        />
      )}
    </Stack>
  );
});
