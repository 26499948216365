import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import { FC, memo, ReactNode } from "react";

import { trpc } from "../hooks/trpc";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60,
    },
  },
});

const backendUrl = import.meta.env.DEV
  ? (import.meta.env.VITE_BACKEND_URL as string)
  : `${location.protocol}//api.${location.host}`;

const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: `${backendUrl}/trpc`,
      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: "include",
        });
      },
    }),
  ],
});

export const ClientProvider: FC<{ children: ReactNode }> = memo(
  ({ children }) => {
    return (
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
      </trpc.Provider>
    );
  }
);
