import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { Convention, processS3Files } from "@promaton/file-processing";
import { createNotification, useDialogs } from "@promaton/frontend-common";
import { CoordinateSystem } from "@promaton/scan-viewer";
import { FC, memo } from "react";
import ga4 from "react-ga4";
import { useAsync } from "react-use";
import { useLocation } from "wouter";

import { createRouteLink, routes } from "../helpers/routes";
import { TError, trpc } from "../hooks/trpc";
import { useAppState } from "../stores/useAppState";
import { LoginDialog } from "./LoginDialog";

export const VIEWER_BUCKET = "promaton-viewer-upload";
export const DUMP_FOLDER = "dump";

let claimStarted = false;

export const ClaimDialog: FC<{ id: string }> = memo(({ id }) => {
  const [_, setLocation] = useLocation();
  const session = useAppState((s) => s.session);
  const loadFromS3 = trpc.storage.loadFolder.useMutation();
  const createScene = trpc.scene.create.useMutation();
  const setConfirmation = useDialogs((s) => s.setConfirmation);

  useAsync(async () => {
    if (!session) return;
    // Added to prevent running twice in dev mode (React 18).
    if (claimStarted) return;
    claimStarted = true;
    try {
      let res = await loadFromS3.mutateAsync({
        bucket: VIEWER_BUCKET,
        folder: `${DUMP_FOLDER}/${id}`,
      });
      if (res.length >= 1000) {
        try {
          await new Promise<void>((resolve, reject) =>
            setConfirmation({
              title: "Large folder",
              description:
                "The folder you are trying to load seems quite big, are you sure you want to load it (max 10000 files)? This may crash the viewer.",
              onConfirm: () => {
                resolve();
              },
              onCancel: () => {
                reject();
              },
            })
          );

          res = await loadFromS3.mutateAsync({
            bucket: VIEWER_BUCKET,
            folder: `${DUMP_FOLDER}/${id}`,
            loadBigFolders: true,
          });
        } catch {
          setLocation("/");
          return;
        }
      }
      const { objects, convention } = await processS3Files(res);
      if (Object.keys(objects).length === 0) {
        throw new Error("");
      }

      const coordinateSystem =
        convention === Convention.SIAB
          ? CoordinateSystem.IJK
          : CoordinateSystem.LPS;

      const [scene] = await createScene.mutateAsync({
        id,
        name: id,
        data: objects,
        coordinateSystem,
      });

      ga4.event({
        category: "sharing",
        action: "cli dump claimed",
      });

      setLocation(createRouteLink(routes.scene, { id: scene.id }));
    } catch (error: unknown) {
      if ((error as TError)?.data?.code === "NOT_FOUND") {
        ga4.event({
          category: "sharing",
          action: "cli dump viewed",
        });
        setLocation(createRouteLink(routes.scene, { id }));
        return;
      }
      createNotification({ color: "error", text: "No scans found" });
      setLocation("/");
    }
  }, [id, session]);

  if (!session) {
    return (
      <LoginDialog
        title="Log in to claim upload"
        onClose={() => {
          setLocation("/");
        }}
        open
      />
    );
  }

  if (loadFromS3.isLoading) {
    return (
      <Dialog open>
        <DialogContent
          sx={{ display: "flex", alignItems: "center", gap: 2, padding: 4 }}
        >
          <CircularProgress />
          <DialogContentText>Creating scene...</DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  return null;
});
