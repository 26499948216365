import { ExtractRouteParams, Path } from "wouter";

export const routes = {
  scene: "/scene/:id",
  s3: "/s3/:bucket/:folder+",
  dlh1: "/datalake/:slug",
  dlh2: "/dlh2/:slug",
  claim: "/claim/:id",
  example: "/example/:id",
  browse: "/browse/:any+",
  recent: "/browse/recent",
  shared: "/browse/shared",
  public: "/browse/examples",
  browseDlh1: "/browse/datalake",
  browseDlh2: "/browse/dlh2",
  browseS3: "/browse/:bucket/:folder*",
} as const;

export function createRouteLink<T extends Path>(
  path: T,
  params: ExtractRouteParams<T>
) {
  // eslint-disable-next-line no-useless-escape
  let output = path.replaceAll(/[\+\*\?]/g, "") as string;

  Object.entries(params).forEach(([param, value]) => {
    output = output.replace(`:${param}`, `${value}`);
  });

  return output;
}
