import { tsc } from "@promaton/api-client";
import { TscTask } from "@promaton/api-client";
import { applyOSSConvention } from "@promaton/file-processing/src/conventionInternalOSS";
import { applyUniversalConvention } from "@promaton/file-processing/src/conventionUniversal";
import { createNotification } from "@promaton/frontend-common";
import {
  CameraUtils,
  FileType,
  useObjects,
  ViewerObject,
  ViewerObjectMap,
} from "@promaton/scan-viewer";

import { getAiAssistantHeaders } from "../../hooks/useAiAssistantHeaders";
import { useAiAssistantState } from "../../stores/useAiAssistantState";
import { parseAiTaskMetadata } from "./aiTaskMetadata";

type TscTaskResultGroup = {
  loader: typeof tsc.getTscPredictedShape;
  group: string;
  hidden?: boolean;
  fileType?: FileType;
};

const tscTaskResultGroups: TscTaskResultGroup[] = [
  {
    loader: tsc.getTscPredictedShape,
    group: "Predicted",
    hidden: true,
  },
  {
    loader: tsc.getTscStitchedShape,
    group: "Stitched",
  },
  {
    loader: tsc.getTscOriginalShape,
    group: "Original",
    hidden: true,
  },
];

export const loadTscTaskResults = async (task: TscTask) => {
  if (!task.result) return;
  const options = getAiAssistantHeaders();

  let resultLoadingProgress = 0.0001;
  useAiAssistantState.setState({ resultLoadingProgress });

  const presentTeeth = Object.keys(task.result.predictedShapes);

  const stls: [string, ViewerObject][] = [];

  const loadingIncrement =
    1 / (presentTeeth.length * tscTaskResultGroups.length);

  for (const resultGroup of tscTaskResultGroups) {
    await Promise.allSettled(
      presentTeeth.map(async (structure) => {
        const result = await resultGroup
          .loader(task.id, structure, options)
          .finally(() => {
            resultLoadingProgress += loadingIncrement;
            useAiAssistantState.setState({ resultLoadingProgress });
          });
        if (result.data) {
          stls.push([
            `${resultGroup.group}.tooth.${structure}`,
            {
              group: resultGroup.group,
              url: URL.createObjectURL(result.data),
              objectType: resultGroup.fileType ?? FileType.STL,
              clipToPlanes: true,
              hidden: resultGroup.hidden,
            },
          ] as [string, ViewerObject]);
        }
      })
    );
  }

  try {
    const polyline = await tsc.getTscPolyLine(task.id, options).then((t) => {
      return [
        "Polyline",
        {
          url: URL.createObjectURL(new Blob([JSON.stringify(t.data)])),
          objectType: FileType.JSON,
          clipToPlanes: true,
        },
      ] as [string, ViewerObject];
    });

    stls.push(polyline);
  } catch (error) {
    createNotification({
      color: "warning",
      text: "Failed to load polyline",
    });
  }

  try {
    const gingiva = await tsc.getTscGingivaStructure(task.id, options).then(
      (t) =>
        [
          "Gingiva",
          {
            url: URL.createObjectURL(t.data),
            objectType: FileType.STL,
            clipToPlanes: true,
            opacity: 0.5,
          },
        ] as [string, ViewerObject]
    );

    stls.push(gingiva);
  } catch (error) {
    createNotification({
      color: "warning",
      text: "Failed to load gingiva",
    });
  }

  stls.push([
    "result",
    {
      url: URL.createObjectURL(new Blob([JSON.stringify(task.result)])),
      objectType: FileType.JSON,
      clipToPlanes: true,
    },
  ] as [string, ViewerObject]);

  const objects = Object.fromEntries(stls) as ViewerObjectMap;
  const metadata = parseAiTaskMetadata(task.meta_data);
  const input = useObjects.getState().objects[metadata.name];
  if (input) {
    Object.entries(useObjects.getState().objects).forEach(([id, value]) => {
      if (!objects[id] && value) {
        objects[id] = { ...value };
      }
    });

    objects[metadata.name]!.hidden = true;
  }

  applyUniversalConvention(objects);
  await applyOSSConvention(objects);
  useObjects.getState().setObjects(objects, false);
  setTimeout(() => {
    CameraUtils.recenterAllViews();
  }, 200);

  return objects;
};
