import { cbct } from "@promaton/api-client";
import { useMemo } from "react";

import { useAiAssistantState } from "../stores/useAiAssistantState";

export type AxiosOptions = Parameters<(typeof cbct)["getCbctTasks"]>[1];

export const useAiAssistantHeaders = (extra?: object) => {
  const token = useAiAssistantState((s) =>
    s.apiToken ? `Bearer ${s.apiToken}` : undefined
  );
  const endpoint = useAiAssistantState((s) => s.apiEndpoint);

  const headers = useMemo(() => {
    if (!token) return;

    return {
      axios: {
        headers: {
          Authorization: token,
          ...extra,
        },
        baseURL: endpoint,
      } as AxiosOptions,
    };
  }, [token, endpoint, extra]);

  return headers;
};

export const getAiAssistantHeaders = (extra?: object) => {
  const state = useAiAssistantState.getState();
  const token = state.apiToken ? `Bearer ${state.apiToken}` : undefined;
  return {
    headers: {
      Authorization: token,
      ...extra,
    },
    baseURL: state.apiEndpoint,
  } as AxiosOptions;
};
