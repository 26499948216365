import { IconButton, List } from "@mui/material";
import {
  AddCommentForm,
  Comment,
  type CommentFormData,
  makeAvatar,
} from "@promaton/frontend-common";
import { Trash } from "@promaton/icons";
import { FC } from "react";
import { useRoute } from "wouter";

import { routes } from "../helpers/routes";
import { APIOutput, trpc } from "../hooks/trpc";
import { useAppState } from "../stores/useAppState";

type Thread = APIOutput["comment"]["createThread"];

export const CommentCard: FC<{
  draftPosition?: Record<"x" | "y" | "z", number>;
  thread?: Thread;
  onClose?: () => void;
  onCreateThread?: (threadId: string) => void;
}> = ({ draftPosition, thread, onClose, onCreateThread }) => {
  const [_, params] = useRoute(routes.scene);
  const utils = trpc.useContext();
  const createThread = trpc.comment.createThread.useMutation();
  const createComment = trpc.comment.createComment.useMutation();
  const deleteComment = trpc.comment.deleteComment.useMutation();
  const session = useAppState((s) => s.session);
  const isDraft = draftPosition !== undefined;

  const onSubmit = async (data: CommentFormData) => {
    if (!params?.id) return;

    if (isDraft) {
      const newThread = await createThread.mutateAsync({
        text: data.text,
        scene: params.id,
        position: draftPosition,
      });
      onClose?.();
      onCreateThread?.(newThread.id);
      utils.comment.getThreads.setData({ scene: params.id }, (prev) => [
        ...(prev || []),
        newThread,
      ]);
    } else {
      if (!thread?.id) return;
      await createComment.mutateAsync({
        text: data.text,
        thread: thread?.id,
      });
      utils.comment.getThreads.invalidate({ scene: params.id });
    }
  };

  const handleDelete = async (id: string) => {
    await deleteComment.mutateAsync(id);
    params?.id && utils.comment.getThreads.invalidate({ scene: params.id });
  };

  return (
    <>
      {thread?.comments?.length && (
        <List sx={{ overflowY: "auto" }}>
          {thread?.comments?.map((comment) => (
            <Comment
              key={comment.id}
              comment={comment}
              author={comment.user.name?.split(" ")[0] || comment.user.email}
              avatar={makeAvatar(comment.user)}
              secondaryAction={
                session?.email === comment.user.email && (
                  <IconButton
                    disabled={deleteComment.isLoading}
                    onClick={() => {
                      handleDelete(comment.id);
                    }}
                  >
                    <Trash />
                  </IconButton>
                )
              }
            />
          ))}
        </List>
      )}
      <AddCommentForm
        avatar={makeAvatar(session)}
        placeholder={isDraft ? "Write a comment…" : "Reply in thread…"}
        isDraft={isDraft}
        autoFocus={isDraft}
        onSubmit={onSubmit}
      />
    </>
  );
};
