import {
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { feedback } from "@promaton/api-client";
import { Close, ThumbsDown, ThumbsUp } from "@promaton/icons";
import { AnimatePresence, motion } from "framer-motion";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useAsync } from "react-use";
import { useLocation } from "wouter";

import { getAiAssistantHeaders } from "../hooks/useAiAssistantHeaders";
import { useAiAssistantState } from "../stores/useAiAssistantState";

interface ErrorFeedback extends Error {
  response: {
    data: {
      error: string;
    };
  };
}

const AnimatedCard = styled(motion(Card))``;
const SELF_CLOSE_TIMEOUT = 5000;

export const Feedback: FC<{}> = memo(() => {
  const [feedbackStep, setFeedbackStep] = useState(1);
  const [isAccepted, setIsAccepted] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const taskForFeedback = useAiAssistantState((s) => s.latestTaskLoaded);
  const setTaskForFeedback = useAiAssistantState((s) => s.setLatestTaskLoaded);

  const taskId = useMemo(() => taskForFeedback?.id, [taskForFeedback]);
  const options = getAiAssistantHeaders();
  const [location] = useLocation();

  useEffect(() => {
    setFeedbackStep(0);
    setTaskForFeedback(null);
  }, [location]);

  useEffect(() => {
    if (taskId) {
      setError(undefined);
      setFeedbackStep(1);
    }
  }, [taskId]);

  const onClickClose = useCallback(() => {
    setFeedbackStep(0);
    setTaskForFeedback(null);
  }, [setTaskForFeedback]);

  const handleNextStep = useCallback(() => {
    setFeedbackStep((prevStep) => prevStep + 1);
  }, [setFeedbackStep]);

  const onProvideFeedback = useCallback(
    (isAccepted: boolean) => {
      setIsAccepted(isAccepted);
      handleNextStep();
    },
    [setIsAccepted, handleNextStep]
  );

  const submitFeedbackMutation = feedback.useSubmitFeedback({
    axios: {
      ...options,
    },
  });

  const feedbackMissing = useAsync(async () => {
    if (!taskId) return true;
    try {
      await feedback.getFeedback(taskId, options);
      return false;
    } catch (e) {
      return true;
    }
  }, [taskId]);

  const onSubmitFeedback = useCallback(
    async (comment: string) => {
      if (!taskId) {
        setError("No task id provided");
        return;
      }

      try {
        await submitFeedbackMutation.mutateAsync({
          taskId,
          data: {
            is_accepted: isAccepted,
            comment,
          },
        });
      } catch (e) {
        const error = e as ErrorFeedback;
        setError(error.response.data.error);
      }
    },
    [getAiAssistantHeaders, taskId, isAccepted]
  );

  const onProvideComment = useCallback(
    (comment: string) => {
      onSubmitFeedback(comment);
      handleNextStep();
    },
    [onSubmitFeedback, handleNextStep]
  );

  useEffect(() => {
    if (feedbackStep === 3) {
      setTimeout(() => {
        onClickClose();
      }, SELF_CLOSE_TIMEOUT);
    }
  }, [feedbackStep]);

  if (!taskId || !feedbackMissing.value) return null;

  return (
    <AnimatePresence>
      <AnimatedCard
        variant="outlined"
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          padding: 3,
          margin: 3,
          width: 350,
          borderRadius: 2,
          height: "auto",
        }}
        layout
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 100 }}
        transition={{ duration: 0.2 }}
      >
        {error && <ErrorFeedback error={error} onClickClose={onClickClose} />}
        {!error && (
          <>
            {feedbackStep === 1 && (
              <ProvideFeedback
                onProvideFeedback={onProvideFeedback}
                onClickClose={onClickClose}
              />
            )}
            {feedbackStep === 2 && (
              <CommentFeedback
                onClickClose={onClickClose}
                onClickSend={onProvideComment}
                isSubmitting={submitFeedbackMutation.isLoading}
              />
            )}
            {feedbackStep === 3 && (
              <ThankYouFeedback onClickClose={onClickClose} />
            )}
          </>
        )}
      </AnimatedCard>
    </AnimatePresence>
  );
});

interface ProvideFeedbackProps {
  onClickClose: () => void;
  onProvideFeedback: (isAccepted: boolean) => void;
}

const ProvideFeedback = ({
  onClickClose,
  onProvideFeedback,
}: ProvideFeedbackProps) => {
  return (
    <LayoutRow layout>
      <Typography
        variant="body1"
        sx={{
          width: "100%",
        }}
      >
        How are the results?
      </Typography>
      <Tooltip title="Good">
        <IconButton
          size="small"
          onClick={() => onProvideFeedback(true)}
          data-testid="feedback-thumbsup-button"
        >
          <ThumbsUp />
        </IconButton>
      </Tooltip>
      <Tooltip title="Bad">
        <IconButton
          size="small"
          onClick={() => onProvideFeedback(false)}
          data-testid="feedback-thumbsdown-button"
        >
          <ThumbsDown />
        </IconButton>
      </Tooltip>
      <Divider orientation="vertical" flexItem />
      <Tooltip title="Close">
        <IconButton
          size="small"
          style={{ opacity: 0.7 }}
          onClick={onClickClose}
        >
          <Close />
        </IconButton>
      </Tooltip>
    </LayoutRow>
  );
};

interface CommentFeedbackProps {
  onClickClose: () => void;
  onClickSend: (comment: string) => void;
  isSubmitting: boolean;
}

const CommentFeedback = ({
  onClickClose,
  onClickSend,
  isSubmitting,
}: CommentFeedbackProps) => {
  const [comment, setComment] = useState<string | undefined>();
  return (
    <LayoutColumn layout>
      <Header>
        <Typography
          variant="body1"
          color="text.primary"
          sx={{
            textAlign: "left",
            width: "100%",
          }}
        >
          Do you have any feedback?
        </Typography>
        <Tooltip title="Close">
          <IconButton size="small" sx={{ opacity: 0.7 }} onClick={onClickClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </Header>
      <div>
        <Card variant="outlined">
          <TextField
            autoFocus
            placeholder="Write a comment..."
            sx={{ borderRadius: 16, width: "100%" }}
            type="text"
            multiline
            rows={4}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            data-testid="feedback-comment-input"
          />
        </Card>
      </div>
      <ButtonContainer>
        <Button
          variant="contained"
          type="submit"
          onClick={() => onClickSend(comment!)}
          disabled={isSubmitting}
          data-testid="feedback-send-button"
        >
          {isSubmitting || false ? <CircularProgress size={16} /> : <>Send</>}
        </Button>
      </ButtonContainer>
    </LayoutColumn>
  );
};

interface ThankYouFeedbackProps {
  onClickClose: () => void;
}

const ThankYouFeedback = ({ onClickClose }: ThankYouFeedbackProps) => {
  return (
    <LayoutColumn layout>
      <Header>
        <Typography
          variant="body1"
          sx={{
            width: "100%",
          }}
        >
          Feedback submitted!
        </Typography>
        <Tooltip title="Close">
          <IconButton
            size="small"
            style={{ opacity: 0.7 }}
            onClick={onClickClose}
          >
            <Close />
          </IconButton>
        </Tooltip>
      </Header>
      <Typography
        variant="body1"
        sx={{
          width: "100%",
        }}
      >
        Thanks for shaping our AI&apos;s future with your feedback! Your input
        drives us to deliver better results every time.
      </Typography>
    </LayoutColumn>
  );
};

interface ErrorFeedbackProps {
  error: string;
  onClickClose: () => void;
}

const ErrorFeedback = ({ error, onClickClose }: ErrorFeedbackProps) => {
  return (
    <LayoutColumn layout>
      <Header>
        <Typography
          sx={{
            width: "100%",
          }}
        >
          Something went wrong!
        </Typography>
        <Tooltip title="Close">
          <IconButton
            size="small"
            style={{ opacity: 0.7 }}
            onClick={onClickClose}
          >
            <Close />
          </IconButton>
        </Tooltip>
      </Header>
      <Typography
        variant="body2"
        sx={{
          width: "100%",
        }}
      >
        Error - {error}
      </Typography>
    </LayoutColumn>
  );
};

const LayoutRow = styled(motion.div)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const LayoutColumn = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

const Header = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const ButtonContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing(2)};
`;
