import {
  ExtendButtonBase,
  ListItemButton,
  ListItemButtonProps,
  ListItemButtonTypeMap,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { FC, ReactNode } from "react";

const BrowserItemContainer = styled(
  ListItemButton as ExtendButtonBase<ListItemButtonTypeMap<{}, "a">>
)`
  transition-duration: 0s;

  .hide {
    opacity: 0;
  }

  &:hover {
    .hide {
      opacity: 1;
    }
  }
`;

export const BrowserItem: FC<
  {
    link?: string;
    title: string;
    subtitle?: string;
    selected?: boolean;
    icon?: ReactNode;
    secondary?: ReactNode;
  } & ListItemButtonProps<"a">
> = ({
  link,
  selected,
  icon,
  onClick,
  title,
  subtitle,
  secondary,
  ...rest
}) => {
  return (
    <Container
      sx={{
        padding: 0.5,
        boxSizing: "border-box",
        marginX: -0.5,
      }}
    >
      <BrowserItemContainer
        disableRipple
        draggable={false}
        {...rest}
        sx={
          rest.sx || {
            margin: "auto",
            borderRadius: 1,
          }
        }
        selected={selected}
        href={link || ""}
        onClick={(e) => {
          if (!link || e.metaKey || e.ctrlKey) return;
          e.preventDefault();
          return onClick && onClick(e);
        }}
      >
        {icon && (
          <ListItemIcon sx={{ marginRight: 2, minWidth: "initial" }}>
            {icon}
          </ListItemIcon>
        )}
        <ListItemText
          primaryTypographyProps={{
            sx: {
              width: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            },
          }}
          secondaryTypographyProps={{
            sx: {
              width: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            },
          }}
          title={title}
          primary={title}
          secondary={subtitle}
        />
        {secondary}
      </BrowserItemContainer>
    </Container>
  );
};

const Container = styled("div")``;
