import { ButtonProps, CircularProgress, Stack } from "@mui/material";
import { Sparkle } from "@promaton/icons";
import { ToolbarButton } from "@promaton/scan-viewer";
import { QueryClientProvider } from "@tanstack/react-query";
import { FC, lazy, memo, Suspense } from "react";

import {
  aiQueryClient,
  useAiAssistantState,
} from "../stores/useAiAssistantState";

const AiAssistantModal = lazy(() => import("./AiAssistantModal"));

export const AiAssistant: FC<{ buttonProps?: ButtonProps }> = memo(
  ({ buttonProps }) => {
    const setDialogOpen = useAiAssistantState((s) => s.setDialogOpen);
    const activeTask = useAiAssistantState((s) => s.activeTasks.length > 0);

    return (
      <QueryClientProvider client={aiQueryClient}>
        <>
          <ToolbarButton
            onClick={() => setDialogOpen(true)}
            sx={{ color: (t) => t.palette.secondary.main, fontWeight: "bold" }}
            icon={
              activeTask ? (
                <Stack>
                  <CircularProgress
                    sx={{ position: "absolute" }}
                    color="secondary"
                    size={24}
                  ></CircularProgress>
                  <Sparkle color="secondary" sx={{ transform: "scale(0.7)" }} />
                </Stack>
              ) : (
                <Sparkle color="secondary" />
              )
            }
            label={"AI"}
            tooltip={"Run Promaton AI APIs"}
            {...buttonProps}
          />
          <Suspense>
            <AiAssistantModal />
          </Suspense>
        </>
      </QueryClientProvider>
    );
  }
);
