import type { AvatarData } from "./AvatarData";

export function makeAvatar(
  userObject: {
    picture?: string | null;
    name?: string | null;
    email?: string;
  } | null
): AvatarData | undefined {
  if (userObject === null) {
    return undefined;
  }

  if (userObject.picture != null) {
    return { picture: userObject.picture };
  } else {
    return { name: userObject.name ?? userObject.email ?? "" };
  }
}
