import { useLoadingState } from "@promaton/scan-viewer";
import { memo, useEffect, useState } from "react";
import ga4 from "react-ga4";

export const LoadingMetric = memo(() => {
  const loadingDone = useLoadingState((s) => s.firstLoadComplete);
  const [loadingStart, setLoadingStart] = useState(Date.now());

  useEffect(() => {
    if (loadingDone) {
      const loadingDuration = Date.now() - loadingStart;
      ga4.event({
        category: "loading",
        action: "Initial load complete",
        value: loadingDuration / 1000,
        nonInteraction: true,
      });
    } else {
      setLoadingStart(Date.now());
    }
  }, [loadingDone]);

  return null;
});
