import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useCommandPalette } from "@promaton/frontend-common";
import { BoundingBox, Layer } from "@promaton/icons";
import { useShortcut } from "@promaton/scan-viewer";
import { sentenceCase } from "change-case";
import { FC, memo, useEffect } from "react";
import ga4 from "react-ga4";

import {
  AxisDirection,
  ControlMode,
  Resolution,
  ThemeMode,
  useAppState,
  ViewMode,
} from "../stores/useAppState";

const RESOLUTION_SCALES = ["auto", 0.1, 0.5, 0.75, 1.0] as const;

export const SettingsDialog: FC<{}> = memo(() => {
  const settings = useAppState((s) => s.settings);
  const showSettings = useAppState((s) => s.showSettings);
  const state = useAppState();
  const updateCommand = useCommandPalette((s) => s.updateCommand);

  useShortcut(
    ",",
    (e) => {
      if (e.metaKey || e.ctrlKey) {
        showSettings(!settings);
        e.preventDefault();
      }
    },
    [settings]
  );

  useEffect(() => {
    updateCommand("settings", {
      name: "Settings",
      description: !settings
        ? "Open the settings dialog"
        : "Close the settings dialog",
      action: () => showSettings(!settings),
      keywords: "preferences toggle configuration",
    });
  }, [settings]);

  useEffect(() => {
    updateCommand("lightTheme", {
      name: "Light mode",
      description: "Switch to the light theme",
      action: () => state.setThemeMode(ThemeMode.LIGHT),
    });
    updateCommand("darkTheme", {
      name: "Dark mode",
      description: "Switch to the dark theme",
      action: () => state.setThemeMode(ThemeMode.DARK),
    });
    updateCommand("systemTheme", {
      name: "Use system light / dark mode",
      description: "Switch to the system theme",
      action: () => state.setThemeMode(ThemeMode.SYSTEM),
    });
    updateCommand("toggleBounds", {
      name: state.showBounds ? "Hide bounding boxes" : "Show bounding boxes",
      description: "Toggle the visibility of bounding boxes on selected meshes",
      action: () => state.setShowBounds(!state.showBounds),
    });
    updateCommand("toggleWireframe", {
      name: state.showWireframe ? "Disable wireframes" : "Enable wireframes",
      description: "Shows polygon outlines when enabled",
      action: () => state.setShowWireframe(!state.showWireframe),
    });
  }, [state]);

  return (
    <Dialog
      open={settings}
      onClose={() => showSettings(false)}
      PaperProps={{
        variant: "outlined",
        sx: {
          width: 600,
          maxWidth: "100%",
          overflowY: "auto",
        },
      }}
    >
      <DialogTitle>Viewer Settings</DialogTitle>
      <DialogContent sx={{ scrollbarWidth: "thin" }}>
        <Stack gap={2}>
          <Stack flexDirection={"row"}>
            <ListItemText
              primary="Theme"
              secondary="Colors of the app"
              sx={{ flex: 1 }}
            />
            <Select
              sx={{ width: 150 }}
              value={state.themeMode}
              onChange={(e) => {
                state.setThemeMode(e.target.value as ThemeMode);
                ga4.event({
                  category: "settings",
                  action: "setTheme",
                });
              }}
            >
              {Object.entries(ThemeMode).map(([_, value]) => (
                <MenuItem value={value} key={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack flexDirection={"row"}>
            <ListItemText
              primary="View mode"
              secondary="Layout of the viewport"
              sx={{ flex: 1 }}
            />
            <Select
              sx={{ width: 150 }}
              value={state.viewMode}
              onChange={(e) => {
                state.setViewMode(e.target.value as ViewMode);
              }}
            >
              {Object.entries(ViewMode).map(([_, value]) => (
                <MenuItem value={value} key={value}>
                  {sentenceCase(value)}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack flexDirection={"row"}>
            <ListItemText
              primary="Controls"
              secondary="Changes reload page"
              sx={{ flex: 1 }}
            />
            <Select
              sx={{ width: 150 }}
              value={state.controlMode}
              onChange={(e) => {
                state.setControlMode(e.target.value as ControlMode);
                location.reload();
              }}
              renderValue={(selected) => sentenceCase(selected)}
            >
              {Object.entries(ControlMode).map(([_, value]) => (
                <MenuItem value={value} key={value}>
                  <ListItemText
                    primary={sentenceCase(value)}
                    secondary={ControlModeDescription[value]}
                  />
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack flexDirection={"row"}>
            <ListItemText
              primary="Render resolution"
              secondary="Impacts render performance while moving"
              sx={{ flex: 1 }}
            />
            <Select
              sx={{ width: 150 }}
              value={state.resolution}
              onChange={(e) => {
                state.setResolution(e.target.value as Resolution);
              }}
            >
              {RESOLUTION_SCALES.map((value) => (
                <MenuItem value={value} key={`${value}`}>
                  {value === "auto" ? value : `${value * 100}%`}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack flexDirection={"row"}>
            <ListItemText
              primary="Axis direction"
              secondary="Switch between Y or Z axis label up"
              sx={{ flex: 1 }}
            />
            <Select
              sx={{ width: 150 }}
              value={state.axisDirection}
              onChange={(e) => {
                state.setAxisDirection(e.target.value as AxisDirection);
              }}
              renderValue={(selected) =>
                sentenceCase(
                  `${selected.split("_")[0]} ${selected.split("_")[1]}}`
                )
              }
            >
              {Object.entries(AxisDirection).map(([_, value]) => (
                <MenuItem value={value} key={value}>
                  <ListItemText
                    primary={sentenceCase(
                      `${value.split("_")[0]} ${value.split("_")[1]}}`
                    )}
                    secondary={AxisDirectionDescription[value]}
                  />
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack flexDirection={"row"} alignItems="center">
            <ListItemIcon>
              <Layer />
            </ListItemIcon>
            <ListItemText
              primary="Wireframes"
              secondary="Show polygon wireframes in 3D meshes"
              sx={{ flex: 1 }}
            />
            <Switch
              checked={state.showWireframe}
              onChange={(e, v) => {
                state.setShowWireframe(v);
                ga4.event({
                  category: "settings",
                  action: "showWireframe",
                });
              }}
            />
          </Stack>
          <Stack flexDirection={"row"} alignItems="center">
            <ListItemIcon>
              <BoundingBox />
            </ListItemIcon>
            <ListItemText
              primary="Bounding boxes"
              secondary="Show bounding boxes around selected meshes"
              sx={{ flex: 1 }}
            />
            <Switch
              checked={state.showBounds}
              onChange={(e, v) => {
                state.setShowBounds(v);
                ga4.event({
                  category: "settings",
                  action: "showBoundingBox",
                });
              }}
            />
          </Stack>

          <Divider />

          <Typography variant="overline">Experimental features</Typography>
          <Stack flexDirection={"row"} alignItems="center">
            <ListItemText
              primary="Intersection heatmaps"
              secondary="Colors a mesh based on intersection with other mesh while moving or sculpting it"
              sx={{ flex: 1 }}
            />
            <Switch
              checked={state.showIntersectionHeatmaps}
              onChange={(e, v) => {
                state.setShowIntersectionHeatmaps(v);
                ga4.event({
                  category: "settings",
                  action: "showIntersectionHeatmaps",
                });
              }}
            />
          </Stack>
          <Stack flexDirection={"row"} alignItems="center">
            <ListItemText
              primary="Landmark visibility threshold in 2D"
              secondary="Maximum distance (mm) from slice plane to show landmarks at"
              sx={{ flex: 1 }}
            />
            <TextField
              sx={{ maxWidth: 100 }}
              value={state.maxLandmarkSliceDistance}
              type="number"
              inputProps={{
                min: 0,
                step: 0.1,
              }}
              onChange={(e) => {
                state.setMaxLandmarkSliceDistance(
                  parseFloat(e.target.value) ?? 0.3
                );
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
});

const ControlModeDescription = {
  [ControlMode.ORBIT]: "Rotate with a straight horizon (default)",
  [ControlMode.ARCBALL]: "Rotate freely around model",
};

const AxisDirectionDescription = {
  [AxisDirection.Y_UP_RIGHT_HANDED]: "Y axis label up (default)",
  [AxisDirection.Z_UP_RIGHT_HANDED]: "Z axis label up",
};
