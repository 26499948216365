import {
  Alert,
  ButtonBase,
  Chip,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { BoundingBox2 } from "@promaton/icons";
import { FC, memo } from "react";

import { useIsDarkMode } from "../hooks/useIsDarkMode";
import {
  AiTaskData,
  AiTaskType,
  TasksCompletionCallback,
  useAiAssistantState,
} from "../stores/useAiAssistantState";
import { AiAssistantTask } from "./AiAssistantTask";
import { EmptyState } from "./EmptyState";

export const AiAssistantRootPage: FC<{
  ossEnabled: boolean;
  cbctEnabled: boolean;
  acdEnabled: boolean;
  tscEnabled: boolean;
  alignmentEnabled: boolean;
  sippEnabled: boolean;
  jawFillingEnabled: boolean;
  taskList: AiTaskData[];
  pendingTasks: TasksCompletionCallback[];
}> = memo(
  ({
    ossEnabled,
    cbctEnabled,
    acdEnabled,
    tscEnabled,
    alignmentEnabled,
    sippEnabled,
    jawFillingEnabled,
    taskList,
    pendingTasks,
  }) => {
    const isDark = useIsDarkMode();
    return (
      <Stack gap={1} sx={{ height: "100%" }}>
        <Typography variant="subtitle1" fontWeight={"medium"}>
          Create new API Task
        </Typography>
        <Grid2 container spacing={3}>
          <APICardGridItem
            enabled={ossEnabled}
            icon={isDark ? "/oss/dark.svg" : "/oss/light.svg"}
            title="Optical Scan Segmentation"
            page={AiTaskType.OPTICAL}
          />
          <APICardGridItem
            enabled={cbctEnabled}
            icon={isDark ? "/cbct/dark.svg" : "/cbct/light.svg"}
            title="CBCT Segmentation"
            page={AiTaskType.CBCT}
          />
          <APICardGridItem
            enabled={acdEnabled}
            icon={isDark ? "/acd/dark.svg" : "/acd/light.svg"}
            title="Automatic Crown Design"
            page={AiTaskType.ACD}
          />
          <APICardGridItem
            enabled={alignmentEnabled}
            icon={isDark ? "/fusion/dark.svg" : "/fusion/light.svg"}
            title="Alignment & Fusion"
            page={AiTaskType.ALIGNMENT}
          />
          <APICardGridItem
            enabled={tscEnabled}
            icon={isDark ? "/tsc/dark.png" : "/tsc/light.png"}
            title="Tooth Shape Completion"
            page={AiTaskType.TSC}
          />
          <APICardGridItem
            enabled={sippEnabled}
            icon={isDark ? "/sipp/dark.png" : "/sipp/light.png"}
            title="Implant Placement Planning"
            page={AiTaskType.SIPP}
          />
          <APICardGridItem
            enabled={jawFillingEnabled}
            icon={isDark ? "/jaw/dark.svg" : "/jaw/light.svg"}
            title="Jaw-Hole Filling"
            page={AiTaskType.CBCT_FILLED_JAW}
          />
        </Grid2>

        {pendingTasks?.length ? (
          <Alert severity="warning" variant="filled" sx={{ mt: 2 }}>
            {pendingTasks.length} {pendingTasks.length > 1 ? "tasks" : "task"}{" "}
            waiting to start. Please keep this tab open.
          </Alert>
        ) : null}

        <Typography variant="subtitle1" fontWeight={"medium"} marginTop={2}>
          Tasks
        </Typography>
        {taskList?.length ? (
          <Stack
            sx={{
              flexBasis: "auto",
              flex: 1,
              borderRadius: 1,
              gap: 2,
            }}
          >
            {taskList.map((task) => (
              <AiAssistantTask task={task} key={task.id} />
            ))}
          </Stack>
        ) : (
          <EmptyState
            sx={{
              background: (t) => t.palette.action.hover,
              flex: 1,
              opacity: 0.7,
              borderRadius: 1,
            }}
          >
            <BoundingBox2 fontSize="large"></BoundingBox2>
            <Typography>No recent tasks</Typography>
          </EmptyState>
        )}
      </Stack>
    );
  }
);

const APICardGridItem: FC<{
  enabled: boolean;
  page: AiTaskType;
  icon: string;
  title: string;
}> = ({ enabled, page, icon, title }) => {
  const setActivePage = useAiAssistantState((s) => s.setActivePage);

  return (
    <Grid2 xs={6} md={3} sm={4}>
      <APICard
        disabled={!enabled}
        disableRipple
        onClick={() => {
          setActivePage(page);
        }}
      >
        <APIIcon src={icon} />
        {!enabled && <APIBadge label={"Missing permission"} variant="filled" />}
        <Typography
          variant="body2"
          sx={{
            maxInlineSize: `15ch`,
            textWrap: "balance",
          }}
        >
          {title}
        </Typography>
      </APICard>
    </Grid2>
  );
};

export const APICard = styled(ButtonBase)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  perspective: 100px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  &:disabled {
    img,
    p {
      opacity: 0.5;
    }
  }

  &:hover img {
    transform: rotateX(2deg) translateY(-5px) scale(1.02);
    filter: drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.1));
  }
`;

export const APIIcon = styled("img")`
  margin: ${({ theme }) => theme.spacing(1.5)};
  width: 80%;
  user-select: none;
  pointer-events: none;
  height: auto;
  transform-style: preserve-3d;
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.15));
  transition: all 0.25s ease;
`;

const APIBadge = styled(Chip)`
  position: absolute;
  z-index: 1;
  top: ${({ theme }) => theme.spacing(7)};
`;
