import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";

import { trpc } from "../../hooks/trpc";
import { useAppState } from "../../stores/useAppState";
import { UpdateButton } from "../UpdateButton";
import { SceneTitle } from "./SceneTitle";

export const SharedSceneTitle: FC<{ id: string; onClose: () => void }> = ({
  id,
  onClose,
}) => {
  const session = useAppState((s) => s.session);
  const scene = trpc.scene.get.useQuery(id, {
    enabled: !!session,
  });

  const [editTitleDialogOpen, setEditTitleDialogOpen] = useState(false);

  const isOwner = scene.data && scene.data?.owner?.email === session?.email;

  const subtitle = scene.data
    ? `by ${scene.data.owner?.name?.split(" ")[0] || "Unknown"} on ${new Date(
        scene.data.createdAt!
      ).toLocaleString("en-GB", {
        month: "short",
        day: "numeric",
      })}`
    : undefined;

  return (
    <SceneTitle
      title={scene.data?.name}
      subtitle={subtitle}
      onClose={onClose}
      onEditTitle={
        isOwner
          ? () => {
              setEditTitleDialogOpen(true);
            }
          : undefined
      }
    >
      {isOwner && <UpdateButton id={id} />}
      {editTitleDialogOpen && scene.data?.name && (
        <EditTitleDialog
          id={id}
          title={scene.data?.name}
          onClose={() => {
            setEditTitleDialogOpen(false);
          }}
        ></EditTitleDialog>
      )}
    </SceneTitle>
  );
};

const EditTitleDialog: FC<{
  id: string;
  onClose: () => void;
  title: string;
}> = ({ id, onClose, title }) => {
  const { register, handleSubmit } = useForm<{ title: string }>({
    defaultValues: { title },
  });

  const update = trpc.scene.update.useMutation();
  const tools = trpc.useContext();

  return (
    <Dialog
      open
      PaperProps={{
        sx: {
          width: 500,
        },
      }}
    >
      <DialogTitle>Edit Scene Title</DialogTitle>
      <DialogContent>
        <form
          onSubmit={handleSubmit(async (d) => {
            await update.mutateAsync({
              id,
              name: d.title,
            });
            tools.scene.get.setData(id, (scene) => {
              if (!scene) return;
              return {
                ...scene,
                name: d.title,
              };
            });

            onClose();
          })}
        >
          <Stack flexDirection={"row"} gap={2}>
            <TextField
              sx={{ flex: 1 }}
              {...register("title", { minLength: 1, maxLength: 100 })}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={update.isLoading}
            >
              Save
            </Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};
