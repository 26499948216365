import {
  Box,
  GlobalStyles,
  Stack,
  styled,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import {
  Convention,
  processDroppedFiles,
  processLaunchFiles,
  processPickedFiles,
} from "@promaton/file-processing";
import { CommandPalette, GlobalDialogs } from "@promaton/frontend-common";
import {
  CoordinateSystem,
  FileType,
  OrthographicView,
  PerspectiveView,
  SyncViews,
  useObjects,
  useStaging,
  Viewer,
  ViewerObjectMap,
  ViewOrientation,
  Viewport,
} from "@promaton/scan-viewer";
import { PSG, Sculpt } from "@promaton/scan-viewer/dist/helpers";
import { useCallback } from "react";
import ga4 from "react-ga4";
import { Helmet } from "react-helmet";
import { useMount } from "react-use";
import { Route, Switch, useLocation, useRoute } from "wouter";

import { Analytics } from "./components/Analytics";
import { BrowseHistory } from "./components/BrowseHistory";
import { ClaimDialog } from "./components/ClaimDialog";
import { ClientProvider } from "./components/ClientProvider";
import { CopySceneState } from "./components/CopySceneState";
import { Dlh2Loader } from "./components/Dlh2Loader";
import { DropOverlay } from "./components/DropOverlay";
import { Feedback } from "./components/Feedback";
import { InternalRootPage } from "./components/InternalRootPage";
import { Menu } from "./components/Menu";
import { Onboarding } from "./components/Onboarding";
import { OnlineChecker } from "./components/OnlineChecker";
import { PresentationStage } from "./components/PresentationStage";
import { PublicExampleLoader } from "./components/PublicExampleLoader";
import { PublicRootPage } from "./components/PublicRootPage";
import { S3LoadingURLParam } from "./components/S3LoadingDialog";
import { SceneLoader } from "./components/SceneLoader";
import { SettingsDialog } from "./components/SettingsDialog";
import { Sidebar } from "./components/Sidebar";
import { createThumb } from "./helpers/createThumb";
import { routes } from "./helpers/routes";
import { ViewName } from "./helpers/viewNames";
import { useDrop } from "./hooks/useDrop";
import { useIsMobile } from "./hooks/useIsMobile";
import { useIsPresentationMode } from "./hooks/useIsPresentationMode";
import { useIsPublicViewer } from "./hooks/useIsPublicViewer";
import { useAppState, ViewMode } from "./stores/useAppState";
import { OnboardingStepSelector } from "./stores/useOnboarding";
import { useRecents } from "./stores/useRecents";
import { BoundingBox } from "./widgets/BoundingBox";
import { SceneComments } from "./widgets/SceneComments";

const Container = styled(Box)`
  height: 100vh;
  max-height: fill-available;
  width: 100vw;
`;

function App() {
  const isPublic = useIsPublicViewer();
  const theme = useTheme();
  const [isScene] = useRoute(routes.scene);
  const [isRoot] = useRoute("/");
  const [location, setLocation] = useLocation();
  const [isBrowse] = useRoute(routes.browse);
  const setObjects = useObjects((s) => s.setObjects);
  const setCoordinateSystem = useStaging((s) => s.setCoordinateSystem);
  const isEmpty = useObjects((s) => Object.keys(s.objects).length === 0);
  const mobile = useIsMobile();
  const isRecent = useRecents((s) => s.recents.at(-1)?.path === location);

  const loadObjects = useCallback(
    ({
      objects,
      convention,
    }: {
      objects: ViewerObjectMap;
      convention?: Convention;
    }) => {
      setObjects(objects, true);
      setCoordinateSystem(
        convention === Convention.SIAB
          ? CoordinateSystem.IJK
          : CoordinateSystem.LPS
      );
      setLocation("/");
      ga4.event({
        category: "loading",
        action: "localFilesLoaded",
      });
    },
    [setObjects, setCoordinateSystem, setLocation]
  );

  const onFileSelect = useCallback(
    async (files: FileList) => {
      loadObjects(await processPickedFiles(files));
    },
    [loadObjects]
  );

  const { over: dropActive } = useDrop({
    onDataTransfer: async (data) => {
      if (isScene) return;
      const existing = useObjects.getState().objects as ViewerObjectMap;
      loadObjects(await processDroppedFiles(data, existing));
    },
  });

  useMount(() => {
    processLaunchFiles(loadObjects);
  });

  const isPresentationMode = useIsPresentationMode();
  const viewMode = useAppState((s) =>
    isPresentationMode ? ViewMode.SINGLE : s.viewMode
  );
  const controlMode = useAppState((s) => s.controlMode);
  const axisDirection = useAppState((s) => s.axisDirection);
  const showWireframe = useAppState((s) => s.showWireframe);
  const maxLandmarkSliceDistance = useAppState(
    (s) => s.maxLandmarkSliceDistance
  );
  const showIntersectionHeatmaps = useAppState(
    (s) => s.showIntersectionHeatmaps
  );
  const layerListVisible = useAppState((s) => s.sidebarVisible);
  const resolutionScale = useAppState((s) => s.resolution);

  return (
    <Container>
      <Helmet>
        <meta name="theme-color" content={theme.palette.background.paper} />
      </Helmet>
      <GlobalStyles
        styles={{
          body: {
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            colorScheme: theme.palette.mode,
            overflowX: "hidden",
          },
        }}
      />
      {!isBrowse && (!isEmpty || !isRoot) && (
        <Stack
          direction="column"
          alignItems={"stretch"}
          sx={{ inset: 0, position: "absolute", height: "100%" }}
        >
          <Menu onOpenFiles={onFileSelect} />
          {!isEmpty && (
            <Stack
              direction={"row"}
              alignItems={"stretch"}
              sx={{ flex: 1, minHeight: 0, zIndex: 0, height: "100%" }}
            >
              <Sidebar open={layerListVisible} />
              <Viewer
                theme={theme}
                config={{
                  enableObjectDeletion: true,
                  enableTransforms: true,
                  enableAutomaticCameraMovement: false,
                  enableWireframe: showWireframe,
                  enableOrthogonalPlaneVisualizationIn3DView: true,
                  enableAdvancedMaterialControls: true,
                  enableIntersectionHeatmaps: showIntersectionHeatmaps,
                  selectionOutline: {
                    color: theme.palette.warning.main,
                  },
                  axisDirection,
                  resolutionScale,
                  maxLandmarkDistanceFromSlicePlane: maxLandmarkSliceDistance,
                }}
                objects={undefined}
                sx={{
                  zIndex: 0,
                  flex: 1,
                  display: "flex",
                  height: "100%",
                  position: "relative",
                  flexDirection: mobile ? "column" : "row",
                }}
                customFileComponents={{
                  [FileType.PSG]: PSG,
                }}
                onLoad={async () => {
                  if (isRecent && !isPresentationMode) {
                    const recentState = useRecents.getState();
                    const recent = recentState.recents.at(-1);
                    if (recent) {
                      const screenshot = await createThumb();
                      recentState.addRecent({ ...recent, screenshot });
                    }
                  }
                }}
                sceneExtension={
                  <>
                    <ThemeProvider theme={theme}>
                      <ClientProvider>
                        <BoundingBox />
                        <Route path={routes.scene}>
                          {({ id }) => <SceneComments scene={id} />}
                        </Route>
                      </ClientProvider>
                    </ThemeProvider>
                    <Sculpt />
                    {isPresentationMode && <PresentationStage />}
                    {viewMode === ViewMode.SIDE_BY_SIDE && (
                      <SyncViews views={[ViewName.LEFT, ViewName.RIGHT]} />
                    )}
                  </>
                }
              >
                <Stack
                  sx={{
                    flex: 1,
                  }}
                >
                  <Viewport
                    name={
                      viewMode === ViewMode.SIDE_BY_SIDE
                        ? ViewName.LEFT
                        : undefined
                    }
                    className={OnboardingStepSelector.VIEW_3D}
                    component={PerspectiveView}
                    props={{
                      controls: controlMode,
                    }}
                    allowOrientationSwitch={
                      viewMode === ViewMode.SINGLE ||
                      viewMode === ViewMode.SIDE_BY_SIDE
                    }
                    style={{ border: "none" }}
                  />
                  {viewMode === ViewMode.SPLIT && !mobile && (
                    <Viewport
                      component={OrthographicView}
                      props={{ axis: ViewOrientation.COR }}
                      style={{ border: "none" }}
                    />
                  )}
                </Stack>
                {viewMode === ViewMode.SPLIT && (
                  <Stack
                    sx={{
                      flex: 1,
                    }}
                  >
                    <Viewport
                      component={OrthographicView}
                      props={{ axis: ViewOrientation.AXI }}
                      style={{ border: "none" }}
                    />
                    {!mobile && (
                      <Viewport
                        component={OrthographicView}
                        props={{ axis: ViewOrientation.SAG }}
                        style={{ border: "none" }}
                      />
                    )}
                  </Stack>
                )}
                {viewMode === ViewMode.CUSTOM && (
                  <Stack
                    sx={{
                      flex: 1,
                    }}
                  >
                    <Viewport
                      component={OrthographicView}
                      props={{ axis: ViewOrientation.CUSTOM }}
                      style={{ border: "none" }}
                    />
                  </Stack>
                )}
                {viewMode === ViewMode.SIDE_BY_SIDE && (
                  <Stack
                    sx={{
                      flex: 1,
                    }}
                  >
                    <Viewport
                      name={ViewName.RIGHT}
                      component={PerspectiveView}
                      allowOrientationSwitch
                      props={{
                        controls: controlMode,
                      }}
                      style={{ border: "none" }}
                    />
                  </Stack>
                )}
              </Viewer>
            </Stack>
          )}
        </Stack>
      )}
      <Switch>
        <Route path={routes.s3}>
          {(params) => (
            <S3LoadingURLParam
              bucket={params.bucket}
              folder={decodeURIComponent(params.folder)}
            />
          )}
        </Route>
        <Route path={routes.scene}>
          {(params) => <SceneLoader {...params} />}
        </Route>
        <Route path={routes.claim}>
          {(params) => <ClaimDialog {...params} />}
        </Route>
        <Route path={routes.example}>
          {(params) => <PublicExampleLoader {...params} />}
        </Route>

        <Route path={routes.dlh2}>
          {(params) => <Dlh2Loader {...params} />}
        </Route>
        <Route>
          {(isEmpty || isBrowse) &&
            (isPublic ? (
              <PublicRootPage onOpenFiles={onFileSelect} />
            ) : (
              <InternalRootPage onOpenFiles={onFileSelect} />
            ))}
        </Route>
      </Switch>

      <GlobalDialogs />
      <SettingsDialog />
      <Analytics />
      {!isPublic && <OnlineChecker />}
      {isBrowse && <BrowseHistory />}
      <DropOverlay active={dropActive} />
      <Onboarding />
      <CopySceneState />
      <CommandPalette />
      <Feedback />
    </Container>
  );
}

export default App;
