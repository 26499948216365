import { memo, useEffect } from "react";
import { useLocation } from "wouter";
import { create } from "zustand";

export const useBrowseHistory = create<{
  lastLocation?: string;
  set: (lastLocation: string) => void;
}>((set) => ({
  lastLocation: undefined,
  set: (lastLocation) => set({ lastLocation }),
}));

export const BrowseHistory = memo(() => {
  const [_] = useLocation();

  useEffect(() => {
    useBrowseHistory
      .getState()
      .set(`${window.location.pathname}${window.location.search}`);
  });

  return null;
});
