import { takeScreenshot } from "@promaton/scan-viewer";

export const createThumb = () => {
  return new Promise<string>((res, rej) => {
    const canvas = document.querySelector("canvas");
    if (!canvas) {
      rej();
      return;
    }
    setTimeout(() => {
      const url = takeScreenshot(canvas, false);
      const image = new Image();
      image.src = url;
      image.onload = () => {
        const width = image.naturalWidth;
        const height = image.naturalHeight;
        const aspect = width / height;
        const canvas = document.createElement("canvas");
        canvas.width = 300;
        canvas.height = Math.round(canvas.width / aspect);
        const ctx = canvas.getContext("2d");
        if (!ctx) return;
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        const url = canvas.toDataURL("image/webp");
        res(url);
        canvas.remove();
      };
      image.onerror = () => {
        rej();
      };
    }, 500);
  });
};
